// import { generateActions } from "helpers/generateActions";
export const GET_SHIPMENTS = "GET_SHIPMENTS";
export const GET_SHIPMENTS_SUCCESS = "GET_SHIPMENTS_SUCCESS";

export const GET_SINGLE_SHIPMENT = "GET_SINGLE_SHIPMENT";
export const GET_SINGLE_SHIPMENT_SUCCESS = "GET_SINGLE_SHIPMENT_SUCCESS";

export const EDIT_SHIPMENT_DETAILS = "EDIT_SHIPMENT_DETAILS";
export const EDIT_SHIPMENT_DETAILS_SUCCESS = "EDIT_SHIPMENT_DETAILS_SUCCESS";

export const EDIT_CONTAINER_DETAILS = "EDIT_CONTAINER_DETAILS";
export const EDIT_CONTAINER_DETAILS_SUCCESS = "EDIT_CONTAINER_DETAILS_SUCCESS";

export const EDIT_CONTAINER_STATUS = "EDIT_CONTAINER_STATUS";
export const EDIT_CONTAINER_STATUS_SUCCESS = "EDIT_CONTAINER_STATUS_SUCCESS";

export const GET_CONTAINER_STATUS_HISTORY = "GET_CONTAINER_STATUS_HISTORY";
export const GET_CONTAINER_STATUS_HISTORY_SUCCESS = "GET_CONTAINER_STATUS_HISTORY_SUCCESS";

export const ADD_EXPORT_SHIPMENT = "ADD_EXPORT_SHIPMENT";
export const ADD_EXPORT_SHIPMENT_SUCCESS = "ADD_EXPORT_SHIPMENT_SUCCESS";

export const UPLOAD_SHIPMENT_DOCS = "UPLOAD_SHIPMENT_DOCS";
export const UPLOAD_SHIPMENT_DOCS_SUCCESS = "UPLOAD_SHIPMENT_DOCS_SUCCESS";

export const DELETE_SHIPMENT = "DELETE_SHIPMENT";
export const DELETE_SHIPMENT_SUCCESS = "DELETE_SHIPMENT_SUCCESS";

export const DELETE_CONTAINER = "DELETE_CONTAINER";
export const DELETE_CONTAINER_SUCCESS = "DELETE_CONTAINER_SUCCESS";

export const API_ERROR = "API_ERROR";

