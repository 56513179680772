import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import moment from "moment";
import DataTable from "react-data-table-component";
import "react-sliding-pane/dist/react-sliding-pane.css";
import Aside from "components/partials/aside";
import TopBar from "components/partials/topBar";
import ShipmentDropdown from "components/shipmentDropdown/shipmentDropdown";
import PrimaryButton from "components/buttons/PrimaryButton";
import "react-datepicker/dist/react-datepicker.css";
import movement from "assets/icons/movement.svg";
import DeleteModal from "./shipmentPartials/deleteModal";
import { getShipments, deleteShipment } from "store/actions";
import ShipmentCsvData from "components/csvData/shipmentCsvData";
import Moment from "react-moment";
import { parse_container_count_and_size } from "helpers/parse_container_count_and_size";

const Shipment = (props: any) => {
	const { my_shipments, getShipments, loading, total_shipments } = props;
	console.log(my_shipments)
	console.log(total_shipments)
	const [openAside, SetOpenAside] = useState(false);
	const [selectedShipment, setSelectedShipment] = useState(null);
	const [filteredShipments, setFilteredShipments] = useState([]);
	const [isOpen, setIsOpen] = useState(false);
	const [dataToDelete, setDataToDelete] = useState({});

	const [pending, setPending] = useState(true);
	const [pageLoading, setPageLoading] = useState(false);
	const [filterObject, setFilterObject] = useState({ page: "0", count: "10" });


	const fetchShipments = () => {
		const filter_string = new URLSearchParams(filterObject).toString();
		setPageLoading(true);
		getShipments(filter_string);
	}

	const handlePageChange = (page) => {
		setFilterObject({ ...filterObject, ...{ page: String(page - 1) } });
		fetchShipments();
	};

	const handlePerRowsChange = async (newPerPage, page) => {
		setFilterObject({ ...filterObject, ...{ page: String(page), count: String(newPerPage) } });
		fetchShipments();
	};

	useEffect(() => {
		fetchShipments();
	}, [filterObject]);

	useEffect(() => {
		if (my_shipments?.length != 0) {
			setFilteredShipments(my_shipments);
		} else {
			//   fetchBooking();
			setFilteredShipments(my_shipments);
		}
	}, [my_shipments]);

	useEffect(() => {
		const timeout = setTimeout(() => {
			setPending(false);
		}, 2000);
		return () => clearTimeout(timeout);
	}, []);

	useEffect(() => {
		setFilteredShipments(my_shipments);
	}, [my_shipments]);


	const customStyles = {
		rows: {
			style: {
				minHeight: "72px",
				maxWidth: "100%",
			},
		},

		headCells: {
			style: {
				paddingLeft: "8px",
				paddingRight: "8px",
				backgroundColor: "#f0fdf4",
			},
		},

		cells: {
			style: {
				paddingLeft: "1px",
				paddingRight: "1px",
			},
		},
	};

	const columns = [
		{
			name: "Job Number",
			selector: (row) => {
				return (
					<div
						className="green-text cursor-pointer"
						style={{
							// marginLeft:'10px',
							zIndex: 1,
						}}
						onClick={() => {
							setSelectedShipment(row);
							window.location.href = `/shipments/shipment-details/${row?.["_id"]}`;
						}}
					>
						<small style={{ textAlign: 'right', justifyContent: 'right', paddingLeft: '8px' }}>
							{row.job_number}
						</small>
					</div>
				);
			},
			sortable: true,
		},
		{
			name: "Activity Status",
			selector: (row) => {
				return (
					<div className="">

						{
							row?.activity_status === "Active" || "active" ? (
								<Link to="#" className="bg-light-green black-text-2 text-sm py-2 px-3 green-text mx-5 text-center rounded-full">
									<small>Active</small>
								</Link>
							) : row?.activity_status === "inactive" ? (
								<Link to="#" className="bg-light-red red-text- text-sm py-2 px-3 red-text mx-5 text-center rounded-full">
									<small>Inactive</small>
								</Link>
							) : (
								<></>
							)
						}
					</div>
				);
			},
			sortable: true,
		},

		{
			name: "Container",
			selector: (row) => {
				return (
					<div className="capitalize" style={{ marginLeft: '10px' }}>
						{Object.entries(parse_container_count_and_size(row.container_details)).map((container, index) => {
							return (
								<p>{container[1] + "x" + container[0]}</p>
							)
						})
						}
						{/* {row.container_count}x{row.container_size} */}
					</div>
				)
			},
			sortable: true,
		},

		// {
		// 	name: "B/L Number",
		// 	selector: (row) => {
		// 		return (
		// 			<div className="black-text py-1 px-2 rounded-full mt-3 mb-3">
		// 				{row.bl_number === "" ? 'N/A' : row.bl_number}
		// 			</div>
		// 		);
		// 	},
		// 	sortable: true,
		// },

		{
			name: "Date Created",
			selector: (row) => {
				return (
					<div className="grey-text py-1 px-2 rounded-full mt-3 mb-3">
						<Moment format="DD-MM-YYYY">{row.createdAt}</Moment>
					</div>
				);
			},
			sortable: true,
		},

		// {
		// 	name: "Cargo Pick Up Date",
		// 	selector: (row) => {
		// 		return (
		// 			<div className="grey-text py-1 px-2 rounded-full mt-3 mb-3">
		// 				<Moment format="DD-MM-YYYY">{row.cargo_pickup_date}</Moment>
		// 			</div>
		// 		);
		// 	},
		// 	sortable: true,
		// },

		{
			name: "Origin",
			selector: (row) => {
				return (
					<div>
						<p className="font-semibold capitalize" style={{ justifyContent: 'left', whiteSpace: 'break-spaces' }}>
							{row?.shipment_type === "export" &&
								row?.shipment_transport_type === "ocean_freight" ? (
								<p className='pl-1' style={{ whiteSpace: 'break-spaces', textAlign: 'left', justifyContent: 'left' }}>
									{row?.origin_port_code}
								</p>
							) : row?.shipment_type === "import" &&
								row?.shipment_transport_type === "ocean_freight" ? (
								<p style={{ whiteSpace: 'break-spaces', textAlign: 'left', justifyContent: 'left' }}>
									{row?.port_of_discharge}
								</p>
							) : row?.shipment_type === "export" &&
								row?.shipment_transport_type === "haulage" ? (
								<p className='pl-1' style={{ whiteSpace: 'break-spaces', textAlign: 'left', justifyContent: 'left' }}>
									{row?.terminal_port}
								</p>
							) : row?.shipment_type === "import" &&
								row?.shipment_transport_type === "haulage" ? (
								<p style={{ whiteSpace: 'break-spaces', textAlign: 'left', justifyContent: 'left' }}>
									{row?.pickup_location}
								</p>
							) : (
								<p style={{ whiteSpace: 'break-spaces', textAlign: 'left', justifyContent: 'left' }}>
									{row?.origin_port_code}
								</p>
							)}
						</p>
					</div>
				);
			},
			sortable: true,
		},
		{
			name: "Destination",
			selector: (row) => {
				return (
					<div>
						<p className="font-semibold capitalize" style={{ justifyContent: 'left', whiteSpace: 'break-spaces' }}>
							{row?.shipment_type === "export" &&
								row?.shipment_transport_type === "ocean_freight" ? (
								<p className='pl-1' style={{ whiteSpace: 'break-spaces', textAlign: 'center', justifyContent: 'center' }}>
									{row?.destination_port_code}
								</p>
							) : row?.shipment_type === "import" &&
								row?.shipment_transport_type === "ocean_freight" ? (
								<p style={{ whiteSpace: 'break-spaces', textAlign: 'center', justifyContent: 'center' }}>
									{row?.delivery_location}
								</p>
							) : row?.shipment_type === "export" &&
								row?.shipment_transport_type === "haulage" ? (
								<p style={{ whiteSpace: 'break-spaces', textAlign: 'center', justifyContent: 'center' }}>
									{row?.stuffing_location}
								</p>
							) : row?.shipment_type === "import" &&
								row?.shipment_transport_type === "haulage" ? (
								<p style={{ whiteSpace: 'break-spaces', textAlign: 'center', justifyContent: 'center' }}>
									{row?.delivery_location}
								</p>
							) : (
								<p style={{ whiteSpace: 'break-spaces', textAlign: 'center', justifyContent: 'center' }}>
									{row?.destination_port_code}
								</p>
							)}
						</p>
					</div>
				);
			},
			sortable: true,
		},
		{
			name: "",
			selector: (row) => {
				return (
					<div
						className="bg-green p-1 rounded cursor-pointer"
						style={{ justifyContent: "center" }}
						onClick={() => {
							setSelectedShipment(row);
							window.location.href = `/shipments/shipment-details/${row?.["_id"]}`;
						}}
					>
						<p className="text-xs text-center white-text font-semibold p-2">
							<small>Details</small>
						</p>
					</div>
				);
			},
		},
	];

	const handleSearch = (value: string, type: string) => {
		if (value) {
			setFilterObject({ ...filterObject, ...{ page: "0", search: value } });

			fetchShipments();
		} else {
			fetchShipments();
		}
	};

	const handleFilter = (filter: any, type: string) => {
		let filtered = [];
		if (type === "shipment_type") {
			if (filter) {
				setFilterObject({
					...filterObject,
					...{
						page: "0",
						shipment_type: filter,
					},
				});
			} else {
				setFilterObject({
					...filterObject,
					...{
						page: "0",
						shipment_type: "",
					},
				});
			}
		} else if (type === "shipment_transport_type") {
			if (filter) {
				setFilterObject({
					...filterObject,
					...{
						page: "0",
						shipment_transport_type: filter,
					},
				});
			} else {
				setFilterObject({
					...filterObject,
					...{
						page: "0",
						shipment_transport_type: "",
					},
				});
			}
		} else if (type === "shipment_status") {
			if (filter) {
				setFilterObject({
					...filterObject,
					...{
						page: "0",
						shipment_status: filter,
					},
				});
			} else {
				setFilterObject({
					...filterObject,
					...{
						page: "0",
						shipment_status: "",
					},
				});
			}
		} else if (type === "shipment_date") {
			if (filter.length) {
				const [startDate, endDate] = filter;

				if (startDate && endDate) {
					setFilterObject({
						...filterObject,
						...{
							page: "0",
							start_date: moment(startDate).format("MM/DD/YYYY"),
							end_date: moment(endDate).format("MM/DD/YYYY"),
						},
					});
				} else {
					setFilterObject({
						...filterObject,
						...{
							page: "0",
							start_date: "",
							end_date: "",
						},
					});
				}
			}
		}

	}

	return (
		<>
			<div className="lg:flex">
				<Aside
					activeTab="shipment"
					openAside={openAside}
					SetOpenAside={SetOpenAside}
				/>
				<div className="">
					<TopBar title={"Shipments"} SetOpenAside={SetOpenAside} />
					<div className="dashboard-content">
						<div className="lg:px-10 lg:pt-5 container mx-auto w-full">
							<ShipmentDropdown handleFilter={handleFilter} />

							<div>
								<div className="lg:pb-3 lg:pt-3 grid grid-cols-2 gap-4">
									<div className="mt-2">
										<p className="font-semibold text-lg pl-3 ">
											All Shipments{" "}
										</p>
									</div>
									<div className="mt-2 ml-auto" style={{ textAlign: "right" }}>
										<small>Shipments </small>
										<small style={{ color: "grey" }}> / All Shipment</small>
									</div>
								</div>
							</div>

							{loading ? (
								<div className="text-center my-3">
									<Link to="#" className="text-success">
										{/* @ts-ignore */}
										<PrimaryButton
											title="Loading Shipments"
											loading={loading}
										/>
									</Link>
								</div>
							) : (
								<>
									<div className="container mx-auto w-full mb-5">
										<>
											<div className="mt-2 w-full">
												{/* Desktop view */}
												<div className="desktop-only pt-3 solid-br">
													<DataTable
														// @ts-ignore
														columns={columns}
														loading={loading}
														data={filteredShipments}
														pagination
														persistTableHead
														responsive
														actions={
															<ActionsMemo
																handleFilter={handleFilter}
																handleSearch={handleSearch}
																filteredShipments={filteredShipments}
															/>
														}
														paginationPerPage={10}
														customStyles={customStyles}
														progressPending={pending}
														paginationServer
														paginationTotalRows={total_shipments}
														onChangeRowsPerPage={handlePerRowsChange}
														onChangePage={handlePageChange}
													/>
												</div>
											</div>
											{/* Mobile View */}
											<div className="bg-grey py-2 mobile-only">
												{my_shipments?.data?.data?.map(
													(data: any, index: any) => {
														return (
															<div className="bg-white mb-3" key={index}>
																<div className="grid grid-cols-1 gap-4 py-3 px-7 bottom-divider items-center ">
																	<div className="">
																		<p className="green-text text-sm">
																			<span className="black-text">
																				Job Number: {data?.["job_number"]}
																			</span>
																		</p>
																		<p className="grey-text text-sm">
																			<span className="black-text">
																				Contact Person Email:{" "}
																				{data?.["client_email"]}
																			</span>
																		</p>
																		<p className="grey-text text-sm">
																			<span className="black-text">
																				Status: {data?.["activity_status"]}
																			</span>
																		</p>
																		<p className="grey-text text-sm">
																			<span className="black-text">
																				Container Details:{" "}
																				{data?.["container_count"]} x{" "}
																				{data?.["container_size"]}
																			</span>
																		</p>
																	</div>
																</div>

																<div className="grid grid-cols-2 gap-4 py-3 px-7 bottom-divider items-center">
																	<div className="flex items-center justify-center">
																		<div className="flex items-center">
																			<p className="black-text">
																				{data.origin_port_code}
																			</p>
																		</div>
																		<p className="pl-7">
																			<img
																				src={movement}
																				width="50%"
																				height="30%"
																			/>
																		</p>
																		<div className="flex items-center ">
																			<p className="black-text">
																				{data.destination_port_code}
																			</p>
																		</div>
																	</div>

																	<Link
																		to=""
																		onClick={() => {
																			// window.location.href = `/customers/edit-customer/${data?.['_id']}`
																			window.location.href = `/shipments/shipment-details/${data?.["_id"]}`;
																		}}
																		className="bg-green white-text text-xs py-2 text-center w-full rounded mr-3"
																	>
																		Details
																	</Link>
																</div>
															</div>
														);
													}
												)}
											</div>
											{/* Mobile View */}
										</>
									</div>
								</>
							)}
						</div>
					</div>
				</div>
			</div>
			<DeleteModal
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				id={dataToDelete}
				deleteShipment={props.deleteShipment}
				selectedShipment={selectedShipment}
				my_shipments={my_shipments}
			/>
		</>
	);
};

// export default Shipment;

const mapStateToProps = (state: any) => {
	const { my_shipments, error, loading, total_shipments } = state.shipments;
	return { my_shipments, error, loading, total_shipments };
};

// Table actions
const ActionsMemo = (props: any): JSX.Element => {
	const { filteredShipments, handleFilter, handleSearch } = props;
	const csvData = [
		["firstname", "lastname", "email"],
		["Ahmed", "Tomi", "ah@smthing.co.com"],
		["Raed", "Labes", "rl@smthing.co.com"],
		["Yezzi", "Min l3b", "ymin@cocococo.com"]
	];
	return (
		<>

			<div className="mb-5 mr-3">
				<div className="">
					<select
						name=""
						id="shipment_transport_type"
						className=" px-4 py-2.5 text-xs rounded custom-input  w-full black-text bg-grey"
						onChange={(e) =>
							handleFilter(e.target.value, "shipment_transport_type")
						}
					>
						<option value="">Transportation Type</option>
						<option value="ocean_freight">Ocean Freight</option>
						<option value="air_freight">Air Freight</option>
						<option value="haulage">Haulage</option>
					</select>
				</div>
			</div>
			<div className="mb-5 mr-3">
				<div className="">
					<select
						name=""
						id="status"
						className=" px-4 py-2.5 text-xs rounded custom-input  w-full black-text bg-grey"
						onChange={(e) => handleFilter(e.target.value, "shipment_status")}
					>
						<option value="">Shipment Status</option>
						<option value="active">Active/In Progress</option>
						<option value="inactive">Inactive</option>
						<option value="invoice accepted">Invoice Accepted</option>
					</select>
				</div>
			</div>

			<div className="mb-5 mr-3">
				<div className="">
					<select
						name=""
						id="shipment_type"
						className=" px-4 py-2.5 text-xs rounded custom-input  w-full black-text bg-grey"
						onChange={(e) => handleFilter(e.target.value, "shipment_type")}
					>
						<option value="">Shipment Type</option>
						<option value="import">Import</option>
						<option value="export">Export</option>
					</select>
				</div>
			</div>

			{/* <div className="mb-5 mr-1">
				<ExportCSV
					onExport={() => downloadCSV(filteredShipments, "shipments.csv")}
				/>
			</div> */}

			{/* <div className="mb-7 mr-1">
				<ShipmentCsvData />
			</div> */}

			<input
				placeholder="Search Shipments"
				className="form-input px-3 py-1.5 custom-input w-full black-text w-40 mb-5"
				onChange={(e) => handleSearch(e.target.value)}
			/>
		</>
	);
};

// const ExportCSV = ({ onExport }) => (
// 	<button
// 		className="bg-grey black-text text-xs w-32 py-2.5 text-center rounded solid-br"
// 		onClick={(e) => onExport(e["target"]["value"])}
// 	>
// 		Export CSV
// 	</button>
// );

export default connect(mapStateToProps, { getShipments, deleteShipment })(Shipment);
