import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import CustomInput from "components/textInputs/CustomInput";
import CustomSelect from "components/selectInputs/CustomSelect";
import PrimaryButton from "components/buttons/PrimaryButton";
import CustomCheckBox from "components/checkBox/CustomCheckbox";
import CustomGoogleInput from "components/textInputs/CustomGoogleInput";
import CustomerAutocomplete from "components/customerAutocomplete/customerAutocomplete";
//helpers
import { findDefaultPort, getCurrentTimestamp } from "helpers";
import { parseAllPorts } from "helpers/index";
const _Json = require("sea-ports");

const ImportShipment = (props: any) => {
  const { loading } = props;
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const [lagos, setLagos] = useState(false);
  const [kano, setKano] = useState(false);
  const [harcourt, setHarcourt] = useState(false);
  const [customs, setCustoms] = useState(false);
  const [haulage, setHaulage] = useState(false);
  const [thc, setThc] = useState(false);
  const [oceanFreight, setOceanFreight] = useState(false);
  const [insurance, setInsurance] = useState(false);

  const [defaultPortsOfOrigin, setDefaultPortsOfOrigin] = useState([]);
  const [defaultPortsOfDestination, setDefaultPortsOfDestination] = useState(
    []
  );

  useEffect(() => {
    const parsePorts = parseAllPorts(_Json.JSON);
    setDefaultPortsOfOrigin(parsePorts.origin);
    setDefaultPortsOfDestination(parsePorts.destination);

    // mixpanel.track("Start new shipment", {
    //   email: user.email,
    // });
  }, []);

  const onSubmit = (data: any) => {
    const newData = {
      data: {
        lagos,
        kano,
        harcourt,
        customs,
        haulage,
        thc,
        oceanFreight,
        insurance,
        shipment_type: "import",
        origin_port: data?.origin_port?.value?.unlocs[0],
        destination_port: data?.destination_port?.value?.unlocs[0],
        delivery_location: data?.delivery_location?.label,
        customer_id: data.customer_id
          ? data.customer_id.value
          : data?.params.id,
      },
    };
  };

  return (
    <div className="my-5 right-divider left-divider top-divider bottom-divider shadow-2xl">
      <form onSubmit={handleSubmit(onSubmit)} className="my-5">
        <div className="px-8">
          <div className="grid grid-cols-1 mb-3">
            <div className="mr-1">
              <p className="text-xs black-text mb-2 font-medium">
                Company Name & Customer Namesss
                <span className="red-text">*</span>
              </p>
              <CustomerAutocomplete
                control={control}
                name={"customer_id"}
                id={"customer_id"}
                label={"Customer Selection"}
                placeholder={"Select Customer"}
                isRequired={true}
                errors={errors}
                isDisabled={false}
                icon=""
              />
            </div>

            <div className="mt-3 grid grid-cols-2 ">
              <div className="mr-1">
                <p className="text-xs black-text font-medium">
                  {" "}
                  Port of dischargess<span className="red-text">*</span>
                </p>
                <CustomSelect
                  control={control}
                  name={"port_of_discharge"}
                  id={"port_of_discharge"}
                  label={""}
                  placeholder={"Port of discharge"}
                  isRequired={false}
                  errors={errors}
                  isDisabled={false}
                  options={defaultPortsOfOrigin}
                  defaultValue={""}
                  icon=""
                />
              </div>

              <div className="mr-1">
                <p className="text-xs black-text font-medium">
                  {" "}
                  Delivery location <span className="red-text">*</span>
                </p>
                <CustomGoogleInput
                  control={control}
                  name={"delivery_location"}
                  id={"delivery_location"}
                  label={""}
                  placeholder={"Delivery location"}
                  isRequired={false}
                  errors={errors}
                  isDisabled={false}
                  defaultValue={""}
                  icon=""
                />
              </div>
            </div>
          </div>

          <div className="mt-3 grid grid-cols-3">
            <div className="">
              <p className="text-xs black-text font-medium">
                Size of Container<span className="red-text">*</span>
              </p>
              <CustomSelect
                control={control}
                name={`container_size`}
                id={`container_size`}
                label={""}
                placeholder={`Container Size`}
                isRequired={true}
                errors={errors}
                isDisabled={false}
                options={[
                  { label: "20 FT", value: "20FT" },
                  { label: "40 FT", value: "40FT" },
                  { label: "40 HC FT", value: "40HC FT" },
                ]}
                defaultValue={""}
                icon=""
              />
            </div>

            <div className="ml-1">
              <p className="text-xs black-text font-medium">
                Type of Container<span className="red-text">*</span>
              </p>
              <CustomSelect
                control={control}
                name={`container_type`}
                id={`container_type`}
                label={""}
                placeholder={`Container Type`}
                isRequired={true}
                errors={errors}
                isDisabled={false}
                options={[
                  { label: "Dry", value: "dry" },
                  { label: "Reefer", value: "reefer" },
                ]}
                defaultValue={""}
                icon=""
              />
            </div>

            <div className="ml-1">
              <p className="text-xs black-text font-medium">
                Number Of Containers<span className="red-text">*</span>
              </p>
              <CustomInput
                control={control}
                name={"container_count"}
                id={"container_count"}
                label={""}
                placeholder={"Number Of Containers"}
                isRequired={true}
                type={"text"}
                errors={errors}
                isDisabled={false}
                defaultValue={""}
                min={""}
                max={""}
                icon={""}
              />
            </div>
          </div>

          <div className="mt-3 grid grid-cols-3">
            <div className="ml-1">
              <p className="text-xs black-text font-medium">
                Weight (TON)<span className="red-text">*</span>
              </p>
              <CustomInput
                control={control}
                name={"container_weight"}
                id={"container_weight"}
                label={""}
                placeholder={"Weight"}
                isRequired={true}
                type={"text"}
                errors={errors}
                isDisabled={false}
                defaultValue={""}
                min={""}
                max={""}
                icon={""}
              />
            </div>

            <div className="ml-1">
              <p className="text-xs black-text font-medium">
                B/L Number<span className="red-text">*</span>
              </p>
              <CustomInput
                control={control}
                name={"bl"}
                id={"bl"}
                label={""}
                placeholder={"B/L"}
                isRequired={true}
                type={"text"}
                errors={errors}
                isDisabled={false}
                defaultValue={""}
                min={""}
                max={""}
                icon={""}
              />
            </div>

            <div className="ml-1">
              <p className="text-xs black-text font-medium">
                Office / Branch<span className="red-text">*</span>
              </p>
              <CustomSelect
                control={control}
                name={`branch_code`}
                id={`branch_code`}
                label={""}
                placeholder={`Office / Branch`}
                isRequired={true}
                errors={errors}
                isDisabled={false}
                options={[
                  { label: "Lagos", value: "LOS" },
                  { label: "Kano", value: "KAN" },
                  { label: "Port Harcourt", value: "PHC" },
                ]}
                defaultValue={""}
                icon=""
              />
            </div>
          </div>

          <div className="mt-2">
            <p className="text-xs black-text mb-2 font-medium">
              Services Provided <small>(Select all that apply)</small>
            </p>
            <div className="grid grid-cols-1">
              <div className="flex items-center mb-5">
                <div className="mr-3">
                  <CustomCheckBox
                    name="lagos"
                    id="lagos"
                    label="Customs"
                    isRequired={false}
                    isDisabled={false}
                    onChange={(e: any) => setCustoms(e.target.checked)}
                    defaultChecked={false}
                  />
                </div>

                <div className="flex items-center ">
                  <div className="mr-3">
                    <CustomCheckBox
                      name="kano"
                      id="kano"
                      label="Haulage"
                      isRequired={false}
                      isDisabled={false}
                      onChange={(e: any) => setHaulage(e.target.checked)}
                      defaultChecked={false}
                    />
                  </div>
                </div>

                <div className="mr-3">
                  <CustomCheckBox
                    name="harcourt"
                    id="harcourt"
                    label="Terminal Handling"
                    isRequired={false}
                    isDisabled={false}
                    onChange={(e: any) => setThc(e.target.checked)}
                    defaultChecked={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="px-8 mb-5">
          <hr className={`solid-br`} />
          <div className="w-24 mt-5">
            {" "}
            {/* @ts-ignore */}
            <PrimaryButton title="Submit Form" loading={loading} />
          </div>
        </div>
      </form>
    </div>
  );
};

export default ImportShipment;
