// import { generateActions } from "helpers/generateActions";
export const GET_ADMINS = "GET_ADMINS";
export const GET_ADMIN_SUCCESS = "GET_ADMIN_SUCCESS";
export const CREATE_ADMIN = "CREATE_ADMIN";
export const CREATE_ADMIN_SUCCESS = "CREATE_ADMIN_SUCCESS";
export const DELETE_ADMIN = "DELETE_ADMIN";
export const DELETE_ADMIN_SUCCESS = "DELETE_ADMIN_SUCCESS";
export const DEACTIVATE_ADMIN = "DEACTIVATE_ADMIN";
export const DEACTIVATE_ADMIN_SUCCESS = "DEACTIVATE_ADMIN_SUCCESS";

export const API_ERROR = "API_ERROR";

// export const CLEAR_API_ERROR = "CLEAR_API_ERROR";
