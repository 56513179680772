import React from "react";
import moment from "moment";
import DynamicShipmentEdit from "../../shipmentPlus/shipmentAndTransportCombination/dynamicShipmentEdit";
import CurrencyFormat from "react-currency-format";

const ShipmentDetail = (props: any) => {
	const { single_shipment } = props;
	console.log(single_shipment?.data?.data)
	const single_shipment_data = single_shipment?.data?.data;
	console.log("single-shipment-data", single_shipment_data);
	return (
		<div>
			<>
				{/* Dynamic Edit */}
				{/* <DynamicShipmentEdit single_shipment={single_shipment} /> */}
				<div className="top-divider left-divider right-divider rounded-t-lg">
					<div className="grid grid-cols-2">
						<p className="black-text text-sm py-3 px-5 ">Transportation Type</p>
						<p className="black-text text-sm left-divider py-3 px-5 capitalize">
							{single_shipment_data?.shipment_transport_type === "ocean_freight"
								? "Ocean Freight"
								: single_shipment_data?.shipment_transport_type ===
									"air_freight"
									? "Air Freight"
									: "Haulage"}
						</p>
					</div>
				</div>
				<div className="solid-br">
					<div className="grid grid-cols-2">
						<p className="black-text text-sm py-3 px-5 ">Job Number</p>
						<p className="black-text text-sm left-divider py-3 px-5 ">
							{single_shipment_data?.job_number === undefined ? 'N/A' : single_shipment_data?.job_number}
						</p>
					</div>
				</div>
				<div className="solid-br">
					<div className="grid grid-cols-2">
						<p className="black-text text-sm py-3 px-5 ">B/L Number</p>
						<p className="black-text text-sm left-divider py-3 px-5 ">
							{single_shipment_data?.bl_number === "" ? "N/A" : single_shipment_data?.bl_number}
						</p>
					</div>
				</div>
				<div className="solid-br">
					<div className="grid grid-cols-2">
						<p className="black-text text-sm py-3 px-5 ">Container Details</p>
						<p className="black-text text-sm left-divider py-3 px-5 ">
							{single_shipment_data?.container_details?.length}x
							{single_shipment_data?.container_details[0]?.container_size}
						</p>
					</div>
				</div>
				<div className="solid-br">
					<div className="grid grid-cols-2">
						<p className="black-text text-sm py-3 px-5 ">Container Weight</p>
						<p className="black-text text-sm left-divider py-3 px-5 ">
							{single_shipment_data?.container_details[0]?.container_weight}Metric Ton(s)
						</p>
					</div>
				</div>
				<div className="solid-br">
					<div className="grid grid-cols-2">
						<p className="black-text text-sm py-3 px-5">Container Type</p>
						<p className="black-text text-sm left-divider py-3 px-5 capitalize">
							{single_shipment_data?.container_details[0]?.container_type}
						</p>
					</div>
				</div>
				{/* <div className="solid-br">
					<div className="grid grid-cols-2">
						<p className="black-text text-sm py-3 px-5 capitalize">
							Commodity Description
						</p>
						<p className="black-text text-sm left-divider py-3 px-5 ">
							{single_shipment_data?.goods_type}
						</p>
					</div>
				</div> */}
				<div className="solid-br">
					<div className="grid grid-cols-2">
						<p className="black-text text-sm py-3 px-5 capitalize">
							Goods Value
						</p>
						<p className="black-text text-sm left-divider py-3 px-5 ">
							{/* ₦<NumericFormat value={single_shipment_data?.goods_value === undefined ? 'N/A' : single_shipment_data?.goods_value} />; */}
							{/* <CurrencyFormat thousandSeparator={true} prefix={'$'} /> */}
							<CurrencyFormat
								value={single_shipment_data?.goods_value === undefined ? "N/A" : single_shipment_data?.goods_value}
								displayType={"text"}
								thousandSeparator={true}
								prefix={"₦"}
							/>
						</p>
					</div>
				</div>
				{/* <div className="solid-br">
                        <div className="grid grid-cols-2">
                            <p className="black-text text-sm py-3 px-5 ">Activity status</p>
                            <p className="black-text text-sm left-divider py-3 px-5 ">{single_shipment_data?.activity_status}</p>
                        </div>
                    </div> */}
				<div className="solid-br ">
					<div className="grid grid-cols-2">
						<p className="black-text text-sm py-3 px-5">
							Container Pickup Date
						</p>
						<p className="black-text text-sm left-divider py-3 px-5 ">
							{moment(single_shipment_data?.cargo_pickup_date).format("LL")}
						</p>
					</div>
				</div>
				<div className="solid-br ">
					<div className="grid grid-cols-2">
						<p className="black-text text-sm py-3 px-5 ">
							Customs Brokerage & Terminal
						</p>
						<p className="black-text text-sm left-divider py-3 px-5 ">
							{single_shipment_data?.customs_brokerage === true ? (
								<p>Yes</p>
							) : (
								<p>No</p>
							)}
						</p>
					</div>
				</div>
				<div className="solid-br ">
					<div className="grid grid-cols-2">
						<p className="black-text text-sm py-3 px-5 ">Marine Insurance</p>
						<p className="black-text text-sm left-divider py-3 px-5 ">
							{single_shipment_data?.marine_insurance === true ? (
								<p>Yes</p>
							) : (
								<p>No</p>
							)}
						</p>
					</div>
				</div>
				<div className="solid-br ">
					<div className="grid grid-cols-2">
						<p className="black-text text-sm py-3 px-5 ">Warehousing</p>
						<p className="black-text text-sm left-divider py-3 px-5 ">
							{single_shipment_data?.warehousing === true ? (
								<p>Yes</p>
							) : (
								<p>No</p>
							)}
						</p>
					</div>
				</div>
				<div className="solid-br ">
					<div className="grid grid-cols-2">
						<p className="black-text text-sm py-3 px-5 ">Haulage</p>
						<p className="black-text text-sm left-divider py-3 px-5 ">
							{single_shipment_data?.with_haulage === true ? (
								<p>Yes</p>
							) : (
								<p>No</p>
							)}
						</p>
					</div>
				</div>
				<div className="solid-br ">
					<div className="grid grid-cols-2">
						<p className="black-text text-sm py-3 px-5 ">Haulage Escort</p>
						<p className="black-text text-sm left-divider py-3 px-5 ">
							{single_shipment_data?.with_escort === true ? (
								<p>Yes</p>
							) : (
								<p>No</p>
							)}
						</p>
					</div>
				</div>
				<div className="solid-br ">
					<div className="grid grid-cols-2">
						<p className="black-text text-sm py-3 px-5 ">Haulage Tracker</p>
						<p className="black-text text-sm left-divider py-3 px-5 ">
							{single_shipment_data?.with_tracker === true ? (
								<p>Yes</p>
							) : (
								<p>No</p>
							)}
						</p>
					</div>
				</div>
				<div className="solid-br ">
					<div className="grid grid-cols-2">
						<p className="black-text text-sm py-3 px-5 ">Hazardous Product</p>
						<p className="black-text text-sm left-divider py-3 px-5 ">
							{single_shipment_data?.is_product_hazardous === true ? (
								<p>Yes</p>
							) : (
								<p>No</p>
							)}
						</p>
					</div>
				</div>

				{/* <div className="bottom-divider left-divider right-divider">
                        <div className="grid grid-cols-2">
                            <p className="black-text text-sm py-3 px-5 ">Marine Insurance</p>
                            <p className="black-text text-sm left-divider py-3 px-5 ">
                                {
                                    single_shipment_data?.marine_insurance === true ? (
                                        <p>Yes</p>
                                    ) : (
                                        <p>No</p>
                                    )
                                }
                            </p>
                        </div>
                    </div>
                    </div> */}

				<div className="bottom-divider left-divider right-divider ">
					<div className="grid grid-cols-2">
						<p className="black-text text-sm py-3 px-5 ">Post Shipment Done</p>
						<p className="black-text text-sm left-divider py-3 px-5 ">
							{single_shipment_data?.post_shipment_done === true ? (
								<p>Yes</p>
							) : (
								<p>No</p>
							)}
						</p>
					</div>
				</div>

				{single_shipment_data?.shipment_type === "export" &&
					single_shipment_data?.with_haulage === true ? (
					<>
						<p className="black-text text-base mt-10 mb-3 font-semibold">
							Haulage Details
						</p>

						<div className="top-divider left-divider right-divider bottom-divider rounded-t-lg">
							<div className="grid grid-cols-2">
								<p className="black-text text-sm py-3 px-5 ">
									Haulage Pick Up Location
								</p>
								<p className="black-text text-sm left-divider py-3 px-5">
									{single_shipment_data?.pickup_location}
								</p>
							</div>
						</div>

						<div className="top-divider left-divider right-divider bottom-divider rounded-t-lg">
							<div className="grid grid-cols-2">
								<p className="black-text text-sm py-3 px-5 ">
									Haulage Drop Off Location
								</p>
								<p className="black-text text-sm left-divider py-3 px-5 ">
									{single_shipment_data?.destination_port_code}
								</p>
							</div>
						</div>
					</>
				) : (
					<></>
				)}

				{single_shipment_data?.shipment_type === "import" &&
					single_shipment_data?.with_haulage === true ? (
					<>
						<p className="black-text text-base mt-10 mb-3 font-semibold">
							Haulage Details
						</p>

						<div className="top-divider left-divider right-divider bottom-divider rounded-t-lg">
							<div className="grid grid-cols-2">
								<p className="black-text text-sm py-3 px-5 ">
									Haulage Port of Discharge
								</p>
								<p className="black-text text-sm left-divider py-3 px-5 ">
									{single_shipment_data?.port_of_discharge}
								</p>
							</div>
						</div>

						<div className="top-divider left-divider right-divider bottom-divider rounded-t-lg">
							<div className="grid grid-cols-2">
								<p className="black-text text-sm py-3 px-5 ">
									Haulage Delivery Location
								</p>
								<p className="black-text text-sm left-divider py-3 px-5 ">
									{single_shipment_data?.delivery_location}
								</p>
							</div>
						</div>
					</>
				) : (
					<></>
				)}

				{single_shipment_data?.shipment_type === "export" &&
					single_shipment_data?.shipment_transport_type === "haulage" ? (
					<>
						<p className="black-text text-base mt-10 mb-3 font-semibold">
							Haulage Details
						</p>

						<div className="top-divider left-divider right-divider bottom-divider rounded-t-lg">
							<div className="grid grid-cols-2">
								<p className="black-text text-sm py-3 px-5 ">
									Stuffing Location
								</p>
								<p className="black-text text-sm left-divider py-3 px-5 ">
									{single_shipment_data?.stuffing_location}
								</p>
							</div>
						</div>

						<div className="top-divider left-divider right-divider bottom-divider rounded-t-lg">
							<div className="grid grid-cols-2">
								<p className="black-text text-sm py-3 px-5 ">Port of Loading</p>
								<p className="black-text text-sm left-divider py-3 px-5 ">
									{single_shipment_data?.origin_port_code}
								</p>
							</div>
						</div>

						<div className="top-divider left-divider right-divider bottom-divider rounded-t-lg">
							<div className="grid grid-cols-2">
								<p className="black-text text-sm py-3 px-5 ">
									Terminal Location
								</p>
								<p className="black-text text-sm left-divider py-3 px-5 ">
									{single_shipment_data?.terminal_port}
								</p>
							</div>
						</div>
					</>
				) : (
					<></>
				)}

				{single_shipment_data?.shipment_type === "import" &&
					single_shipment_data?.shipment_transport_type === "haulage" ? (
					<>
						<p className="black-text text-base mt-10 mb-3 font-semibold">
							Haulage Details
						</p>

						<div className="top-divider left-divider right-divider bottom-divider rounded-t-lg">
							<div className="grid grid-cols-2">
								<p className="black-text text-sm py-3 px-5 ">
									Haulage Port of Discharge
								</p>
								<p className="black-text text-sm left-divider py-3 px-5 ">
									{single_shipment_data?.pickup_location}
								</p>
							</div>
						</div>

						<div className="top-divider left-divider right-divider bottom-divider rounded-t-lg">
							<div className="grid grid-cols-2">
								<p className="black-text text-sm py-3 px-5 ">
									Haulage Destination
								</p>
								<p className="black-text text-sm left-divider py-3 px-5 ">
									{single_shipment_data?.delivery_location}
								</p>
							</div>
						</div>
					</>
				) : (
					<></>
				)}

				{/* <div className="solid-br">
                        <div className="grid grid-cols-2">
                            <p className="black-text text-sm py-3 px-5 ">City</p>
                            <p className="black-text text-sm left-divider py-3 px-5 ">
                                {
                                    single_shipment_data?.origin_port_details === undefined || null ? (
                                        <p>N/A</p>
                                    ) : (
                                        // <p>{single_shipment_data?.origin_port_details.city}</p>
                                        <p></p>
                                    )
                                }
                            </p>
                        </div>
                    </div> */}

				{/* <div className="bottom-divider left-divider right-divider  rounded-b-lg">
                        <div className="grid grid-cols-2">
                            <p className="black-text text-sm py-3 px-5 ">Country</p>
                            <p className="black-text text-sm left-divider py-3 px-5 ">
                                {
                                    single_shipment_data?.origin_port_details === undefined ? (
                                        <p>N/A</p>
                                    ) : (
                                        // <p>{single_shipment_data?.origin_port_details.country}</p>
                                        <p></p>
                                    )
                                }
                            </p>
                        </div>
                    </div> */}

				{/* <p className="black-text text-base mt-10 mb-3 font-semibold">Destination Details</p>

                    <div className="top-divider left-divider right-divider bottom-divider rounded-t-lg">
                        <div className="grid grid-cols-2">
                            <p className="black-text text-sm py-3 px-5 ">Destination Port</p>
                            <p className="black-text text-sm left-divider py-3 px-5 ">{single_shipment_data?.destination_port_code}</p>
                        </div>
                    </div> */}

				{/* <div className="solid-br">
                        <div className="grid grid-cols-2">
                            <p className="black-text text-sm py-3 px-5 ">City</p>
                            <p className="black-text text-sm left-divider py-3 px-5 ">
                                {
                                    single_shipment_data.destination_port_details === undefined ? (
                                        <p>N/A</p>
                                    ) : (
                                        // <p>{single_shipment_data.destination_port_details.city}</p>
                                        <p></p>
                                    )
                                }
                            </p>
                        </div>
                    </div> */}

				{/* <div className="bottom-divider left-divider right-divider  rounded-b-lg">
                        <div className="grid grid-cols-2">
                            <p className="black-text text-sm py-3 px-5 ">Country</p>
                            <p className="black-text text-sm left-divider py-3 px-5 ">
                                {
                                    single_shipment_data.destination_port_details === undefined ? (
                                        <p>N/A</p>
                                    ) : (
                                        // <p>{single_shipment_data.destination_port_details.country}</p>
                                        <p></p>
                                    )
                                }
                            </p>
                        </div>
                    </div> */}
			</>
		</div>
	);
};

export default ShipmentDetail;
