import React from 'react'

const RejectedQuotes = () => {
    return (
        <div>
            {/* rejectedQuotes */}
        </div>
    )
}

export default RejectedQuotes