// import { generateActions } from "helpers/generateActions";

export const GET_ALL_QUOTES = "GET_ALL_QUOTES";
export const GET_ALL_QUOTES_SUCCESS = "GET_ALL_QUOTES_SUCCESS";

export const GET_MY_QUOTES = "GET_MY_QUOTES";
export const GET_MY_QUOTES_SUCCESS = "GET_MY_QUOTES_SUCCESS";

export const GET_MY_CREATED_QUOTES = "GET_MY_CREATED_QUOTES";
export const GET_MY_CREATED_QUOTES_SUCCESS = "GET_MY_CREATED_QUOTES_SUCCESS";

export const GET_PENDING_ADMIN_QUOTES = "GET_PENDING_ADMIN_QUOTES";
export const GET_PENDING_ADMIN_QUOTES_SUCCESS = "GET_PENDING_ADMIN_QUOTES_SUCCESS";

export const PROCESS_PENDING_ADMIN_QUOTES = "PROCESS_PENDING_ADMIN_QUOTES";
export const PROCESS_PENDING_ADMIN_QUOTES_SUCCESS = "PROCESS_PENDING_ADMIN_QUOTES_SUCCESS";

export const GET_PENDING_CUSTOMER_QUOTES = "GET_PENDING_CUSTOMER_QUOTES";
export const GET_PENDING_CUSTOMER_QUOTES_SUCCESS = "GET_PENDING_CUSTOMER_QUOTES_SUCCESS";

export const GET_ACCEPTED_QUOTES = "GET_ACCEPTED_QUOTES";
export const GET_ACCEPTED_QUOTES_SUCCESS = "GET_ACCEPTED_QUOTES_SUCCESS";

export const GET_SINGLE_QUOTE = "GET_SINGLE_QUOTE";
export const GET_SINGLE_QUOTE_SUCCESS = "GET_SINGLE_QUOTE_SUCCESS";

export const ATTACH_QUOTE = "ATTACH_QUOTE";
export const ATTACH_QUOTE_SUCCESS = "ATTACH_QUOTE_SUCCESS";

export const FILTER_QUOTES = "FILTER_QUOTES";
export const FILTER_QUOTES_SUCCESS = "FILTER_QUOTES_SUCCESS";


export const API_ERROR = "API_ERROR";