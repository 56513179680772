import React, { useState } from "react";
import { Marker } from "@react-google-maps/api";
import { v4 as uuidv4 } from "uuid";
import Iframe from "react-iframe";

import container_icon from "assets/icons/container_icon.png";

export default function CustomMarker(props) {
	const { position, clusterer, key, src, onClick } = props;

	return <Marker
		position={position}
		clusterer={clusterer}
		icon={container_icon}
		clickable={true}
		onClick={onClick}
	/>;
}
