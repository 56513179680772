import { get,post,put } from "helpers/axios"
import { getContainersUrl } from "api/endpoints";

const getContainers = () => {
    return get(getContainersUrl);
}


const containerServices = {
    getContainers,
}

export default containerServices;