import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useParams} from "react-router-dom";
import CustomInput from "components/textInputs/CustomInput";
import CustomSelect from "components/selectInputs/CustomSelect";
import PrimaryButton from "components/buttons/PrimaryButton";
import CustomCheckBox from "components/checkBox/CustomCheckbox";
import CustomCurrencyInput from "components/textInputs/CustomCurrencyInput";
import { parseAllPorts } from "helpers/index";
import Aside from "components/partials/aside";
import TopBar from "components/partials/topBar";
import { getSingleShipment, editShipmentDetails } from "store/actions";
const _Json = require("sea-ports");

const EditShipmentDetails = (props:any) => {
    const params = useParams();
    const { loading, single_shipment } = props;
    const single_shipment_data = single_shipment?.data?.data[0]
    // console.log(single_shipment_data);
    const [openAside, SetOpenAside] = useState(false);
    const { handleSubmit, control, formState: { errors }} = useForm();
    const [customs, setCustoms] = useState(false);
    const [haulage, setHaulage] = useState(false);
    const [terminal_handling, setThc] = useState(false);
    const [ocean_freight, setOceanFreight] = useState(false);
    const [marine_insurance, setInsurance] = useState(false);
    const [warehousing, setWarehousing] = useState(false);
    const [defaultPortsOfOrigin, setDefaultPortsOfOrigin] = useState([]);
    const [defaultPortsOfDestination, setDefaultPortsOfDestination] = useState([]);

    useEffect(() => {
        const parsePorts = parseAllPorts(_Json.JSON);
        setDefaultPortsOfOrigin(parsePorts.origin);
        setDefaultPortsOfDestination(parsePorts.destination);
    
        // mixpanel.track("Start new shipment", {
        //   email: user.email,
        // });
    }, []);

    useEffect(() => {
        props.getSingleShipment(params.id)
    },[])


    const onSubmit = (data: any) => {
        const newData = {
            id:params.id,
            customs,
            terminal_handling,
            ocean_freight,
            warehousing,
            marine_insurance,
            origin_port_code: data?.origin_port?.value?.unlocs[0],
            destination_port_code: data?.destination_port?.value?.unlocs[0],
            cargo_pickup_date: data?.cargo_pickup_date,
            container_type: data?.container_type.value,
            container_weight: data?.container_weight,
            goods_value: data?.goods_value,
            bl_number: data?.bl_number
		};
        props.editShipmentDetails(newData);
		console.log(newData);
        // console.log(data.customer_id.value)
    }


    return (
        <div className="flex">
            <Aside activeTab="Shipment"
                openAside={openAside}
                SetOpenAside={SetOpenAside}
            />
            <div className="dashboard-content">
			<TopBar title={"Shipment"} SetOpenAside={SetOpenAside}/>
                {
                    loading ? 
                    (
                        <div className="text-center my-3 ml-5">
                            <Link to="#" className="text-success">
                                {/* @ts-ignore */}
                                <PrimaryButton title="Loading Shipment Details" loading={loading} />
                            </Link>
                        </div>
                    ) : ( 
                        <>
                            <div className="">
                                <div>
                                    <div className="lg:px-14 lg:pb-5 lg:pt-5 grid grid-cols-2 gap-4 px-5">
                                        <div className="mt-2">
                                            <p className='font-semibold text-lg'>Job Number : {single_shipment_data.job_number}</p>
                                        </div>
                                        <div className="mt-2 ml-auto" style={{textAlign:'right'}}>
                                            <small>Shipments </small><small style={{color: 'grey'}}> / Edit Shipment</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="lg:px-14 lg:pt-3 container mx-auto  w-full">
                                <div className='my-5 right-divider left-divider top-divider bottom-divider shadow-2xl'>
                                    <form onSubmit={handleSubmit(onSubmit)} className="my-5">
                                        <div className="px-8">
                                            <div className="grid grid-cols-2 mb-3">
                                                <div className="mr-1">
                                                    <p className="text-sm black-text mb-2 font-semibold">Customer Name<span className="red-text">*</span></p>
                                                    <CustomInput
                                                        control={control}
                                                        name={"customer_id"}
                                                        id={"customer_id"}
                                                        label={""}
                                                        placeholder={"Select Company & Customer Name"}
                                                        isRequired={false}
                                                        type={"text"}
                                                        errors={errors}
                                                        isDisabled={true}
                                                        defaultValue={single_shipment_data.client_name}
                                                        min={""}
                                                        max={""}
                                                        icon={""}
                                                    />
                                                </div>
                                                <div className="mr-1">
                                                    <p className="text-sm black-text mb-2 font-semibold">Company Address<span className="red-text">*</span></p>
                                                    <CustomInput
                                                        control={control}
                                                        name={"company_address"}
                                                        id={"company_address"}
                                                        label={""}
                                                        placeholder={"Cargo Pickup Date"}
                                                        isRequired={false}
                                                        type={"text"}
                                                        errors={errors}
                                                        isDisabled={true}
                                                        defaultValue={single_shipment_data.client_email}
                                                        min={""}
                                                        max={""}
                                                        icon={""}
                                                    />
                                                </div>

                                                

                                            </div>

                                            <div className="mt-3 grid grid-cols-2 mb-2">
                                                    <div className="mr-1">
                                                        <p className="text-sm black-text font-semibold">Select Origin Port<span className="red-text">*</span></p>
                                                            {/* <CustomInput
                                                                control={control}
                                                                name={"origin_port"}
                                                                id={"origin_port"}
                                                                label={""}
                                                                placeholder={""}
                                                                isRequired={false}
                                                                type={"text"}
                                                                errors={errors}
                                                                isDisabled={true}
                                                                defaultValue={single_shipment_data.origin_port_code}
                                                                min={""}
                                                                max={""}
                                                                icon={""}
                                                            /> */}
                                                            <CustomSelect
                                                                control={control}
                                                                name={"origin_port"}
                                                                id={"origin_port"}
                                                                label={""}
                                                                placeholder={"Origin Port"}
                                                                isRequired={false}
                                                                errors={errors}
                                                                isDisabled={false}
                                                                options={defaultPortsOfOrigin}
                                                                defaultValue={single_shipment_data.origin_port_code}
                                                                icon=""
                                                            />
                                                    </div>

                                                    <div className="mr-1">
                                                        <p className="text-sm black-text font-semibold">Select Destination Port<span className="red-text">*</span></p>
                                                        {/* <CustomInput
                                                            control={control}
                                                            name={"destination_port"}
                                                            id={"destination_port"}
                                                            label={""}
                                                            placeholder={""}
                                                            isRequired={false}
                                                            type={"text"}
                                                            errors={errors}
                                                            isDisabled={true}
                                                            defaultValue={single_shipment_data.destination_port_code}
                                                            min={""}
                                                            max={""}
                                                            icon={""}
                                                        /> */}
                                                        <CustomSelect
                                                            control={control}
                                                            name={"destination_port"}
                                                            id={"destination_port"}
                                                            label={""}
                                                            placeholder={"Destination Port"}
                                                            isRequired={false}
                                                            errors={errors}
                                                            isDisabled={false}
                                                            defaultValue={single_shipment_data.destination_port_code}
                                                            options={defaultPortsOfDestination}
                                                            icon=""
                                                        />
                                                    </div>
                                                </div>

                                            <div className="grid grid-cols-2">
                                                <div className="mr-1">
                                                    <p className="text-sm black-text font-semibold">Cargo Pickup Date<span className="red-text">*</span></p>
                                                    <CustomInput
                                                        control={control}
                                                        name={"cargo_pickup_date"}
                                                        id={"cargo_pickup_date"}
                                                        label={""}
                                                        placeholder={""}
                                                        isRequired={true}
                                                        type={"date"}
                                                        errors={errors}
                                                        isDisabled={false}
                                                        defaultValue={single_shipment_data.cargo_pickup_date}
                                                        min={""}
                                                        max={""}
                                                        icon={""}
                                                    />
                                                </div>

                                                <div className="ml-1">
                                                    <p className="text-sm black-text font-semibold">Total value of Goods (₦)<span className="red-text">*</span></p>
                                                    <CustomCurrencyInput
                                                        control={control}
                                                        name={"goods_value"}
                                                        id={"goods_value"}
                                                        label={""}
                                                        placeholder={""}
                                                        isRequired={true}
                                                        type={"number"}
                                                        errors={errors}
                                                        isDisabled={false}
                                                        defaultValue={single_shipment_data.goods_value}
                                                        min={1}
                                                        max={""}
                                                        icon=""
                                                    />
                                                    {/* <CustomInput
                                                        control={control}
                                                        name={"container_countgoods_value"}
                                                        id={"fullname"}
                                                        label={"Estimated Total Value of Goods"}
                                                        placeholder={"Estimated Total Value of Goods"}
                                                        isRequired={true}
                                                        type={"text"}
                                                        errors={errors}
                                                        isDisabled={false}
                                                        defaultValue={""}
                                                        min={""}
                                                        max={""}
                                                        icon={""}
                                                    /> */}
                                                </div>
                                            </div>

                                            <div className="mt-3 grid grid-cols-4">
                                                <div className="">
                                                    <p className="text-sm black-text font-semibold">Size of Container<span className="red-text">*</span></p>
                                                    <CustomInput
                                                        control={control}
                                                        name={"container_size"}
                                                        id={"container_size"}
                                                        label={""}
                                                        placeholder={""}
                                                        isRequired={true}
                                                        type={"text"}
                                                        errors={errors}
                                                        isDisabled={true}
                                                        defaultValue={single_shipment_data.container_size}
                                                        min={""}
                                                        max={""}
                                                        icon={""}
                                                    />
                                                </div>

                                                <div className="ml-1">
                                                <p className="text-sm black-text font-semibold">Type of Container<span className="red-text">*</span></p>
                                                    <CustomSelect
                                                        control={control}
                                                        name={`container_type`}
                                                        id={`container_type`}
                                                        label={""}
                                                        placeholder={`Container Type`}
                                                        isRequired={true}
                                                        errors={errors}
                                                        isDisabled={false}
                                                        options={[
                                                            { label: "Dry", value: "dry" },
                                                            { label: "Reefer", value: "reefer" },
                                                        ]}
                                                        defaultValue={single_shipment_data.container_type}
                                                        icon=""
                                                    />
                                                </div>

                                                <div className="ml-1">
                                                <p className="text-sm black-text font-semibold">Number Of Containers<span className="red-text">*</span></p>
                                                    <CustomInput
                                                        control={control}
                                                        name={"container_count"}
                                                        id={"container_count"}
                                                        label={""}
                                                        placeholder={""}
                                                        isRequired={true}
                                                        type={"text"}
                                                        errors={errors}
                                                        isDisabled={true}
                                                        defaultValue={single_shipment_data.container_count}
                                                        min={""}
                                                        max={""}
                                                        icon={""}
                                                    />
                                                </div>

                                                

                                                <div className="ml-1">
                                                    <p className="text-sm black-text font-semibold">Weight (TON)<span className="red-text">*</span></p>
                                                        <CustomInput
                                                            control={control}
                                                            name={"container_weight"}
                                                            id={"container_weight"}
                                                            label={""}
                                                            placeholder={"Weight"}
                                                            isRequired={true}
                                                            type={"text"}
                                                            errors={errors}
                                                            isDisabled={false}
                                                            defaultValue={single_shipment_data.container_weight}
                                                            min={""}
                                                            max={""}
                                                            icon={""}
                                                        />
                                                    </div>
                                                
                                                </div>

                                            <div className="mt-2">
                                                <div className="grid grid-cols-3">
                                                    <div className="">
                                                        <p className="text-sm black-text font-semibold">B/L Number</p>
                                                            <CustomInput
                                                                control={control}
                                                                name={"bl_number"}
                                                                id={"bl_number"}
                                                                label={""}
                                                                placeholder={""}
                                                                isRequired={false}
                                                                type={"text"}
                                                                errors={errors}
                                                                isDisabled={false}
                                                                defaultValue={single_shipment_data.bl_number === undefined ? 'N/A' :single_shipment_data.bl_number}
                                                                min={""}
                                                                max={""}
                                                                icon={""}
                                                            />
                                                    </div>
                                                

                                                    <div className="ml-1 ">
                                                        <p className="text-sm black-text font-semibold">Office / Branch</p>
                                                        <div className="mr-1">
                                                            <CustomInput
                                                                control={control}
                                                                name={"branch"}
                                                                id={"branch"}
                                                                label={""}
                                                                placeholder={""}
                                                                isRequired={false}
                                                                type={"text"}
                                                                errors={errors}
                                                                isDisabled={true}
                                                                defaultValue={single_shipment_data.branch}
                                                                min={""}
                                                                max={""}
                                                                icon={""}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="grid grid-cols-3">
                                                    <div className="flex items-center mb-5">
                                                        <div className="mr-3">
                                                            <CustomCheckBox
                                                                name="lagos"
                                                                id="lagos"
                                                                label="Lagos"
                                                                isRequired={true}
                                                                defaultChecked={false}
                                                                isDisabled={false}
                                                                onChange={(e : any) => setLagos(e.target.checked)}
                                                            />
                                                        </div>
                                                        

                                                        <div className="flex items-center ">
                                                            <div className="mr-3">
                                                                <CustomCheckBox
                                                                    name="kano"
                                                                    id="kano"
                                                                    label="Kano"
                                                                    isRequired={false}
                                                                    isDisabled={false}
                                                                    onChange={(e : any) => setKano(e.target.checked)}
                                                                    defaultChecked={false}
                                                                />
                                                            </div>
                                                            
                                                        </div>

                                                        <div className="mr-3">
                                                            <CustomCheckBox
                                                                name="harcourt"
                                                                id="harcourt"
                                                                label="Port-Harcourt"
                                                                isRequired={false}
                                                                isDisabled={false}
                                                                onChange={(e : any) => setHarcourt(e.target.checked)}
                                                                defaultChecked={false}
                                                            />
                                                        </div>

                                                        
                                                    </div>
                                            
                                                    
                                                </div> */}
                                            </div>

                                            <div className="mt-5">
                                                <p className="text-sm black-text mb-2 font-semibold">Services Provided <small>(Select all that apply)<span className="red-text">*</span></small></p>
                                                <div className="grid grid-cols-1">
                                                    <div className="flex items-center mb-5">
                                                        <div className="mr-3">
                                                            <CustomCheckBox
                                                                name="customs_brokerage"
                                                                id="customs_brokerage"
                                                                label="customs_brokerage"
                                                                isRequired={false}
                                                                isDisabled={false}
                                                                onChange={(e : any) => setCustoms(e.target.checked)}
                                                                // defaultChecked={single_shipment_data.customs_brokerage}
                                                                defaultChecked={false}
                                                            />
                                                        </div>

                                                        <div className="mr-3">
                                                            <CustomCheckBox
                                                                name="terminal_handling"
                                                                id="terminal_handling"
                                                                label="Terminal Handling"
                                                                isRequired={false}
                                                                isDisabled={false}
                                                                onChange={(e : any) => setThc(e.target.checked)}
                                                                // defaultChecked={single_shipment_data.terminal_handling}
                                                                defaultChecked={false}
                                                            />
                                                        </div>

                                                        <div className="mr-3">
                                                            <CustomCheckBox
                                                                name="ocean_freight"
                                                                id="ocean_freight"
                                                                label="Ocean Freight"
                                                                isRequired={false}
                                                                isDisabled={false}
                                                                onChange={(e : any) => setOceanFreight(e.target.checked)}
                                                                // defaultChecked={single_shipment_data.ocean_freight}
                                                                defaultChecked={false}
                                                            />
                                                        </div>

                                                        <div className="mr-3">
                                                            <CustomCheckBox
                                                                name="marine_insurance"
                                                                id="marine_insurance"
                                                                label="Marine Insurance"
                                                                isRequired={false}
                                                                isDisabled={false}
                                                                onChange={(e : any) => setInsurance(e.target.checked)}
                                                                // defaultChecked={single_shipment_data.marine_insurance}
                                                                defaultChecked={false}
                                                            />
                                                        </div>

                                                        <div className="mr-3">
                                                            <CustomCheckBox
                                                                name="warehousing"
                                                                id="warehousing"
                                                                label="Warehousing"
                                                                isRequired={false}
                                                                isDisabled={false}
                                                                onChange={(e : any) => setWarehousing(e.target.checked)}
                                                                // defaultChecked={single_shipment_data.warehousing}
                                                                defaultChecked={false}
                                                            />
                                                        </div>

                                                        
                                                    </div>
                                            
                                                    
                                                </div>
                                            </div>

                                            

                                        </div>
                                        <div className="px-8 mb-5">
                                            <hr className={`solid-br`} />
                                            <div className="w-24 mt-5">
                                                {" "}
                                                {/* @ts-ignore */}
                                                <PrimaryButton title="Submit Form" loading={loading} />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </>
                    )
                }
                
            </div>
        </div>
    )
}

// export default EditShipmentDetails;

const mapStateToProps = (state: any) => {
    const { single_shipment, loading } = state.shipments;
    return { single_shipment, loading };
};

export default connect(mapStateToProps, { getSingleShipment, editShipmentDetails })(EditShipmentDetails);
