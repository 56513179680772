// import { generateActions } from "helpers/generateActions";
export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const GET_CUSTOMER_SUCCESS = "GET_CUSTOMER_SUCCESS";

export const CREATE_CUSTOMER = "CREATE_CUSTOMER";
export const CREATE_CUSTOMER_SUCCESS = "CREATE_CUSTOMER_SUCCESS";

export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS";

export const GET_SINGLE_CUSTOMER = "GET_SINGLE_CUSTOMER";
export const GET_SINGLE_CUSTOMER_SUCCESS = "GET_SINGLE_CUSTOMER_SUCCESS";

export const SEARCH_CUSTOMER = "SEARCH_CUSTOMER";
export const SEARCH_CUSTOMER_SUCCESS = "SEARCH_CUSTOMER_SUCCESS";

export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const DELETE_CUSTOMER_SUCCESS = "DELETE_CUSTOMER_SUCCESS";


export const API_ERROR = "API_ERROR";

