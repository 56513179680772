import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import NewCustomRadio from "components/selectInputs/NewCustomRadio";
import { Link, useParams } from "react-router-dom";
import PrimaryButton from "components/buttons/PrimaryButton";
import userIcon from "assets/icons/user.svg";

const ShipmentType = (props: any) => {
    const { loading, nextStep, shipmentType, setShipmentType, transportationType, setTransportationType } = props;
    const [openAside, SetOpenAside] = useState(false);
    const { handleSubmit, control, formState: { errors } } = useForm();
    const [showNext, setShowNext] = useState(false);
    // const [shipmentType, setShipmentType] = useState('');
    // const [transportationType, setTransportationType] = useState('');
    // const [haulage, setHaulage] = useState('Yes')

    console.log(shipmentType)
    console.log(transportationType)

    const onSubmit = (data: any) => {
        const newData = {
            shipment_type: shipmentType,
            transportation_type: transportationType
        }
        console.log(data);
    }

    return (
        <div className='lg:px-10 px-5 py-10 mb-3'>
            <p className='upload-text'>Fill in the information and add a shipment for a customer</p>
            <p className='add-shipment-text'>What shippment type do you require?</p>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mt-3">
                    <p className="text-sm quote-text mb-2">Select Shipment Type</p>
                    <div className="lg: grid grid-cols-3">
                        <div className="">
                            <NewCustomRadio
                                selected={shipmentType === 'export' ? true : false}
                                label={"Export"}
                                onClick={() => setShipmentType('export')}
                                icon={''}
                                isDisabled={false}
                            />
                        </div>

                        <div className="ml-2">
                            <NewCustomRadio
                                selected={shipmentType === 'import' ? true : false}
                                label={"Import"}
                                onClick={() => setShipmentType('import')}
                                icon={''}
                                isDisabled={false}
                            />
                        </div>


                    </div>
                </div>
                <div className="py-8">
                    <p className='add-shipment-text mb-3'>What mode of Transportation would you be looking for?</p>
                    <div>
                        <p className="text-sm quote-text mb-2">Select Transportation Type</p>
                        <div className="grid grid-cols-3">
                            <div className="">
                                <NewCustomRadio
                                    selected={transportationType === 'Ocean Freight' ? true : false}
                                    label={"Ocean Freight"}
                                    icon={''}
                                    isDisabled={true}
                                    onClick={() => {
                                        setTransportationType('Ocean Freight')
                                        // setShowNext(!showNext);
                                    }}

                                />
                            </div>

                            <div className="ml-2">
                                <NewCustomRadio
                                    selected={transportationType === 'Air Freight' ? true : false}
                                    label={"Air Freight"}
                                    icon={''}
                                    isDisabled={true}
                                    onClick={() => {
                                        setTransportationType('Air Freight')
                                        // setShowNext(!showNext);
                                    }}
                                />
                            </div>

                            <div className="ml-2">
                                <NewCustomRadio
                                    selected={transportationType === 'Haulage' ? true : false}
                                    label={"Haulage"}
                                    icon={''}
                                    isDisabled={true}
                                    onClick={() => {
                                        setTransportationType('Haulage')
                                        // setShowNext(!showNext);
                                    }}
                                />
                            </div>


                        </div>
                    </div>
                </div>
                <div className="mb-5">
                    <hr className={`solid-br`} />
                    {
                        props.shipmentType === null && props.transportationType === null ||
                            props.shipmentType === 'export' && props.transportationType === null ||
                            props.shipmentType === 'import' && props.transportationType === null ||
                            props.shipmentType === null && props.transportationType === "Ocean Freight" ||
                            props.shipmentType === null && props.transportationType === "Air Freight" ||
                            props.shipmentType === null && props.transportationType === "Haulage" ?
                            (
                                <>
                                    <div className="grid grid-cols-3 mt-10 items-center">
                                        <div></div>
                                        <div></div>
                                        <div className="" style={{ textAlign: 'right' }}>
                                            {" "}
                                            {/* @ts-ignore */}
                                            <Link
                                                to=''
                                                className="not-allowed solid-br black-text text-sm py-3.5 px-4 w-full rounded flex"
                                                style={{ background: 'rgba(136, 136, 136, 0.15)', justifyContent: 'center' }}
                                            >
                                                Next
                                            </Link>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="grid grid-cols-3 mt-10 items-center">
                                        <div></div>
                                        <div></div>
                                        <div className="" style={{ textAlign: 'right' }}>
                                            {" "}
                                            {/* @ts-ignore */}
                                            <PrimaryButton
                                                title="Next"
                                                loading={loading}
                                                onClick={nextStep}
                                            />
                                        </div>
                                    </div>

                                </>
                            )
                    }
                </div>

            </form>
        </div>
    )
}

export default ShipmentType;