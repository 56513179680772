import { get } from "helpers/axios";
import { getDashboardAnalyticsUrl } from "api/endpoints";

const getDashboardAnalytics = () => {
    return get(getDashboardAnalyticsUrl);
}

const dashboardServices = {
    getDashboardAnalytics,
}

export default dashboardServices;