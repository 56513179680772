import React from 'react'

const AdditionalDetailsTab = () => {
    return (
        <div>
            AdditionalDetailsTab
        </div>
    )
}

export default AdditionalDetailsTab;

// import React from "react";
// // import { Link } from "react-router-dom";

// // icons
// // import edit from "assets/icons/edit.svg";

// function ShipmentAdditionalDetails() {
//     return (
//         <div className="shippingdetails-height">
//             <div className="mt-7">
//                 <div className="flex p-5 solid-br rounded-t-lg">
//                     <div className="">
//                         <p className="black-text text-sm font-medium">Do you need warehousing?</p>
//                     </div>

//                     <div className="ml-auto">
//                         <p className="black-text text-sm font-medium text-right">Yes</p>
//                     </div>
//                 </div>
//                 <div className="flex p-5  left-divider right-divider">
//                     <div className="">
//                         <p className="black-text text-sm font-medium">Duration</p>
//                     </div>

//                     <div className="ml-auto">
//                         <p className="black-text text-sm font-medium text-right">2 weeks</p>
//                     </div>
//                 </div>

//                 <div className="flex p-5 top-divider left-divider right-divider">
//                     <div className="">
//                         <p className="black-text text-sm font-medium">Pick up date</p>
//                     </div>

//                     <div className="ml-auto">
//                         <p className="black-text text-sm font-medium text-right">May 22, 2022</p>
//                     </div>
//                 </div>

//                 <div className="flex p-5 solid-br rounded-b-lg items-center">
//                     <div className="">
//                         <p className="black-text text-sm font-medium">Insurance</p>
//                     </div>

//                     <div className="ml-auto lg:pl-40">
//                         <p className="black-text text-sm font-medium text-right ">Yes</p>
//                     </div>
//                 </div>
//             </div>

//             {/* <div className="flex mt-5">
// 				<div className="ml-auto">
// 				<Link
// 					to="#"
// 					className="bg-grey white-text text-sm py-3 px-4 w-full rounded  flex"
// 				>
// 					Edit Additional Details{" "}
// 					<img src={edit} alt="" width={15} height={15} className="ml-2" />
// 				</Link>
// 				</div>
// 			</div> */}
//         </div>
//     );
// }

// export default ShipmentAdditionalDetails;
