// import { generateActions } from "helpers/generateActions";
export const GET_QUOTES = "GET_QUOTES";
export const GET_QUOTES_SUCCESS = "GET_QUOTES_SUCCESS";

export const GET_NEW_SINGLE_QUOTE = "GET_NEW_SINGLE_QUOTE";
export const GET_NEW_SINGLE_QUOTE_SUCCESS = "GET_NEW_SINGLE_QUOTE_SUCCESS";


export const API_ERROR = "API_ERROR";

