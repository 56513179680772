import React from 'react'

const RatesTab = () => {
    return (
        <div>
            ratesTab
        </div>
    )
}

export default RatesTab;