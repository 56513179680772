// import { generateActions } from "helpers/generateActions";

export const GET_ALL_INVOICES = "GET_ALL_INVOICES";
export const GET_ALL_INVOICES_SUCCESS = "GET_ALL_INVOICES_SUCCESS";
export const GET_SINGLE_INVOICE = "GET_SINGLE_INVOICE";
export const GET_SINGLE_INVOICE_SUCCESS = "GET_SINGLE_INVOICE_SUCCESS";
export const GET_SINGLE_INVOICE_DETAILS = "GET_SINGLE_INVOICE_DETAILS";
export const GET_SINGLE_INVOICE_DETAILS_SUCCESS = "GET_SINGLE_INVOICE_DETAILS_SUCCESS";
export const ATTACH_INVOICE = "ATTACH_INVOICE";
export const ATTACH_INVOICE_SUCCESS = "ATTACH_INVOICE_SUCCESS";
export const ATTACH_PROOF_OF_PAYMENT = "ATTACH_PROOF_OF_PAYMENT";
export const ATTACH_PROOF_OF_PAYMENT_SUCCESS = "ATTACH_PROOF_OF_PAYMENT_SUCCESS";
export const DELETE_INVOICE = "DELETE_INVOICE";
export const DELETE_INVOICE_SUCCESS = "DELETE_INVOICE_SUCCESS";


export const API_ERROR = "API_ERROR";