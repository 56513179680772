import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import "react-sliding-pane/dist/react-sliding-pane.css";
import SlidingPane from "react-sliding-pane";
import onePortLogo from "assets/images/oneport-logo.png";
import NewOnePortLogo from "assets/logos/new-logo.png";
import Dashboard from "assets/aside-logo/dashboard.png";
import Admin from "assets/aside-logo/admin.png";
import Customer from "assets/aside-logo/customer.png";
import Invoice from "assets/aside-logo/invoice.png";
import Shipment from "assets/aside-logo/shipment.png";
import Containers from "assets/aside-logo/container.png";
import Quote from "assets/aside-logo/quote-request.png";
import Booking from "assets/aside-logo/booking.png";
import Settings from "assets/aside-logo/settings.png";
import Logout from "assets/aside-logo/poweroff.png";
import "./index.css";
import LogoutModal from "components/partials/logoutModal";
import { logoutUser } from "store/actions";

const Aside = (props: any): JSX.Element => {
	const [isOpen, setIsOpen] = useState(false);
	const { activeTab, openAside, SetOpenAside } = props;
	let admin_data = useSelector((state: any) => state.auth.admin_data);
	//   @ts-ignore
	admin_data = JSON.parse(localStorage.getItem("admin_data"));
	// ? JSON.parse(localStorage.getItem("admin_data"))
	// : JSON.parse(localStorage.getItem("admin_data"));

	return (
		<>
			{/* Desktop Aside */}
			<div className="dashboard-aside flex flex-col right-divider desktop-only shadow-2xl">
				<div className="px-5">
					<div className="py-8">
						<Link to="/dashboard">
							<img
								alt=""
								src={NewOnePortLogo}
								className="w-18 h-10 desktop-only"
							/>
						</Link>
					</div>

					<div className="py-10">
						<div className="pl-3 flex items-center mb-3">
							<div className="flex items-center">
								<p className="navigation-menu-text">MENU</p>
							</div>
						</div>

						<NavLink to="/dashboard" style={{ textDecoration: "none" }}>
							{({ isActive }) => (
								<div
									className={
										isActive
											? "flex items-center mb-5 pb-2 border-b-2 solid-break"
											: "flex items-center mb-5"
									}
								>
									<p className="pl-3">
										<img src={Dashboard} width="60%" height="50%" />
									</p>
									<div className="flex items-center ">
										<p className="navigation-text">Dashboard</p>
									</div>
								</div>
							)}
						</NavLink>

						{/* Adnin */}

						{/* {admin_data.role == "super_admin" ? (
							<>
								<NavLink to="/admins" style={{ textDecoration: "none" }}>
									{({ isActive }) => (
										<div
											className={
												isActive
													? "flex items-center mb-5 pb-2 border-b-2 solid-break"
													: "flex items-center mb-5"
											}
										>
											<p className="pl-3">
												<img src={Admin} width="60%" height="60%" />
											</p>
											<div className="flex items-center ">
												<p className="navigation-text">Admin</p>
											</div>
										</div>
									)}
								</NavLink>
							</>
						) : (
							<></>
						)} */}

						{/* Admin */}

						{/* Shipping */}

						{/* <NavLink to="/customers" style={{ textDecoration: "none" }}>
							{({ isActive }) => (
								<div
									className={
										isActive
											? "flex items-center mb-5 pb-2 border-b-2 solid-break"
											: "flex items-center mb-5"
									}
								>
									<p className="pl-3">
										<img src={Customer} width="60%" height="50%" />
									</p>
									<div className="flex items-center ">
										<p className="navigation-text">Customers</p>
									</div>
								</div>
							)}
						</NavLink> */}

						<NavLink
							to="/shipments"
						// onClick={() => {
						// 	window.location.href = `/shipments`;
						// }}
						// style={{ textDecoration: "none" }}
						>

							{({ isActive }) => (
								<div
									className={
										isActive
											? "flex items-center mb-5 pb-2 border-b-2 solid-break"
											: "flex items-center mb-5"
									}
								>
									<p className="pl-3">
										<img src={Shipment} width="60%" height="60%" />
									</p>
									<div className="flex items-center ">
										<p className="navigation-text">Shipments</p>
									</div>
								</div>
							)}
						</NavLink>



						<NavLink to="/containers" style={{ textDecoration: "none" }}>
							{({ isActive }) => (
								<div
									className={
										isActive
											? "flex items-center mb-5 pb-2 border-b-2 solid-break"
											: "flex items-center mb-5"
									}
								>
									<p className="pl-3">
										<img src={Containers} width="60%" height="60%" />
									</p>
									<div className="flex items-center ">
										<p className="navigation-text">Containers</p>
									</div>
								</div>
							)}
						</NavLink>

						<NavLink to="/settings" style={{ textDecoration: "none" }}>
							{({ isActive }) => (
								<div
									className={
										isActive
											? "flex items-center mb-10 border-b-2 solid-break pb-2"
											: "flex items-center mb-10"
									}
								>
									<p className="pl-3">
										<img src={Settings} width="60%" height="50%" />
									</p>
									<div className="flex items-center ">
										<p className="navigation-text">Settings</p>
									</div>
								</div>
							)}
						</NavLink>






					</div>
				</div>

				<div className="mb-3 px-4 absolute bottom-0 w-3/12">
					<div className="flex items-center py-1">
						<Link
							to="#"
							onClick={() => {
								setIsOpen(true);
								// window.localStorage.removeItem('token')
							}}
							style={{ textDecoration: "none" }}
						>
							<div className="flex items-center mb-5">
								<p className="pl-3">
									<img src={Logout} width="60%" height="50%" />
								</p>
								<div className="flex items-center ">
									<p className="navigation-text">Logout</p>
								</div>
							</div>
						</Link>
					</div>
				</div>
			</div>
			<LogoutModal isOpen={isOpen} setIsOpen={setIsOpen} />
			{/* Desktop Aside */}

			{/* Mobile Aside */}
			{openAside ? (
				<>
					<SlidingPane
						className="custom-slider sliding-aside"
						overlayClassName="some-custom-overlay-class"
						isOpen={openAside}
						from="left"
						hideHeader={true}
						width="50%"
						shouldCloseOnEsc={true}
						onRequestClose={() => {
							// triggered on "<" on left top click or on outside click
							SetOpenAside(false);
						}}
					>
						<div
							className=""
						// style={{
						//     background: '#286a3a'
						// }}
						>
							<div className="flex">
								<i
									className="ion-ios-close py-1 px-4 bg-grey text-3xl rounded-full black-text cursor-pointer ml-auto"
									onClick={() => SetOpenAside(false)}
								></i>
							</div>

							<div className="pl-3 flex items-center mb-5">
								<div className="flex items-center">
									<p className="navigation-menu-text">MENU</p>
								</div>
							</div>

							<NavLink to="/dashboard" style={{ textDecoration: "none" }}>
								{({ isActive }) => (
									<div
										className={
											isActive
												? "flex items-center mb-10 border-b-2 solid-break pb-2"
												: "flex items-center mb-10"
										}
									>
										<p className="pl-3">
											<img src={Dashboard} width="60%" height="50%" />
										</p>
										<div className="flex items-center ">
											<p className="navigation-text">Dashboard</p>
										</div>
									</div>
								)}
							</NavLink>




							<NavLink to="/shipments" style={{ textDecoration: "none" }}>
								{({ isActive }) => (
									<div
										className={
											isActive
												? "flex items-center mb-10 border-b-2 solid-break pb-2"
												: "flex items-center mb-10"
										}
									>
										<p className="pl-3">
											<img src={Shipment} width="60%" height="60%" />
										</p>
										<div className="flex items-center ">
											<p className="navigation-text">Shipments</p>
										</div>
									</div>
								)}
							</NavLink>
							{/* <NavLink
								to=""
								onClick={() => {
									window.location.href = `/shipments`;
								}}
								style={{ textDecoration: "none" }}
							>
								<div className="flex items-center mb-5 pl-2">
									<p>
										<img src={Shipment} width="70%" height="70%" />
									</p>
									<div className="flex items-center">
										<div>
											<p className="navigation-text">Shipments</p>
										</div>
									</div>
								</div>
							</NavLink> */}

							<NavLink to="/containers" style={{ textDecoration: "none" }}>
								{({ isActive }) => (
									<div
										className={
											isActive
												? "flex items-center mb-10 border-b-2 solid-break pb-2"
												: "flex items-center mb-10"
										}
									>
										<p className="pl-3">
											<img src={Containers} width="60%" height="60%" />
										</p>
										<div className="flex items-center ">
											<p className="navigation-text">Containers</p>
										</div>
									</div>
								)}
							</NavLink>



							{/* payment */}

							<NavLink to="/settings" style={{ textDecoration: "none" }}>
								{({ isActive }) => (
									<div
										className={
											isActive
												? "flex items-center mb-10 border-b-2 solid-break pb-2"
												: "flex items-center mb-10"
										}
									>
										<p className="pl-3">
											<img src={Settings} width="60%" height="50%" />
										</p>
										<div className="flex items-center">
											<p className="navigation-text">Settings</p>
										</div>
									</div>
								)}
							</NavLink>


						</div>
					</SlidingPane>
				</>
			) : (
				<></>
			)}
		</>
	);
};

export default connect(null, { logoutUser })(Aside);
