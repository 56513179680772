import { GET_DASHBOARD_ANALYTICS, GET_DASHBOARD_ANALYTICS_SUCCESS, API_ERROR } from "./constants";

export const getDashboardAnalytics = () => {
    return {
        type: GET_DASHBOARD_ANALYTICS,
    }
}

export const getDashboardAnalyticsSuccess = (response:any) => {
    return {
        type: GET_DASHBOARD_ANALYTICS_SUCCESS,
        payload: response,
    }
}

export const DashboardApiError = (error: any) => {
    return {
        type: API_ERROR,
        payload: error,
    };
}