import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import Aside from "components/partials/aside";
import TopBar from "components/partials/topBar";
import moment from "moment";
import DataTable from "react-data-table-component";
import CustomTabs from "components/customTabs/CustomTabs";
import PrimaryButton from "components/buttons/PrimaryButton";
import arrowUp from "assets/icons/arrow-up.svg";
import arrowDown from "assets/icons/arrow-down.svg";
import ContainerDetailsDrawer from "./containerTabPartials/containerDetailsDrawer";
import ContainerStatusDrawer from "./containerTabPartials/containerStatusDrawer";
import SingleContainerStatus from "./containerTabPartials/singleContainerStatusUpdate";
import movement from "assets/icons/movement.svg";
import DeleteModal from "./containerTabPartials/deleteContainer";
import { deleteContainer } from "store/actions";
import "../../index.css";
import TrackingModal from './trackingModal/trackingModal';

const ContainerDetails = (props: any) => {
	const params = useParams();
	const { single_shipment } = props;
	const single_shipment_data = single_shipment?.data?.data;
	// console.log(single_shipment_data.container_details)
	const [openAside, SetOpenAside] = useState(false);
	const [tab, setTab] = useState("Shipment Details");
	const [isUpdateContainer, setIsUpdateContainer] = useState(false);
	const [dataToUpdate, setDataToUpdate] = useState({});
	const [isSingleStatusUpdate, setIsSingleStatusUpdate] = useState(false);
	const [singleStatusToUpdate, setSingleStatusToUpdate] = useState({});
	const [singleStatusRow, setSingleStatusRow] = useState();
	const [isUpdateStatus, setIsUpdateStatus] = useState(false);
	const [statusToUpdate, setStatusToUpdate] = useState({});
	const [containerId, setContainerId] = useState([]);
	const [selectedContainer, setSelectedContainer] = useState({});
	//   state to store boolean value of selected row
	const [selected, setSelected] = useState<any>([]);
	// state to store the row of the clicked container
	const [isOpen, setIsOpen] = useState(false);
	const [containerToTrack, setContainerToTrack] = useState({});
	const [dataToDelete, setDataToDelete] = useState({});
	const [filteredContainers, setFilteredContainers] = useState([]);

	let admin_data = useSelector((state: any) => state.auth.admin_data);
	// @ts-ignore
	//prettier-ignore
	admin_data = JSON.parse(localStorage.getItem("admin_data")) ? JSON.parse(localStorage.getItem("admin_data")) : JSON.parse(localStorage.getItem("admin_data"));

	const updateContainerData = (selected_ids) => {
		let container_ids: string[] = [];
		selected_ids.forEach(({ _id }) => {
			container_ids.push(_id);
		});
		// @ts-ignore
		setContainerId(container_ids);
	};
	console.log(selectedContainer);

	useEffect(() => {
		setFilteredContainers(single_shipment_data?.container_details);
	}, [single_shipment_data?.container_details]);
	console.log(single_shipment_data)

	const customStyles = {
		rows: {
			style: {
				minHeight: "50px",
			},
		},
		headCells: {
			style: {
				paddingLeft: "8px",
				paddingRight: "8px",
				backgroundColor: "#f0fdf4",
			},
		},
		cells: {
			style: {
				paddingLeft: "8px",
				paddingRight: "8px",
			},
		},
	};

	const columns = [
		{
			name: "Job Number",
			selector: (row) => {
				return (
					<div>
						{single_shipment_data?.job_number === undefined ? "N/A" : single_shipment_data?.job_number}
					</div>
				);
			},
			sortable: true,
		},
		{
			name: "Container Number",
			selector: (row) => {
				return (
					<div className="uppercase">
						{row?.container_number === null ? "N/A" : row?.container_number}
					</div>
				);
			},
			sortable: true,
		},
		{
			name: "Seal Number",
			selector: (row) => {
				return (
					<div className="uppercase">
						{row?.shipping_line_seal_number === null ? "N/A" : row?.shipping_line_seal_number}
					</div>
				);
			},
			sortable: true,
		},
		{
			name: "B/L Number",
			selector: (row) => {
				return (
					<div>
						{/* {row?.bl_number === undefined ? 'Unavailable' : row?.bl_number} */}
						{single_shipment_data?.bl_number === "" ? "N/A" : single_shipment_data?.bl_number}
					</div>
				);
			},
		},
		{
			name: "Size",
			selector: (row) => {
				return <div>{single_shipment_data?.container_details[0]?.container_size}</div>;
			},
		},
		{
			name: "Status",
			selector: (row) => {
				return (
					<div
						className="bg-light-green green-text py-1 px-2 rounded-full mt-3 mb-3"
						style={{ marginRight: "10px" }}
					>
						<small>{row?.container_status}</small>
					</div>
				);
			},
		},
		{
			name: "",
			selector: (row) => {
				return (
					<div>
						{
							row?.container_details?.container_number === null ? (
								<>
									<div
										className="bg-green p-1 rounded cursor-pointer"
										style={{ justifyContent: "center" }}
										onClick={() => {
											setSelectedContainer(row);
											setIsOpen(true);
											setContainerToTrack(row?._id)
										}}
									>
										<p className="text-xs text-center white-text font-semibold p-2">
											<small>View Tracking</small>
										</p>
									</div>
								</>

							) : (
								<>
									<div
										className="bg-green p-1 rounded cursor-pointer"
										style={{ justifyContent: "center" }}
										onClick={() => {
											setSelectedContainer(row);
											setIsOpen(true);
											setContainerToTrack(row?._id)
										}}
									>
										<p className="text-xs text-center white-text font-semibold p-2">
											<small>View Tracking</small>
										</p>
									</div>
								</>
							)
						}
					</div>
				);
			},
		},
	];

	const handleSearch = (event) => {
		console.log("this is the string written", event);
		let value = event;
		console.log("this is the string value written", event);
		const results: any = [];

		const matchShipment = single_shipment_data?.container_details?.filter(
			(single_shipment_data) => {
				//prettier-ignore
				return `${single_shipment_data?.container_number} ${single_shipment_data?.shipping_line_seal_number}`
					.toLowerCase()
					.includes(event.target.value.toLowerCase())
			}
		);

		setFilteredContainers(results);
		// single_shipment_data?.container_details(results);
	};
	//   console.log(
	//     "seal number",
	//     single_shipment_data.container_details[0]?.container_number
	//   );

	return (
		<div>
			<>
				<div className="flex items-center">
					<>
						<input
							placeholder="Search By Container Number"
							className="form-input px-4 py-1.5 custom-input w-full black-text w-52 mb-5"
							onChange={(e) => handleSearch(e.target.value)}
						// onChange={(e) => handleSearch(e)}
						/>
					</>
					{/* Button to Update Multiple Status Should be disabled if the checkbox is not clicked */}
				</div>

				<div className="pt-3 solid-br desktop-only">
					<DataTable
						//@ts-ignore
						columns={columns}
						data={filteredContainers}
						pagination
						persistTableHead
						responsive
						customStyles={customStyles}
						// selectableRows
						selectableRowsHighlight={false}
						onSelectedRowsChange={({ selectedRows }) => {
							updateContainerData(selectedRows);
							setSelected(selectedRows);
						}}
						paginationPerPage={30}
					// actions={
					// 	<ActionsMemo handleSearch={handleSearch} />
					// }
					/>
				</div>

				{/* Mobile View */}
				<div className="bg-grey py-2 mobile-only">
					{single_shipment_data?.container_details.map(
						(data: any, index: any) => {
							return (
								<div className="bg-white mb-3" key={index}>
									<div className="grid grid-cols-1 gap-4 py-3 px-7 bottom-divider items-center ">
										<div className="">
											<p className="grey-text text-sm">
												<span className="black-text">
													Container Number:{" "}
													{data?.["container_number"] === null
														? "N/A"
														: data?.container_number}{" "}
												</span>
											</p>
											<p className="grey-text text-sm">
												<span className="black-text">
													Seal Number:{" "}
													{data?.["seal_number"] === null
														? "N/A"
														: data?.seal_number}
												</span>
											</p>
											<p className="grey-text text-sm">
												<span className="black-text">
													B/L Number:{" "}
													{data?.["bl_number"] === null
														? "N/A"
														: data?.bl_number}
												</span>
											</p>
											<p className="grey-text text-sm">
												<span className="black-text">
													Continer Size:{" "}
													{data?.container_size === undefined
														? "Unavailable"
														: data?.container_size}
												</span>
											</p>
											<p className="grey-text text-sm">
												<span className="black-text">
													Continer Status:{" "}
													{data?.container_status === undefined
														? "Unavailable"
														: data?.container_status}
												</span>
											</p>
											<p className="grey-text text-sm">
												<span className="black-text">
													Last Updated:{" "}
													{moment(data?.createdAt).format("DD/MM/YYYY")}
												</span>
											</p>
										</div>
									</div>

									<div className="grid grid-cols-3 gap-4 py-3 px-7 bottom-divider items-center">
										<Link
											to=""
											onClick={() => {
												window.location.href = `/customers/customer-shipment/${data?.["_id"]}`;
											}}
										>
											{/* Shipments */}
										</Link>

										<div className="">
											<p className="white-text font-semibold text-sm">
												{data?.["company_name"]}
											</p>
										</div>

										<Link
											to=""
											onClick={() => {
												setDataToUpdate(data?._id);
												setIsUpdateContainer(true);
											}}
											className="bg-green white-text text-xs py-2 text-center w-full rounded mr-3"
										>
											Edit
										</Link>
									</div>
								</div>
							);
						}
					)}
				</div>
				{/* Mobile View */}
			</>

			<TrackingModal
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				id={containerToTrack}
				selectedContainer={selectedContainer}
			/>

			{/* <ContainerDetailsDrawer
				isOpen={isUpdateContainer}
				setIsOpen={setIsUpdateContainer}
				container_id={dataToUpdate}
				selectedContainer={selectedContainer}
				setSelectedContainer={setSelectedContainer}
				single_shipment={single_shipment}
			/>
			<ContainerStatusDrawer
				isOpen={isUpdateStatus}
				setIsOpen={setIsUpdateStatus}
				container_id={statusToUpdate}
				single_shipment={single_shipment}
				containerId={containerId}
				row_number={singleStatusRow}
			/>
			<SingleContainerStatus
				isOpen={isSingleStatusUpdate}
				setIsOpen={setIsSingleStatusUpdate}
				container_id={singleStatusToUpdate}
				single_shipment={single_shipment}
				row_number={singleStatusRow}
			/>

			<DeleteModal
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				id={dataToDelete}
				deleteContainer={props.deleteContainer}
				selectedContainer={selectedContainer}
				setSelectedContainer={setSelectedContainer}
			/> */}
		</div>
	);
};

// export default ContainerDetails

const mapStateToProps = (state: any) => {
	const { error, loading } = state.shipments;
	return { error, loading };
};

// Table actions
const ActionsMemo = (props: any): JSX.Element => {
	const { handleSearch } = props;
	return (
		<>
			<input
				placeholder="Search By Container Number"
				className="form-input px-4 py-1.5 custom-input w-full black-text w-52 mb-5"
				onChange={(e) => handleSearch(e.target.value)}
			// onChange={(e) => handleSearch(e)}
			/>
		</>
	);
};

export default connect(mapStateToProps, { deleteContainer })(ContainerDetails);
