// import { generateActions } from "helpers/generateActions";
export const GET_LOAN_APPLICATIONS = "GET_LOAN_APPLICATIONS";
export const GET_LOAN_APPLICATIONS_SUCCESS = "GET_LOAN_APPLICATIONS_SUCCESS";

export const GET_SINGLE_LOAN_APPLICATION = "GET_SINGLE_LOAN_APPLICATION";
export const GET_SINGLE_LOAN_APPLICATION_SUCCESS = "GET_SINGLE_LOAN_APPLICATION_SUCCESS";

export const UPDATE_LOAN_PAYMENT_STATUS = "UPDATE_LOAN_PAYMENT_STATUS";
export const UPDATE_LOAN_PAYMENT_STATUS_SUCCESS = "UPDATE_LOAN_PAYMENT_STATUS_SUCCESS"

export const API_ERROR = "API_ERROR";