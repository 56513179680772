import React, { useEffect, useState } from "react";
import Aside from "components/partials/aside";
import TopBar from "components/partials/topBar";
import { Link, useParams, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import arrowUp from "assets/icons/arrow-up.svg";
import arrowDown from "assets/icons/arrow-down.svg";
import movement from "assets/icons/movement.svg";
import moment from "moment";
import Moment from "react-moment";
import CustomTabs from "components/customTabs/CustomTabs";
import PrimaryButton from "components/buttons/PrimaryButton";
import { getSingleBooking } from "store/actions";
import BookingEdits from "./bookingDetailPartials/dynamicBookingEdit";
import CurrencyFormat from "react-currency-format";
import UpdateBookingStatus from "./bookingDetailPartials/updateBookingStatus/updateBookingStatus";

const BookingDetails = (props: any) => {
	const [openAside, SetOpenAside] = useState(false);
	const params = useParams();
	const { single_booking, loading } = props
	const [isOpen, setIsOpen] = useState(false);
	console.log(single_booking);
	console.log(single_booking?.shipment_status)

	// const fetchSingleBooking = async page => {
	// 	await getSingleBooking(`id=${params.id}`, `format_containers=${false}`);
	// }

	useEffect(() => {
		// props.getSingleBooking(params.id, `format_containers=${true}`);
		props.getSingleBooking(params.id, "")
	}, []);

	return (
		<>
			<div className="lg:flex">
				<Aside
					activeTab="Bookings"
					openAside={openAside}
					SetOpenAside={SetOpenAside}
				/>
				<div className="">
					<TopBar title={"Booking Details"} SetOpenAside={SetOpenAside} />
					<div className="dashboard-content">
						<div className="lg:px-10 lg:pt-5 container mx-auto w-full">
							{
								loading ?
									(
										<div className="text-center my-3">
											<Link to="#" className="text-success">
												{/* @ts-ignore */}
												<PrimaryButton title="Loading Booking Details" loading={loading} />
											</Link>
										</div>
									) : (
										<>
											<div>
												<div className="lg:pb-3 lg:pt-3 grid grid-cols-2 gap-4">
													<div className="mt-2">
														<p className='font-semibold text-lg pl-3'>Job Number: {single_booking?.job_number}<span className="green-text"> </span></p>
													</div>
													{/* <div className="mt-2 ml-auto" style={{ textAlign: 'right' }}>
														<small>Booking Detail</small><small style={{ color: 'grey' }}> : {params.id}</small>
													</div> */}
												</div>
											</div>
											<>
												<div
													className="bg-white"
													style={{ top: 20 }}
												>
													<div className="grid grid-cols-3 items-center p-5 bg-green lg:rounded-lg">
														<div className="flex items-center">
															<img
																src={
																	single_booking?.shipment_type === "export"
																		? arrowUp
																		: arrowDown
																}
																alt=""
																width={40}
																height={40}
																className="bg-light-green p-2 rounded-full"
															/>

															<p className="white-text text-sm ml-2 capitalize">
																{single_booking?.shipment_type}
															</p>
														</div>

														<div className="text-center">
															<div className="flex items-center justify-center"></div>
															<div className="flex items-center justify-center mb-3">
																<div className="flex items-center">
																	<p className="navigation-text">
																		{/* {single_booking.origin_port_code} */}
																		{
																			single_booking?.shipment_type === "export" && single_booking?.shipment_transport_type === "ocean_freight" ? (
																				single_booking?.origin_port_code

																			) : single_booking?.shipment_type === "import" && single_booking?.shipment_transport_type === "ocean_freight" ? (
																				single_booking?.port_of_discharge

																			) : single_booking?.shipment_type === "export" && single_booking?.shipment_transport_type === "haulage" ? (
																				single_booking?.stuffing_location

																			) : single_booking?.shipment_type === "import" && single_booking?.shipment_transport_type === "haulage" ? (
																				single_booking?.pickup_location
																			) : (
																				single_booking?.origin_port_code
																			)}
																	</p>
																</div>
																<p className="lg:pl-7 pl-3">
																	<img src={movement} width="60%" height="50%" />
																</p>
																<div className="flex items-center ">
																	<p className="navigation-text">
																		{/* {single_booking.destination_port_code} */}
																		{single_booking?.shipment_type === "export" && single_booking?.shipment_transport_type === "ocean_freight" ? (
																			single_booking?.destination_port_code

																		) : single_booking?.shipment_type === "import" && single_booking?.shipment_transport_type === "ocean_freight" ? (
																			single_booking?.delivery_location

																		) : single_booking?.shipment_type === "export" && single_booking?.shipment_transport_type === "haulage" ? (
																			single_booking?.terminal_port

																		) : single_booking?.shipment_type === "import" && single_booking?.shipment_transport_type === "haulage" ? (
																			single_booking?.delivery_location
																		) : (
																			single_booking?.destination_port_code
																		)}
																	</p>
																</div>
															</div>

															<p className="white-text text-sm">
																<Moment format="DD-MM-YYYY">{single_booking?.createdAt}</Moment>
															</p>
														</div>
														<div className="text-right">
															<p className="white-text text-xs opacity-75">
																Job Number
															</p>
															<p className="white-text text-sm">
																{single_booking?.job_number}
															</p>
														</div>
													</div>
												</div>

												<div className="mt-5 container mx-auto w-full">
													{/* {
														single_loan_application[0].loan_status === 'accepted' ? ( */}
													<>
														<div className="flex items-center ml-auto">
															<h3 className="text-xl black-text font-semibold">
																Booking Status :
																{
																	single_booking?.shipment_status === "awaiting quotes" ? (
																		<Link to="#" className="bg-light-purple purple-text text-xs py-2 px-3 red-text mx-5 text-center rounded-full">
																			Awaiting Quote
																		</Link>
																	) : single_booking?.shipment_status === "new booking" ? (
																		<Link to="#" className="bg-yellow yellow-text text-xs py-2 px-3 mx-5 text-center rounded-full">
																			New Booking
																		</Link>
																	) : single_booking?.shipment_status === "quoute_accepted" ? (
																		<Link to="#" className="bg-light-green black-text-2 text-sm py-2 px-3 green-text mx-5 text-center rounded-full">
																			Accepted Quote
																		</Link>
																	) : single_booking?.shipment_status === "cancelled" ? (
																		<Link to="#" className="bg-light-red red-text- text-sm py-2 px-3 green-text mx-5 text-center rounded-full">
																			Cancelled
																		</Link>
																	) : (
																		<></>
																	)
																}
															</h3>
															<div className="ml-auto">
																<div className="flex items-center">
																	{/* <Link
																		to=""
																		className="green-br py-2 px-3 rounded ml-auto text-sm green-text"
																		onClick={() => {
																			setIsOpen(true)
																		}}
																	>
																		Update Booking Status
																	</Link> */}
																	{
																		single_booking?.shipment_status === "new booking" ? (
																			<>

																			</>
																		) : (
																			<>
																				<BookingEdits single_booking={single_booking} />
																			</>
																		)
																	}
																</div>
															</div>
														</div>
													</>
													<div className="mt-5 top-divider left-divider right-divider rounded-t-lg">
														<div className="grid grid-cols-2">
															<p className="black-text text-sm py-3 px-5 ">Transportation Type</p>
															<p className="black-text text-sm left-divider py-3 px-5 capitalize">
																{single_booking?.shipment_transport_type === "ocean_freight"
																	? "Ocean Freight"
																	: single_booking?.shipment_transport_type ===
																		"air_freight"
																		? "Air Freight"
																		: "Haulage"
																}
															</p>
														</div>
													</div>
													<div className="top-divider left-divider right-divider rounded-t-lg">
														<div className="grid grid-cols-2">
															<p className="black-text text-sm py-3 px-5 ">Job Number</p>
															<p className="black-text text-sm left-divider py-3 px-5 capitalize">{single_booking?.job_number}</p>
														</div>
													</div>
													<div className="solid-br">
														<div className="grid grid-cols-2">
															<p className="black-text text-sm py-3 px-5 ">B/L Number</p>
															<p className="black-text text-sm left-divider py-3 px-5 capitalize">{single_booking?.bl_number === "" ? "N/A" : single_booking?.bl_number}</p>
														</div>
													</div>
													<div className="solid-br">
														<div className="grid grid-cols-2">
															<p className="black-text text-sm py-3 px-5 ">Booking Branch Code</p>
															<p className="black-text text-sm left-divider py-3 px-5 capitalize">{single_booking?.branch == null ? 'N/A' : single_booking?.branch}</p>
														</div>
													</div>

													<div className="solid-br">
														<div className="grid grid-cols-2">
															<p className="black-text text-sm py-3 px-5 ">Cargo Pick Up Date</p>
															<p className="black-text text-sm left-divider py-3 px-5 capitalize"><Moment format="DD-MM-YYYY">{single_booking?.cargo_pickup_date}</Moment></p>
														</div>
													</div>
													<div className="solid-br">
														<div className="grid grid-cols-2">
															<p className="black-text text-sm py-3 px-5 ">Company Name</p>
															<p className="black-text text-sm left-divider py-3 px-5 capitalize">{single_booking?.customer_details[0]?.company_name}</p>
														</div>
													</div>
													<div className="solid-br">
														<div className="grid grid-cols-2">
															<p className="black-text text-sm py-3 px-5 ">Company Email</p>
															<p className="black-text text-sm left-divider py-3 px-5 capitalize">{single_booking?.customer_details[0]?.email}</p>
														</div>
													</div>
													<div className="solid-br">
														<div className="grid grid-cols-2">
															<p className="black-text text-sm py-3 px-5 ">Contact Person Fullname</p>
															<p className="black-text text-sm left-divider py-3 px-5 capitalize">{single_booking?.customer_details[0]?.firstname} {single_booking?.customer_details[0]?.lastname}</p>
														</div>
													</div>
													{/* <div className="solid-br">
														<div className="grid grid-cols-2">
															<p className="black-text text-sm py-3 px-5 ">Goods Type</p>
															<p className="black-text text-sm left-divider py-3 px-5 capitalize">{single_booking?.goods_type === null || undefined ? 'N/A' : single_booking?.goods_type}</p>
														</div>
													</div> */}

													<div className="solid-br">
														<div className="grid grid-cols-2">
															<p className="black-text text-sm py-3 px-5 ">Goods Value</p>
															<p className="black-text text-sm left-divider py-3 px-5 ">
																<CurrencyFormat
																	value={
																		single_booking?.goods_value === undefined
																			? "N/A"
																			: single_booking?.goods_value
																	}
																	displayType={"text"}
																	thousandSeparator={true}
																// prefix={"₦"}
																/>
															</p>
															{/* <p className="black-text text-sm left-divider py-3 px-5 capitalize">{single_booking?.goods_value === null || undefined ? 'N/A' : single_booking?.goods_value}</p> */}
														</div>
													</div>

													<div className="solid-br">
														<div className="grid grid-cols-2">
															<p className="black-text text-sm py-3 px-5 ">Goods Value Currency</p>
															<p className="black-text text-sm left-divider py-3 px-5 capitalize">{single_booking?.goods_value_currency === null || undefined ? 'N/A' : single_booking?.goods_value_currency}</p>
														</div>
													</div>


													<div className="solid-br">
														<div className="grid grid-cols-2">
															<p className="black-text text-sm py-3 px-5 ">Container Count</p>
															<p className="black-text text-sm left-divider py-3 px-5 capitalize">{single_booking?.container_details?.length}</p>
														</div>
													</div>
													<div className="solid-br">
														<div className="grid grid-cols-2">
															<p className="black-text text-sm py-3 px-5 ">Container Size</p>
															<p className="black-text text-sm left-divider py-3 px-5 capitalize">{single_booking?.container_details[0]?.container_size}</p>
														</div>
													</div>
													<div className="solid-br">
														<div className="grid grid-cols-2">
															<p className="black-text text-sm py-3 px-5 ">Container Type</p>
															<p className="black-text text-sm left-divider py-3 px-5 capitalize">{single_booking?.container_details[0]?.container_type}</p>
														</div>
													</div>

													<div className="solid-br ">
														<div className="grid grid-cols-2">
															<p className="black-text text-sm py-3 px-5 ">Customs Brokerage</p>
															<p className="black-text text-sm left-divider py-3 px-5 ">
																{single_booking?.customs_brokerage === true ? (
																	<p>Yes</p>
																) : (
																	<p>No</p>
																)}
															</p>
														</div>
													</div>

													<div className="solid-br ">
														<div className="grid grid-cols-2">
															<p className="black-text text-sm py-3 px-5 ">marine Insurance</p>
															<p className="black-text text-sm left-divider py-3 px-5 ">
																{single_booking?.marine_insurance === true ? (
																	<p>Yes</p>
																) : (
																	<p>No</p>
																)}
															</p>
														</div>
													</div>

													<div className="solid-br ">
														<div className="grid grid-cols-2">
															<p className="black-text text-sm py-3 px-5 ">Warehousing</p>
															<p className="black-text text-sm left-divider py-3 px-5 ">
																{single_booking?.warehousing === true ? (
																	<p>Yes</p>
																) : (
																	<p>No</p>
																)}
															</p>
														</div>
													</div>

													<div className="solid-br ">
														<div className="grid grid-cols-2">
															<p className="black-text text-sm py-3 px-5 ">Haulage</p>
															<p className="black-text text-sm left-divider py-3 px-5 ">
																{single_booking?.with_haulage === true ? (
																	<p>Yes</p>
																) : (
																	<p>No</p>
																)}
															</p>
														</div>
													</div>
												</div>

												{
													single_booking?.shipment_type === "export" && single_booking?.shipment_transport_type === 'ocean_freight' ? (
														<>
															<p className="black-text text-base mt-10 mb-3 font-semibold">
																Export & Ocean Freight Location Details
															</p>

															<div className="top-divider left-divider right-divider bottom-divider rounded-t-lg">
																<div className="grid grid-cols-2">
																	<p className="black-text text-sm py-3 px-5 ">
																		Origin Port
																	</p>
																	<p className="black-text text-sm left-divider py-3 px-5">
																		{single_booking?.origin_port_code}
																	</p>
																</div>
															</div>

															<div className="top-divider left-divider right-divider bottom-divider rounded-t-lg">
																<div className="grid grid-cols-2">
																	<p className="black-text text-sm py-3 px-5 ">
																		Destination Port
																	</p>
																	<p className="black-text text-sm left-divider py-3 px-5 ">
																		{single_booking?.destination_port_code}
																	</p>
																</div>
															</div>
														</>
													) : (
														<></>
													)
												}

												{
													single_booking?.shipment_type === "import" && single_booking?.shipment_transport_type === 'ocean_freight' ? (
														<>
															<p className="black-text text-base mt-10 mb-3 font-semibold">
																Import & Ocean Freight Location Details
															</p>

															<div className="top-divider left-divider right-divider bottom-divider rounded-t-lg">
																<div className="grid grid-cols-2">
																	<p className="black-text text-sm py-3 px-5 ">
																		Origin Port
																	</p>
																	<p className="black-text text-sm left-divider py-3 px-5">
																		{single_booking?.port_of_discharge}
																	</p>
																</div>
															</div>

															<div className="top-divider left-divider right-divider bottom-divider rounded-t-lg">
																<div className="grid grid-cols-2">
																	<p className="black-text text-sm py-3 px-5 ">
																		Destination Port
																	</p>
																	<p className="black-text text-sm left-divider py-3 px-5 ">
																		{single_booking?.delivery_location}
																	</p>
																</div>
															</div>
														</>
													) : (
														<></>
													)
												}

												{
													single_booking?.shipment_type === "export" && single_booking?.shipment_transport_type === 'haulage' ? (
														<>
															<p className="black-text text-base mt-10 mb-3 font-semibold">
																Export & Haulage Location Details
															</p>

															<div className="top-divider left-divider right-divider bottom-divider rounded-t-lg">
																<div className="grid grid-cols-2">
																	<p className="black-text text-sm py-3 px-5 ">
																		Stuffing Location
																	</p>
																	<p className="black-text text-sm left-divider py-3 px-5 ">
																		{single_booking?.stuffing_location}
																	</p>
																</div>
															</div>

															{/* <div className="top-divider left-divider right-divider bottom-divider rounded-t-lg">
																<div className="grid grid-cols-2">
																	<p className="black-text text-sm py-3 px-5 ">Port of Loading</p>
																	<p className="black-text text-sm left-divider py-3 px-5 ">
																		{single_booking?.origin_port_code}
																	</p>
																</div>
															</div> */}

															<div className="top-divider left-divider right-divider bottom-divider rounded-t-lg">
																<div className="grid grid-cols-2">
																	<p className="black-text text-sm py-3 px-5 ">
																		Terminal Location
																	</p>
																	<p className="black-text text-sm left-divider py-3 px-5 ">
																		{single_booking?.terminal_port}
																	</p>
																</div>
															</div>
															<div className="solid-br ">
																<div className="grid grid-cols-2">
																	<p className="black-text text-sm py-3 px-5 ">Haulage Escort</p>
																	<p className="black-text text-sm left-divider py-3 px-5 ">
																		{single_booking?.with_escort === true ? (
																			<p>Yes</p>
																		) : (
																			<p>No</p>
																		)}
																	</p>
																</div>
															</div>
															<div className="solid-br ">
																<div className="grid grid-cols-2">
																	<p className="black-text text-sm py-3 px-5 ">Haulage Tracker</p>
																	<p className="black-text text-sm left-divider py-3 px-5 ">
																		{single_booking?.with_tracker === true ? (
																			<p>Yes</p>
																		) : (
																			<p>No</p>
																		)}
																	</p>
																</div>
															</div>
														</>
													) : (
														<></>
													)
												}

												{
													single_booking?.shipment_type === "import" && single_booking?.shipment_transport_type === 'haulage' ? (
														<>
															<p className="black-text text-base mt-10 mb-3 font-semibold">
																Import & Haulage Location Details
															</p>

															<div className="top-divider left-divider right-divider bottom-divider rounded-t-lg">
																<div className="grid grid-cols-2">
																	<p className="black-text text-sm py-3 px-5 ">
																		Stuffing Location
																	</p>
																	<p className="black-text text-sm left-divider py-3 px-5 ">
																		{single_booking?.pickup_location}
																	</p>
																</div>
															</div>

															{/* <div className="top-divider left-divider right-divider bottom-divider rounded-t-lg">
																<div className="grid grid-cols-2">
																	<p className="black-text text-sm py-3 px-5 ">Port of Loading</p>
																	<p className="black-text text-sm left-divider py-3 px-5 ">
																		{single_booking?.origin_port_code}
																	</p>
																</div>
															</div> */}

															<div className="top-divider left-divider right-divider bottom-divider rounded-t-lg">
																<div className="grid grid-cols-2">
																	<p className="black-text text-sm py-3 px-5 ">
																		Terminal Location
																	</p>
																	<p className="black-text text-sm left-divider py-3 px-5 ">
																		{single_booking?.delivery_location}
																	</p>
																</div>
															</div>
															<div className="solid-br ">
																<div className="grid grid-cols-2">
																	<p className="black-text text-sm py-3 px-5 ">Haulage Escort</p>
																	<p className="black-text text-sm left-divider py-3 px-5 ">
																		{single_booking?.with_escort === true ? (
																			<p>Yes</p>
																		) : (
																			<p>No</p>
																		)}
																	</p>
																</div>
															</div>
															<div className="solid-br ">
																<div className="grid grid-cols-2">
																	<p className="black-text text-sm py-3 px-5 ">Haulage Tracker</p>
																	<p className="black-text text-sm left-divider py-3 px-5 ">
																		{single_booking?.with_tracker === true ? (
																			<p>Yes</p>
																		) : (
																			<p>No</p>
																		)}
																	</p>
																</div>
															</div>


														</>
													) : (
														<></>
													)
												}


												<UpdateBookingStatus
													isOpen={isOpen}
													setIsOpen={setIsOpen}
												/>
											</>
										</>
									)
							}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

// export default BookingDetails;

const mapStateToProps = (state: any) => {
	const { single_booking, loading } = state.bookings;
	return { single_booking, loading };
};

export default connect(mapStateToProps, { getSingleBooking })(BookingDetails);

