import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import "react-sliding-pane/dist/react-sliding-pane.css";
import Aside from "components/partials/aside";
import TopBar from "components/partials/topBar";
import PrimaryButton from "components/buttons/PrimaryButton";
import "react-datepicker/dist/react-datepicker.css";
import { getContainers } from "store/actions";
import { downloadCSV } from "helpers/jsonToCsv";
import moment from "moment";
import CustomDateFilter from "components/customDateFilter/customDateFilter";
import Moment from "react-moment";
import ContainerCsvData from "components/csvData/containerCsvData";
import Iframe from "react-iframe";
import TrackingModal from './trackingModal/trackingModal'

const Containers = (props: any) => {
	const { containers, loading } = props;
	console.log("the check of the containers", containers);
	const [openAside, SetOpenAside] = useState(false);
	const [filteredContainers, setFilteredContainers] = useState([]);
	const [isOpen, setIsOpen] = useState(false);
	const [containerToTrack, setContainerToTrack] = useState({});
	const [selectedContainer, setSelectedContainer] = useState({});

	console.log(containers?.container_number)

	useEffect(() => {
		props.getContainers();
	}, []);

	useEffect(() => {
		setFilteredContainers(containers);
	}, [containers]);

	const customStyles = {
		rows: {
			style: {
				minHeight: "72px",
			},
		},

		headCells: {
			style: {
				// paddingLeft: '8px',
				// paddingRight: '8px',
				backgroundColor: "#f0fdf4",
			},
		},

		cells: {
			style: {
				// paddingLeft: '1px',
				// paddingRight: '1px',
			},
		},
	};

	const columns = [
		{
			name: "Container Number",
			selector: (row) => {
				return (
					<div
						className="green-text cursor-pointer uppercase"
						style={{
							// marginLeft:'10px',
							zIndex: 1,
						}}
						onClick={() => {
							// window.location.href = `/loan-application-details/${row?.['_id']}`
						}}
					>
						<small>
							{row.container_number === null ? "N/A" : row.container_number}
						</small>
					</div>
				);
			},
			sortable: true,
		},
		{
			name: "Container Details",
			selector: (row) => {
				return (
					<div style={{ marginLeft: "30px" }}>
						{row.shipment_details[0] === undefined
							? "No Container Size"
							: row.shipment_details[0].container_size}
					</div>
				);
			},
			sortable: true,
		},

		{
			name: "Container Type",
			selector: (row) => {
				return (
					<div className="capitalize" style={{ marginLeft: "30px" }}>
						{row.shipment_details[0] === undefined
							? "No Container Type"
							: row.shipment_details[0].container_type}
					</div>
				);
			},
			sortable: true,
		},

		{
			name: "Container Status",
			selector: (row) => {
				return (
					<div
						className="bg-light-green green-text py-1 px-2 rounded-full mt-3 mb-3"
						style={{ marginRight: "10px" }}
					>
						<small>
							{row.container_status === "NEW"
								? "New Container"
								: row.container_status}
						</small>
					</div>
				);
			},
			sortable: true,
		},

		{
			name: "Date Created",
			selector: (row) => {
				return (
					<div style={{ marginRight: "30px" }}>
						{/* {moment(row.createdAt).format("LL")} */}
						<Moment format="DD-MM-YYYY  hh:mmA">{row.createdAt}</Moment>
					</div>
				);
			},
			sortable: true,
		},

		{
			name: "",
			selector: (row) => {
				return (
					<div>
						{
							row?.container_number === null ? (
								<>
									<div
										className="bg-green p-1 rounded cursor-pointer"
										style={{ justifyContent: "center" }}
										onClick={() => {
											setSelectedContainer(row)
											setIsOpen(true);
											setContainerToTrack(row?._id)
										}}
									>
										<p className="text-xs text-center white-text font-semibold p-2">
											<small>View Tracking</small>
										</p>
									</div>
								</>

							) : (
								<>
									<div
										className="bg-green p-1 rounded cursor-pointer"
										style={{ justifyContent: "center" }}
										onClick={() => {
											setSelectedContainer(row)
											setIsOpen(true);
											setContainerToTrack(row?._id)
										}}
									>
										<p className="text-xs text-center white-text font-semibold p-2">
											<small>View Tracking</small>
										</p>
									</div>
								</>
							)
						}
					</div>
				);
			},
		},
	];

	const handleSearch = (event) => {
		console.log(event);
		let value = event.toLowerCase();
		const results: any = [];

		containers.filter((containers) => {
			if (
				//prettier-ignore
				containers?.shipment_details[0]?.client_name.toLowerCase().includes(value.toLowerCase()) ||
				containers?.shipment_details[0]?.client_email.toLowerCase().includes(value.toLowerCase()) ||
				containers?.shipment_details[0]?.createdAt.toLowerCase().includes(value.toLowerCase())
			) {
				results.push(containers);
			}
		});
		setFilteredContainers(results);
	};
	console.log("the check of the filtered containers", filteredContainers);
	console.log(
		"the check of the client name",
		containers[0]?.shipment_details[0]?.client_name
	);

	const handleFilter = (filter: any, type: string) => {
		let filtered = [];

		// if (type === "container_statuss") {
		// 	filtered = containers.filter((container: any) => {
		// 		return container.container_status.toLowerCase() === filter.toLowerCase();
		// 	});

		// } else
		if (type === "shipment_date") {
			if (filter.length) {
				const [startDate, endDate] = filter;

				if (startDate && endDate) {
					filtered = containers.filter((shipment: any) => {
						console.log(moment(shipment.shipment_date));
						return (
							moment(shipment.createdAt).isSameOrAfter(startDate) &&
							moment(shipment.createdAt).isSameOrBefore(endDate)
						);
					});
				} else {
					filtered = containers;
				}
			}
		} else if (type === "container_status") {
			if (filter) {
				filtered = containers.filter((container: any) => {
					return (
						container.container_status.toLowerCase() === filter.toLowerCase()
					);
				});
			} else {
				filtered = containers;
			}
		}

		setFilteredContainers(filtered);
		console.log("filtered-shipments", filtered);
	};

	return (
		<>
			<div className="lg:flex">
				<Aside
					activeTab="Containers"
					openAside={openAside}
					SetOpenAside={SetOpenAside}
				/>
				<div className="">
					<TopBar title={"Containers"} SetOpenAside={SetOpenAside} />
					<div className="dashboard-content">
						<div className="lg:px-10 lg:pt-5 container mx-auto w-full">
							<CustomDateFilter handleFilter={handleFilter} />
							<div>
								<div className="lg:pb-3 lg:pt-3 grid grid-cols-2 gap-4">
									<div className="mt-2">
										<p className="font-semibold text-lg pl-3 ">
											All Containers
										</p>
									</div>
									<div className="mt-2 ml-auto" style={{ textAlign: "right" }}>
										<small>Containers</small>
										<small style={{ color: "grey" }}> / All Containers</small>
									</div>
								</div>
							</div>

							{loading ? (
								<div className="text-center my-3">
									<Link to="#" className="text-success">
										{/* @ts-ignore */}
										<PrimaryButton
											title="Loading Containers"
											loading={loading}
										/>
									</Link>
								</div>
							) : (
								<>
									<div className="container mx-auto w-full mb-5">
										<>
											<div className="mt-2 w-full">
												{/* Desktop view */}
												<div className="desktop-only pt-3 solid-br">
													<DataTable
														// @ts-ignore
														columns={columns}
														loading={loading}
														data={filteredContainers}
														pagination
														persistTableHead
														responsive
														paginationPerPage={30}
														actions={
															<ActionsMemo
																handleFilter={handleFilter}
																handleSearch={handleSearch}
																filteredContainers={filteredContainers}
															/>
														}
														customStyles={customStyles}
													/>
												</div>
											</div>
										</>
									</div>
								</>
							)}
						</div>
					</div>
				</div>
			</div>
			<TrackingModal
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				id={containerToTrack}
				selectedContainer={selectedContainer}
			/>
		</>

	);
};

// export default Containers

const mapStateToProps = (state: any) => {
	const { containers, error, loading } = state.containers;
	return { containers, error, loading };
};

// Table actions
const ActionsMemo = (props: any): JSX.Element => {
	const { filteredContainers, handleFilter, handleSearch } = props;
	return (
		<>
			<div className="mb-5 mr-3">
				<div className="">
					<select
						name=""
						id="container_status"
						className=" px-4 py-2.5 text-xs rounded custom-input  w-full black-text bg-grey"
						onChange={(e) => handleFilter(e.target.value, "container_status")}
					>
						<option value="">Container Status</option>
						<option value="NEW">New Containers</option>
						<option value="File Opening">File Opening</option>
						<option value="In Transit">In Transit</option>
						<option value="File Closed">File Closed</option>
						{/* <option value="sealing">Sealing</option>
						<option value="truck_positioning">Truck Positioning</option>
						<option value="gate_in">Gate In</option>
                        <option value="stuffing">Stuffing</option>
                        <option value="sailing">Sailing</option>
                        <option value="post_shipment_to_docs">Post Shipment To Docs</option> */}
					</select>
				</div>
			</div>

			{/* <div className="mb-5 mr-3">
				<ExportCSV
					onExport={() => downloadCSV(filteredContainers, "containers.csv")}
				/>
			</div> */}
			<ContainerCsvData />
			<input
				placeholder="Search by Container Number"
				className="form-input px-4 py-1.5 custom-input w-full black-text w-52 mb-5"
				onChange={(e) => handleSearch(e.target.value)}
			/>
		</>
	);
};

// const ExportCSV = ({ onExport }) => (
// 	<button
// 		className="bg-grey black-text text-xs w-32 py-2.5 text-center rounded solid-br"
// 		onClick={(e) => onExport(e["target"]["value"])}
// 	>
// 		Export CSV
// 	</button>
// );

export default connect(mapStateToProps, { getContainers })(Containers);
