import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

const DynamicBookingEdits = (props: any) => {
    const params = useParams();
    const { single_booking } = props;
    console.log(single_booking)
    // const single_booking = single_booking[0]
    // console.log(single_booking)

    return (
        <div>
            <div className="p-2 flex items-center">
                <p className="white-text font-semibold text-sm">
                    Edit
                </p>
                {
                    single_booking?.shipment_transport_type === 'ocean_freight' && single_booking?.shipment_type === 'export' ? (
                        <Link
                            to=""
                            onClick={() => {
                                window.location.href = `/bookings/edit-oceanfreight-export-booking/${params.id}`
                            }}
                            className="solid-br py-2 px-3 rounded ml-auto text-sm black-text"
                        >
                            Edit
                        </Link>

                    ) : single_booking?.shipment_transport_type === 'ocean_freight' && single_booking?.shipment_type === 'import' ? (
                        <Link
                            to=""
                            onClick={() => {
                                window.location.href = `/bookings/edit-oceanfreight-import-booking/${params.id}`
                            }}
                            className="solid-br py-2 px-3 rounded ml-auto text-sm black-text"
                        >
                            Edit
                        </Link>

                    ) : single_booking?.shipment_transport_type === 'haulage' && single_booking?.shipment_type === 'export' ? (
                        <Link
                            to=""
                            onClick={() => {
                                // window.location.href = `/shipments/edit-haulage-export-shipment/${params.id}`
                            }}
                            className="solid-br py-2 px-3 rounded ml-auto text-sm black-text"
                        >
                            Edit
                        </Link>

                    ) : single_booking?.shipment_transport_type === 'haulage' && single_booking?.shipment_type === 'import' ? (
                        <Link
                            to=""
                            onClick={() => {
                                // window.location.href = `/shipments/edit-haulage-import-shipment/${params.id}`
                            }}
                            className="solid-br py-2 px-3 rounded ml-auto text-sm black-text"
                        >
                            Edit
                        </Link>
                    ) : (
                        <></>
                    )
                }

            </div>
        </div>

        // <>
        //        <div>
        //     <div className="p-2 flex items-center">
        //         <p className="white-text ml-3 font-semibold text-sm">
        //             Edit
        //         </p>
        //         {
        //             single_booking?.shipment_transport_type === 'ocean_freight' && single_booking?.shipment_type === 'export' ? (
        //                 <Link
        //                     to=""
        //                     onClick={() => {
        //                         window.location.href = `/shipments/edit-oceanfreight-export-shipment/${params.id}`
        //                     }}
        //                     className="solid-br py-2 px-3 rounded ml-auto text-sm black-text"
        //                 >
        //                     Edit
        //                 </Link>

        //             ) : single_booking?.shipment_transport_type === 'ocean_freight' && single_booking?.shipment_type === 'import' ? (
        //                 <Link
        //                     to=""
        //                     // onClick={() => {
        //                     //     window.location.href = `/shipments/edit-oceanfreight-import-shipment/${params.id}`
        //                     // }}
        //                     className="solid-br py-2 px-3 rounded ml-auto text-sm black-text"
        //                 >
        //                     Edit
        //                 </Link>

        //             ) : single_booking?.shipment_transport_type === 'haulage' && single_booking?.shipment_type === 'export' ? (
        //                 <Link
        //                     to=""
        //                     // onClick={() => {
        //                     //     window.location.href = `/shipments/edit-haulage-export-shipment/${params.id}`
        //                     // }}
        //                     className="solid-br py-2 px-3 rounded ml-auto text-sm black-text"
        //                 >
        //                     Edit
        //                 </Link>

        //             ) : single_booking?.shipment_transport_type === 'haulage' && single_booking?.shipment_type === 'import' ? (
        //                 <Link
        //                     to=""
        //                     // onClick={() => {
        //                     //     window.location.href = `/shipments/edit-haulage-import-shipment/${params.id}`
        //                     // }}
        //                     className="solid-br py-2 px-3 rounded ml-auto text-sm black-text"
        //                 >
        //                     Edit
        //                 </Link>
        //             ) : (
        //                 <></>
        //             )
        //         }

        //     </div>
        // </div>
        // </>
    )
}

export default DynamicBookingEdits