import { useState } from "react";
import { Link } from "react-router-dom";
import "react-sliding-pane/dist/react-sliding-pane.css";
import arrowUp from "assets/icons/arrow-up.svg";
import arrowDown from "assets/icons/arrow-down.svg";
import Aside from "components/partials/aside";
import TopBar from "components/partials/topBar";
import NewCustomTabs from "components/customTabs/NewCustomTabs";
import ExportShipment from "../oceanfreight/ExportShipment";
import ImportShipment from "../oceanfreight/ImportShipment";
import InvoiceIcon from "assets/icons/invoice.svg";
// import AirFreight from "./AirFreight";

const NewAirFreightShipment = () => {
    const [openAside, SetOpenAside] = useState(false);
	const [tab, setTab] = useState("Export");

    const tab_result = [
        "Export",
        "Import",
	]


    return (
        <div className="flex">
		<Aside activeTab="Shipment"
			openAside={openAside}
			SetOpenAside={SetOpenAside}
		/>
		<div className="dashboard-content">
			<TopBar title={"Shipment"} SetOpenAside={SetOpenAside}/>
			<div className="">
                <div>
                    <div className="lg:px-14 lg:pb-5 lg:pt-5 grid grid-cols-2 gap-4 px-5">
                        <div className="mt-2">
                            <p className='font-semibold text-lg'>Add A Shipment </p>
                        </div>
                        <div className="mt-2 ml-auto" style={{textAlign:'right'}}>
                            <small>Shipments </small><small style={{color: 'grey'}}> / Add A Shipment</small>
                        </div>
                    </div>
                </div>
                {/* <TransportModeTab /> */}
                <div className="px-14 mb-3">
                    <div className="solid-br ">
                        <div className="grid grid-cols-3">
                            <Link 
                                to='/add-oceanfreight-shipment'
                                className="active-ocean-tab-2"
                            >
                                <p className="text-sm py-3 px-5 font-semibold" style={{textAlign:'center'}}>Ocean Freight</p>
                            </Link>
                            <Link 
                                to='/add-airfreight-shipment'
                                className="active-air-tab-2"
                            >
                                <p className="text-sm left-divider py-3 px-5 font-semibold" style={{textAlign:'center'}}>Air Freight</p>
                            </Link>
                            <Link 
                                to='/add-trucking-shipment'
                                className="inland-haulage-tab-2"
                            >
                                <p className="text-sm left-divider py-3 px-5 font-semibold" style={{textAlign:'center'}}>Haulage</p>
                            </Link>
                        </div>
                    </div>
                </div>
                {/* <TransportModeTab /> */}
				<div className="lg:px-14 lg:pt-3 container mx-auto  w-full mb-3 ">
					<>
						<NewCustomTabs 
                            tabs={tab_result} 
                            activeTab={tab} 
                            setActiveTab={setTab} 
                        />
                        <hr 
                            className={`solid-br`} 
                            style={{marginTop: '-3px'}}
                        />
						{/* {	
							tab === "Export"  ? (
								<ExportShipment />
							// ) : tab === "Import" ? (
                            //     <ImportShipment />
                            ) : (
                                <ImportShipment />
                            )
						} */}
                         <div className="mt-3 flex flex-col item-center justify-center solid-br rounded mobile-dashboard" style={{ minHeight: 400 }}>
                            <img src={InvoiceIcon} alt="" width={113} height={113} className="mx-auto mt-40" />

                             <div 
                                className="mx-auto my-2"
                                style={{textAlign:'center'}}
                            >
                                <p className="grey-text my-3">No Air Freight Yet</p>
                                <Link to="#">
                                    <p className="btn bg-green white-text text-sm py-3 px-6 w-full rounded text-center mb-40">Start New Shipment</p>
                                </Link>
                            </div>
                        </div>
					</>
				</div>
			</div>
		</div>

    </div>
    )
}

export default NewAirFreightShipment;