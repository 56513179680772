import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import Aside from "components/partials/aside";
import TopBar from "components/partials/topBar";
import Quote from "assets/icons/quote-active.svg";
import Invoice from "assets/icons/invoice-active.svg";
import Shipment from "assets/icons/shipment-active.svg";
import Documents from "assets/icons/document-active.svg";
import Customers from "assets/icons/customer-active.svg";
import PrimaryButton from "components/buttons/PrimaryButton";
import { getDashboardAnalytics } from "store/actions";
import GoogleMapReact from 'google-map-react';
import { ComposableMap, Geographies, Geography } from "react-simple-maps"
import Containers from "assets/aside-logo/container.png";
import './Dashboard.css'
import { Marker, MarkerClusterer } from "@react-google-maps/api";
import CustomMarker from "./dashboardPartials/CustomMarker";
import DashboardMap from "./dashboardPartials/DashboardMap";
import IframeTracker from "./dashboardPartials/trackingIframe";


const Dashboard = (props: any) => {
	const [openAside, SetOpenAside] = useState(false);
	let admin_data = useSelector((state: any) => state.auth.admin_data);
	// @ts-ignore
	admin_data = JSON.parse(localStorage.getItem("admin_data")) ? JSON.parse(localStorage.getItem("admin_data")) : JSON.parse(localStorage.getItem("admin_data"));
	const role = (admin_data.role)
	const { dashboard_analytics, loading } = props;
	const dashboard_summary = dashboard_analytics?.['data']
	const [map, setMap] = useState(null);
	const [isOpen, setIsOpen] = useState(false);
	const [containerToTrack, setContainerToTrack] = useState({});
	const [selectedContainer, setSelectedContainer] = useState({});


	useEffect(() => {
		props.getDashboardAnalytics();
	}, [getDashboardAnalytics]);

	const GoogleMapComponent = ({ text }: any) => <div className="red-text light-red-bg">{text}<img src={Containers} width="60%" height="60%" /></div>;

	const defaultProps = {
		trackingData:
			[
				{
					"imei": "860517040870166",
					"frameIndex": 445,
					"timestamp": 1675366183,
					"type": "PERIODIC",
					"acceleration_max_average": null,
					"localization": {
						"location": {
							"lat": 11.939616,
							"lng": 8.582962
						},
						"accuracy": {
							"value": 3431,
							"source": "Cell"
						}
					},
					"temperature": 24.5,
					"temperature_histo": {
						"period": 900,
						"array": [
							25.63,
							25.26,
							24.93,
							24.5
						]
					},
					"humidity": 28,
					"humidity_histo": {
						"period": 900,
						"array": [
							27,
							28,
							28,
							28
						]
					},
					"battery_power": 72,
					"luminosity_voltage": 0,
					"ble_list": null
				},
				{
					"imei": "860517040870166",
					"frameIndex": 446,
					"timestamp": 1675364507,
					"type": "MOVE_END",
					"acceleration_max_average": null,
					"localization": {
						"location": {
							"lat": 11.942139,
							"lng": 8.580827
						},
						"accuracy": {
							"value": 102,
							"source": "Gnss"
						}
					},
					"temperature": null,
					"temperature_histo": null,
					"humidity": 28,
					"humidity_histo": null,
					"battery_power": 72,
					"luminosity_voltage": 0,
					"ble_list": null
				},
				{
					"imei": "860517040870166",
					"frameIndex": 444,
					"timestamp": 1675362523,
					"type": "MOVE_BEGIN",
					"acceleration_max_average": null,
					"localization": {
						"location": {
							"lat": 12.025243,
							"lng": 8.577154
						},
						"accuracy": {
							"value": 55,
							"source": "Gnss"
						}
					},
					"temperature": null,
					"temperature_histo": {
						"period": 900,
						"array": [
							26.29
						]
					},
					"humidity": 26,
					"humidity_histo": {
						"period": 900,
						"array": [
							26
						]
					},
					"battery_power": 72,
					"luminosity_voltage": 0,
					"ble_list": null
				},
				{
					"imei": "860517040870166",
					"frameIndex": 443,
					"timestamp": 1675359888,
					"type": "MOVE_END",
					"acceleration_max_average": null,
					"localization": {
						"location": {
							"lat": 12.025243,
							"lng": 8.577154
						},
						"accuracy": {
							"value": 55,
							"source": "Gnss"
						}
					},
					"temperature": null,
					"temperature_histo": {
						"period": 900,
						"array": [
							28.88,
							27.94,
							27.16,
							26.64
						]
					},
					"humidity": 25,
					"humidity_histo": {
						"period": 900,
						"array": [
							22,
							24,
							25,
							25
						]
					},
					"battery_power": 72,
					"luminosity_voltage": 0,
					"ble_list": null
				},
				{
					"imei": "860517040870166",
					"frameIndex": 442,
					"timestamp": 1675358232,
					"type": "MOVE_BEGIN",
					"acceleration_max_average": null,
					"localization": {
						"location": {
							"lat": 12.027121,
							"lng": 8.574041
						},
						"accuracy": {
							"value": 45,
							"source": "Gnss"
						}
					},
					"temperature": null,
					"temperature_histo": {
						"period": 900,
						"array": [
							30.14
						]
					},
					"humidity": 21,
					"humidity_histo": {
						"period": 900,
						"array": [
							21
						]
					},
					"battery_power": 72,
					"luminosity_voltage": 0,
					"ble_list": null
				},
				{
					"imei": "860517040870166",
					"frameIndex": 441,
					"timestamp": 1675357603,
					"type": "CLOSE",
					"acceleration_max_average": null,
					"localization": {
						"location": {
							"lat": 12.027121,
							"lng": 8.574041
						},
						"accuracy": {
							"value": 45,
							"source": "Gnss"
						}
					},
					"temperature": 30.24,
					"temperature_histo": null,
					"humidity": 21,
					"humidity_histo": null,
					"battery_power": 72,
					"luminosity_voltage": 0,
					"ble_list": null
				},
				{
					"imei": "860517040870166",
					"frameIndex": 440,
					"timestamp": 1675357578,
					"type": "FASTEN",
					"acceleration_max_average": null,
					"localization": {
						"location": {
							"lat": 12.027121,
							"lng": 8.574041
						},
						"accuracy": {
							"value": 45,
							"source": "Gnss"
						}
					},
					"temperature": 30.5,
					"temperature_histo": null,
					"humidity": 20,
					"humidity_histo": null,
					"battery_power": 72,
					"luminosity_voltage": 0,
					"ble_list": null
				},
				{
					"imei": "860517040870166",
					"frameIndex": 439,
					"timestamp": 1675356963,
					"type": "PERIODIC",
					"acceleration_max_average": null,
					"localization": {
						"location": {
							"lat": 12.028007,
							"lng": 8.575736
						},
						"accuracy": {
							"value": 2264,
							"source": "Cell"
						}
					},
					"temperature": 30.72,
					"temperature_histo": null,
					"humidity": 26,
					"humidity_histo": null,
					"battery_power": 72,
					"luminosity_voltage": 72,
					"ble_list": null
				},
				{
					"imei": "860517040870166",
					"frameIndex": 438,
					"timestamp": 1675184163,
					"type": "PERIODIC",
					"acceleration_max_average": null,
					"localization": {
						"location": {
							"lat": 12.00053,
							"lng": 8.542634
						},
						"accuracy": {
							"value": 2004,
							"source": "Cell"
						}
					},
					"temperature": 28.23,
					"temperature_histo": null,
					"humidity": 26,
					"humidity_histo": null,
					"battery_power": 72,
					"luminosity_voltage": 0,
					"ble_list": null
				},
				{
					"imei": "860517040870166",
					"frameIndex": 437,
					"timestamp": 1675011363,
					"type": "PERIODIC",
					"acceleration_max_average": null,
					"localization": {
						"location": {
							"lat": 12.004969,
							"lng": 8.541768
						},
						"accuracy": {
							"value": 1951,
							"source": "Cell"
						}
					},
					"temperature": 28.29,
					"temperature_histo": null,
					"humidity": 31,
					"humidity_histo": null,
					"battery_power": 72,
					"luminosity_voltage": 1,
					"ble_list": null
				},
				{
					"imei": "860517040870166",
					"frameIndex": 436,
					"timestamp": 1674838563,
					"type": "PERIODIC",
					"acceleration_max_average": null,
					"localization": {
						"location": {
							"lat": 12.004969,
							"lng": 8.541768
						},
						"accuracy": {
							"value": 1951,
							"source": "Cell"
						}
					},
					"temperature": 26.47,
					"temperature_histo": null,
					"humidity": 26,
					"humidity_histo": null,
					"battery_power": 72,
					"luminosity_voltage": 1,
					"ble_list": null
				},
				{
					"imei": "860517040870166",
					"frameIndex": 435,
					"timestamp": 1674665763,
					"type": "PERIODIC",
					"acceleration_max_average": null,
					"localization": {
						"location": {
							"lat": 12.00053,
							"lng": 8.542634
						},
						"accuracy": {
							"value": 2004,
							"source": "Cell"
						}
					},
					"temperature": 25.65,
					"temperature_histo": null,
					"humidity": 29,
					"humidity_histo": null,
					"battery_power": 72,
					"luminosity_voltage": 1,
					"ble_list": null
				}
			],
		zoom: 1
	};

	const trackerData = defaultProps.trackingData

	return (
		<>
			<div className="flex mobile-dashboard">
				<Aside
					activeTab="dashboard"
					openAside={openAside}
					SetOpenAside={SetOpenAside}
				/>

				<div>
					<TopBar title={"Dashboard"} SetOpenAside={SetOpenAside} />
					<div className="container">
						{
							loading ?
								(
									<div className="text-center my-3">
										<Link to="#" className="text-success">
											{/* @ts-ignore */}
											<PrimaryButton title="Loading Dashboard" loading={loading} />
										</Link>
									</div>
								) : (
									<>
										<div>
											<div className="lg:px-14 lg:pb-5 lg:pt-5 grid grid-cols-2 gap-4 px-5">
												<div className="mt-2">
													<p className='font-semibold text-lg'>Welcome Back, <span className="green-text">{admin_data.firstname} {admin_data.lastname}</span></p>
												</div>
												{/* <div className="mt-2 ml-auto" style={{ textAlign: 'right' }}>
													<OperationsDashboardToggle />
												</div> */}
											</div>
										</div>
										<div className="lg:px-14 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-5">
											<div className="rounded overflow-hidden right-divider top-divider bottom-divider left-divider rounded w-full">
												<div className="px-6 py-4">
													<img src={Invoice} alt="" width="30px" height="30px" />
													<div className="dashboard-text mb-1 mt-2">Total Shipments</div>
													<p className="font-bold">0</p>
													<div className="ml-auto ">
														{/* <Link to="#" className="text-sm green-text ">
															View <i className="ion-ios-arrow-forward ml-1"></i>
														</Link> */}
													</div>
												</div>
											</div>


											<div className="rounded overflow-hidden right-divider top-divider bottom-divider left-divider rounded w-full">
												<div className="px-6 py-4">
													<img src={Customers} alt="" width="30px" height="30px" />
													<div className="dashboard-text mb-1 mt-2">Total Containers</div>
													<p className="font-bold">0</p>
													<div className="ml-auto ">
														{/* <Link to="#" className="text-sm green-text ">
															View <i className="ion-ios-arrow-forward ml-1"></i>
														</Link> */}
													</div>
												</div>
											</div>

											<div className="rounded overflow-hidden">
												<div className="px-6 py-4 right-divider top-divider bottom-divider left-divider rounded w-full">
													<img src={Shipment} alt="" width="30px" height="30px" />
													<div className="dashboard-text mb-1 mt-2">Total Active Shipments</div>
													<p className="font-bold">0</p>
													<div className="ml-auto ">
														{/* <Link to="#" className="text-sm green-text ">
															View <i className="ion-ios-arrow-forward ml-1"></i>
														</Link> */}
													</div>
												</div>
											</div>

										</div>
										{/* MAP */}
										<div className="lg:px-14 py-5">
											<div className="solid-br">
												<div
													onClick={() => {
														setIsOpen(true);
														setContainerToTrack(trackerData?.map((data: any) => data?.imei))
														setSelectedContainer(trackerData?.map((data: any) => data))
													}}
													style={{ height: '63vh', width: '100%' }}>
													<DashboardMap setMap={setMap} defaultCenter={trackerData[0]?.localization?.location}>
														<MarkerClusterer>
															{(clusterer) => (
																<>
																	{
																		trackerData.map((data, index) => (
																			<div>
																				<CustomMarker
																					key={index}
																					position={{ lat: data.localization.location.lat, lng: data.localization.location.lng }}
																					clusterer={clusterer}
																					link={"https://app.next4.io/shipments/83225b7b-7ac1-47ec-b89b-43797e0fd85d"}

																				/>
																			</div>
																		))
																	}
																</>
															)
															}
														</MarkerClusterer>
													</DashboardMap>
												</div>

											</div>
										</div>

									</>
								)
						}

					</div>
				</div>
			</div>

			<IframeTracker
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				id={containerToTrack}
				selectedContainer={selectedContainer}
			/>
		</>
	);
};

// export default Dashboard;


const mapStateToProps = (state: any) => {
	const { dashboard_analytics, error, loading } = state.dashboard;
	return { dashboard_analytics, error, loading };
};

export default connect(mapStateToProps, { getDashboardAnalytics })(Dashboard);

