// import { generateActions } from "helpers/generateActions";
export const GET_DATASHEET_NAV = "GET_DATASHEET_NAV";
export const GET_DATASHEET_NAV_SUCCESS = "GET_DATASHEET_NAV_SUCCESS";

export const GET_DATASHEET_SUMMARY = "GET_DATASHEET_SUMMARY";
export const GET_DATASHEET_SUMMARY_SUCCESS = "GET_DATASHEET_SUMMARY_SUCCESS";

export const MODIFY_DATASHEET = "MODIFY_DATASHEET";
export const MODIFY_DATASHEET_SUCCESS = "MODIFY_DATASHEET_SUCCESS";

export const MODIFY_FORM_DATASHEET = "MODIFY_FORM_DATASHEET";
export const MODIFY_FORM_DATASHEET_SUCCESS = "MODIFY_FORM_DATASHEET_SUCCESS";

export const API_ERROR = "API_ERROR";