import React from 'react';
import ReactModal from "react-modal";
import { Link } from "react-router-dom";
import PrimaryButton from "components/buttons/PrimaryButton";

const DeleteModal = (props: any) => {
    const { loading, isOpen, setIsOpen, id, deleteCustomer, customers, selectedCustomer } = props;
    console.log(id);
    const modal_customer_data = customers?.data?.data
    console.log(modal_customer_data)
    const onDeleteCustomer = (id) => {
        deleteCustomer(id);
        setIsOpen(false);
    }
    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={() => {
                setIsOpen(false);
            }}
            style={{
                overlay: {
                    position: 'relative',
                }
            }}
        >
            <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
                <div className="fixed z-10 inset-0">
                    <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">

                        {loading ? (
                            <div className="text-center my-3 ml-5">
                                <Link to="#" className="text-success">
                                    {/* @ts-ignore */}
                                    <PrimaryButton
                                        title="Loading Shipment Details"
                                        loading={loading}
                                    />
                                </Link>
                            </div>
                        ) : (
                            <>
                                <div className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform sm:my-8 sm:max-w-lg sm:w-full">
                                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                        <div className="sm:flex sm:items-start">
                                            <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                                <svg className="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                                </svg>
                                            </div>
                                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                                <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">Delete Customer</h3>
                                                <div className="mt-2">
                                                    <p className="text-sm text-gray-500">Are you sure you want to delete &nbsp;
                                                        <span className='green-text font-medium capitalize'>{selectedCustomer?.firstname}</span>&nbsp;
                                                        <span className='green-text font-medium capitalize'>{selectedCustomer?.lastname}</span>&nbsp;
                                                        as a customer? This action cannot be undone.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                        <button
                                            onClick={() => onDeleteCustomer(id)}
                                            type="button"
                                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                                        >
                                            Delete
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => setIsOpen(false)}
                                            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </>
                        )
                        }


                    </div>
                </div>
            </div>
        </ReactModal >
    )
}

export default DeleteModal