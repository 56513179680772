import React from "react";
import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
// import PublicRoute from "./PublicRoute";
import AuthRoute from "./AuthRoute";

//authentication
import SignIn from "pages/auth/SignIn";
import SignUp from "pages/auth/SignUp";
import EmailVerification from "pages/auth/EmailVerification";
import ForgotPassword from "pages/auth/ForgotPassword";

//404 page
import NotFound from "pages/notFound";


//terms
import Terms from "pages/terms/Terms";
import PrivacyPolicy from "pages/terms/PrivacyPolicy";

//dashboard
import Dashboard from "pages/dashboard/Dashboard";

// Admin
import Admin from "pages/administrator/admin";
import CreateAdmin from "pages/administrator/create";

// Customer
import Customer from "pages/customer/Customer";
import CreateCustomer from "pages/customer/create";
import EditCustomer from "pages/customer/edit";
import CustomerDetails from "pages/customer/customerDetails";
import CreateCustomerShipment from "pages/customer/shipmentPartials/createCustomerShipment";
import CustomerShipmentDetails from "pages/customer/customerShipmentDetailsPartials/customerShipmentDetails";
import CustomerShipmentDetailsInvoice from "pages/customer/customerShipmentDetailsPartials/customerShipmentTabs/InvoiceTab/InvoiceTabPartials/viewInvoice";
import CustomerShipmentDetailsLoan from "pages/customer/customerShipmentDetailsPartials/customerShipmentTabs/LoanTab/LoanTabPartials/loanHistoryTable";
import CustomerShipmentDetailsExtinversaLoanHistory from "pages/customer/customerShipmentDetailsPartials/customerShipmentTabs/LoanTab/LoanTabPartials/ExtinversaLoanSummary/ExtinversaLoanHistorySummary";
import CustomerShipmentDetailsTractionLoanHistory from "pages/customer/customerShipmentDetailsPartials/customerShipmentTabs/LoanTab/LoanTabPartials/TractionLoanSummary/tractionLoanSummary";
import CustomerShipmentDetailsOneportLoanHistory from "pages/customer/customerShipmentDetailsPartials/customerShipmentTabs/LoanTab/LoanTabPartials/OnePortLoanSummary/oneportLoanHistorySummary";
import SignOffForm from "pages/customer/customerShipmentDetailsPartials/customerShipmentTabs/LoanTab/LoanTabPartials/OnePortLoanSummary/SignoffForm.tsx/signOffForm";

// OnePort CreditFacility
import LoanCompanyDetails from "pages/customer/customerShipmentDetailsPartials/customerShipmentTabs/LoanTab/LoanTabPartials/OnePortLoanSummary/OnePortLoanHistoryComponents/companyDetails";
import LoanCreditApplication from "pages/customer/customerShipmentDetailsPartials/customerShipmentTabs/LoanTab/LoanTabPartials/OnePortLoanSummary/OnePortLoanHistoryComponents/creditApplication";
// import LoanBillingAddress from "pages/customer/customerShipmentDetailsPartials/customerShipmentTabs/LoanTab/LoanTabPartials/OnePortLoanSummary/OnePortLoanHistoryComponents/billingAddress";
// import LoanCollection from "pages/customer/customerShipmentDetailsPartials/customerShipmentTabs/LoanTab/LoanTabPartials/OnePortLoanSummary/OnePortLoanHistoryComponents/collection";
// import LoanProductsSolicited from "pages/customer/customerShipmentDetailsPartials/customerShipmentTabs/LoanTab/LoanTabPartials/OnePortLoanSummary/OnePortLoanHistoryComponents/productsSolicited";
// import LoanCreditFacility from "pages/customer/customerShipmentDetailsPartials/customerShipmentTabs/LoanTab/LoanTabPartials/OnePortLoanSummary/OnePortLoanHistoryComponents/creditFacilityRequested";
// import LoanUploadedDocuments from "pages/customer/customerShipmentDetailsPartials/customerShipmentTabs/LoanTab/LoanTabPartials/OnePortLoanSummary/OnePortLoanHistoryComponents/uploadedDocuments";

//shipment
import Shipment from "pages/shipment/Shipment";
import NewShipmentPlus from "pages/shipment/shipmentPlus/index";
import OceanFreightShipment from "pages/shipment/NewShipment/oceanfreight/NewOceanFreightShipment";
import AirFreightShipment from "pages/shipment/NewShipment/airfreight/NewAirFreightShipment";
import TruckingShipment from "pages/shipment/NewShipment/Trucking/NewTruckingShipment";
import ShipmentDetails from "pages/shipment/shipmentDetails/ShipmentDetails";
import Haulage from "pages/shipment/shipmentPlus/shipmentAndTransportCombination/OceanAndImport/OceanImportShipmentForm";
import EditShipmentDetails from "pages/shipment/NewShipment/oceanfreight/Edit/editShipmentDetails";
import EditOceanFreightExport from "pages/shipment/shipmentPlus/shipmentAndTransportCombination/OceanAndExport/edit";
import EditOceanFreightImport from "pages/shipment/shipmentPlus/shipmentAndTransportCombination/OceanAndImport/edit";
import EditHaulageExport from "pages/shipment/shipmentPlus/shipmentAndTransportCombination/ExportAndHaulage/edit";
import EditHaulageImport from "pages/shipment/shipmentPlus/shipmentAndTransportCombination/ImportAndHaulage/edit";

// Container
import Containers from "pages/containers/containers";


//quote
import Quote from "pages/quote/Quote";
import QuoteDetails from "pages/quote/QuoteDetails";
import UploadQuote from "pages/quote/uploadQuote";
import AcceptedQuote from "pages/quote/partials/acceptedQuotesTab/acceptedQuotePartials/acceptedQuoteDetails";
import AcceptedQuoteInfo from "pages/quote/partials/acceptedQuotesTab/acceptedQuotePartials/acceptedQuoteInfo";
import ViewAcceptedQuote from "pages/quote/partials/acceptedQuotesTab/acceptedQuotePartials/viewAcceptedQuote";

// New Quotes
import Quotes from "pages/newQuotes/quotes";
import NewQuoteDetails from "pages/newQuotes/newQuotesDetails";

//settings
import Settings from "pages/settings/Settings";


import Bookings from "pages/Bookings/Bookings";
import BookingDetails from "pages/Bookings/BookingDetails";
import ExportOceanBookingEdit from "pages/Bookings/bookingDetailPartials/exportOcean/exportOceanEdit";
import ImportOceanBookingEdit from "pages/Bookings/bookingDetailPartials/importOcean/importOcean";
// import Task1 from "components/UserFrontend/Task1";

const Router = () => {
	return (
		<Routes>
			{/* user frontend tests */}
			{/* <Route path="/user-frontend-test" element={<Task1 />} /> */}

			{/* authentication */}
			<Route
				path="/signin"
				element={
					<AuthRoute>
						<SignIn />
					</AuthRoute>
				}
			/>
			<Route
				path="/signup"
				element={
					<AuthRoute>
						<SignUp />
					</AuthRoute>
				}
			/>
			<Route
				path="/verify-email"
				element={
					<AuthRoute>
						<EmailVerification />
					</AuthRoute>
				}
			/>
			<Route
				path="/forgot-password"
				element={
					<AuthRoute>
						<ForgotPassword />
					</AuthRoute>
				}
			/>
			{/* home  */}
			<Route
				path="/"
				element={
					<AuthRoute>
						<SignIn />
					</AuthRoute>
				}
			/>
			{/* dashboard  */}
			<Route
				path="/dashboard"
				element={
					<ProtectedRoute>
						<Dashboard />
					</ProtectedRoute>
				}
			/>

			{/* Admin */}
			<Route
				path="/admins"
				element={
					<ProtectedRoute>
						<Admin />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/admins/admin-creation"
				element={
					<ProtectedRoute>
						<CreateAdmin />
					</ProtectedRoute>
				}
			/>
			{/* Customer Shipment Creation*/}
			<Route
				path="/shipment-creation-for-customer/:id"
				element={
					<ProtectedRoute>
						<CreateCustomerShipment />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/shipment-creation-for-customer"
				element={
					<ProtectedRoute>
						<CreateCustomerShipment />
					</ProtectedRoute>
				}
			/>
			{/* Customer */}
			<Route
				path="/customers"
				element={
					<ProtectedRoute>
						<Customer />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/customers/customer-creation"
				element={
					<ProtectedRoute>
						<CreateCustomer />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/customers/edit-customer/:id"
				element={
					<ProtectedRoute>
						<EditCustomer />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/customers/customer-shipment/:id"
				element={
					<ProtectedRoute>
						<CustomerDetails />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/customers/customer-shipment-details/:id"
				element={
					<ProtectedRoute>
						<CustomerShipmentDetails />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/customer-shipment-details/invoice/:id"
				element={
					<ProtectedRoute>
						<CustomerShipmentDetailsInvoice />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/customer-shipment-details/loan-history/:id"
				element={
					<ProtectedRoute>
						<CustomerShipmentDetailsLoan />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/customer-shipment-details/extinversa-loan-summary/:id"
				element={
					<ProtectedRoute>
						<CustomerShipmentDetailsExtinversaLoanHistory />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/customer-shipment-details/traction-loan-summary/:id"
				element={
					<ProtectedRoute>
						<CustomerShipmentDetailsTractionLoanHistory />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/customer-shipment-details/oneport-loan-summary/:id"
				element={
					<ProtectedRoute>
						<CustomerShipmentDetailsOneportLoanHistory />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/oneport-loan-application-review/:id"
				element={
					<ProtectedRoute>
						<LoanCompanyDetails />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/customer-shipment-details/oneport-signoff-form/:id"
				element={
					<ProtectedRoute>
						<SignOffForm />
					</ProtectedRoute>
				}
			/>
			{/* <Route path="/single-quote/:id" element={<UploadQuote />} /> */}
			{/* shipment  */}
			<Route
				path="/shipments"
				element={
					<ProtectedRoute>
						<Shipment />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/add-shipment"
				element={
					<ProtectedRoute>
						<NewShipmentPlus />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/add-oceanfreight-shipment"
				element={
					<ProtectedRoute>
						<OceanFreightShipment />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/add-airfreight-shipment"
				element={
					<ProtectedRoute>
						<AirFreightShipment />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/add-trucking-shipment"
				element={
					<ProtectedRoute>
						<TruckingShipment />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/shipments/shipment-details/:id"
				element={
					<ProtectedRoute>
						<ShipmentDetails />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/shipments/edit-shipment-details/:id"
				element={
					<ProtectedRoute>
						<EditShipmentDetails />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/haulage"
				element={
					<ProtectedRoute>
						<Haulage />
					</ProtectedRoute>
				}
			/>
			<Route
				path="shipments/edit-oceanfreight-export-shipment/:id"
				element={
					<ProtectedRoute>
						<EditOceanFreightExport />
					</ProtectedRoute>
				}
			/>
			<Route
				path="shipments/edit-oceanfreight-import-shipment/:id"
				element={
					<ProtectedRoute>
						<EditOceanFreightImport />
					</ProtectedRoute>
				}
			/>
			<Route
				path="shipments/edit-haulage-export-shipment/:id"
				element={
					<ProtectedRoute>
						<EditHaulageExport />
					</ProtectedRoute>
				}
			/>
			<Route
				path="shipments/edit-haulage-import-shipment/:id"
				element={
					<ProtectedRoute>
						<EditHaulageImport />
					</ProtectedRoute>
				}
			/>
			{/* Container */}
			<Route
				path="/containers"
				element={
					<ProtectedRoute>
						<Containers />
					</ProtectedRoute>
				}
			/>

			{/* Terms */}
			<Route path="/terms-of-service" element={<Terms />} />
			<Route path="/privacy-policy" element={<PrivacyPolicy />} />

			{/* quote  */}
			<Route
				path="/quotes"
				element={
					<ProtectedRoute>
						<Quote />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/quote-details/:id"
				element={
					<ProtectedRoute>
						<QuoteDetails />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/upload-quote/:id"
				element={
					<ProtectedRoute>
						<UploadQuote />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/accepted-quote-details/:id"
				element={
					<ProtectedRoute>
						<AcceptedQuote />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/accepted-quote-info/:id"
				element={
					<ProtectedRoute>
						<AcceptedQuoteInfo />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/view-accepted-quote/:id"
				element={
					<ProtectedRoute>
						<ViewAcceptedQuote />
					</ProtectedRoute>
				}
			/>

			{/* Bookings */}
			{/* ********* */}
			{/* ********* */}


			{/* New Quotes */}
			<Route
				path="/new-quotes"
				element={
					<ProtectedRoute>
						<Quotes />
					</ProtectedRoute>
				}
			/>

			{/* QuotesById */}
			<Route
				path="/new-quotes-details/:id"
				element={
					<ProtectedRoute>
						<NewQuoteDetails />
					</ProtectedRoute>
				}
			/>

			<Route
				path="/bookings"
				element={
					<ProtectedRoute>
						<Bookings />
					</ProtectedRoute>
				}
			/>

			{/* BookingsById */}
			{/* ********* */}
			{/* ********* */}
			<Route
				path="/bookings/booking-details/:id"
				element={
					<ProtectedRoute>
						<BookingDetails />
					</ProtectedRoute>
				}
			/>

			<Route
				path="/bookings/edit-oceanfreight-export-booking/:id"
				element={
					<ProtectedRoute>
						<ExportOceanBookingEdit />
					</ProtectedRoute>
				}
			/>


			<Route
				path="/bookings/edit-oceanfreight-import-booking/:id"
				element={
					<ProtectedRoute>
						<ImportOceanBookingEdit />
					</ProtectedRoute>
				}
			/>



			{/* settings  */}
			<Route
				path="/settings"
				element={
					<ProtectedRoute>
						<Settings />
					</ProtectedRoute>
				}
			/>
			{/* not found */}
			<Route path="*" element={<NotFound />} />
		</Routes>
	);
};

export default Router;
