import { useEffect, useState } from "react";
import CustomDateFilter from "components/customDateFilter/customDateFilter";
import Aside from "components/partials/aside";
import TopBar from "components/partials/topBar";
import DataTable from "react-data-table-component";
import PrimaryButton from "components/buttons/PrimaryButton";
import Moment from "react-moment";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { getBookings } from "store/actions";
import BookingCsvData from "components/csvData/bookingCsvData";

const Bookings = (props: any) => {
	const { loading, bookings, getBookings, total_bookings } = props;
	console.log(bookings)
	console.log(total_bookings)
	const [openAside, setOpenAside] = useState(false);
	const [filteredBookings, setFilteredBookings] = useState([]);
	const [selectedBookings, setSelectedBookings] = useState(null);
	const [pending, setPending] = useState(true);

	const [pageLoading, setPageLoading] = useState(false);
	const [itemsPerPage, setItemsPerPage] = useState(10);
	const [page, setPage] = useState(0)
	const [filterObject, setFilterObject] = useState({ page: "0", count: "10" });
	console.log(filteredBookings)

	const fetchBooking = () => {
		const filter_string = new URLSearchParams(filterObject).toString();
		setPageLoading(true);

		getBookings(filter_string);
	};

	const handlePageChange = (page) => {
		setFilterObject({ ...filterObject, ...{ page: String(page - 1) } });
		fetchBooking();
	};

	// handle per rows change
	const handlePerRowsChange = async (newPerPage, page) => {
		setFilterObject({
			...filterObject,
			...{ page: String(page), count: String(newPerPage) },
		});
		fetchBooking();
	};

	useEffect(() => {
		// setFilterObject(filterObject);
		fetchBooking();
	}, [filterObject]);

	useEffect(() => {
		if (bookings.length != 0) {
			//   fetchBooking();
			setFilteredBookings(bookings);
		} else {
			//   fetchBooking();
			setFilteredBookings(bookings);
		}
	}, [bookings]);

	// const fetchBookings = async (page) => {
	// 	setPageLoading(true);

	// 	await getBookings(`page=${page}&count=${itemsPerPage}`);

	// 	setFilteredBookings(bookings);
	// }

	// const handlePageChange = page => {
	// 	fetchBookings(page);
	// };

	// const handlePerRowsChange = async (newPerPage, page) => {
	// 	setItemsPerPage(newPerPage)

	// 	await getBookings(`page=${page}&count=${newPerPage}`);

	// 	setFilteredBookings(bookings);
	// };


	useEffect(() => {
		const timeout = setTimeout(() => {
			setPending(false);
		}, 2000);
		return () => clearTimeout(timeout);
	}, []);

	// useEffect(() => {
	// 	props.getBookings(`page=${page}&count=${itemsPerPage}`);
	// }, []);

	useEffect(() => {
		setFilteredBookings(bookings);
	}, [bookings]);


	// const handleSearch = (event) => {
	// 	console.log(event);
	// 	let value = event.toLowerCase();
	// 	const results: any = [];

	// 	bookings.filter((bookings) => {
	// 		if (
	// 			bookings?.job_number?.toLowerCase().includes(value.toLowerCase()) ||
	// 			bookings?.customer_details[0]?.firstname.toLowerCase().includes(value.toLowerCase()) ||
	// 			bookings?.customer_details[0]?.lastname.toLowerCase().includes(value.toLowerCase()) ||
	// 			bookings?.customer_details[0]?.company_name.toLowerCase().includes(value.toLowerCase()) ||
	// 			bookings?.destination_port_code?.toLowerCase().includes(value.toLowerCase()) ||
	// 			bookings?.origin_port_code?.toLowerCase().includes(value.toLowerCase())

	// 		) {
	// 			results.push(bookings);
	// 		}
	// 	});

	// 	setFilteredBookings(results);
	// };

	const handleSearch = (value: string, type: string) => {
		if (value) {
			setFilterObject({ ...filterObject, ...{ page: "0", search: value } });

			fetchBooking();
		} else {
			fetchBooking();
		}
	};

	// const handleFilter = (value: any, type: string) => {
	// 	if (value) {
	// 		setFilterObject({
	// 			...filterObject,
	// 			...{
	// 				page: "0",
	// 				shipment_transport_type: value.shipment_transport_type,
	// 				shipment_type: value.shipment_type,
	// 				shipment_status: value.shipment_status,
	// 				start_date: value.start_date,
	// 				end_date: value.end_date,
	// 			},
	// 		});
	// 		console.log("value", value);
	// 		//   fetchBooking();
	// 	}
	// };

	const handleFilter = (filter: any, type: string) => {
		let filtered = [];
		if (type === "shipment_type") {
			if (filter) {
				setFilterObject({
					...filterObject,
					...{
						page: "0",
						shipment_type: filter,
						// shipment_transport_type: filter.shipment_transport_type,
						// shipment_status: filter.shipment_status,
						// start_date: filter.start_date,
						// end_date: filter.end_date,
					},
				});
				// filtered = bookings.filter((shipment: any) => {
				// 	return shipment.shipment_type.toLowerCase() === filter.toLowerCase();
				// });
			} else {
				filtered = bookings;
			}
		} else if (type === "shipment_transport_type") {
			if (filter) {
				setFilterObject({
					...filterObject,
					...{
						page: "0",
						shipment_transport_type: filter,
					},
				});
			} else {
				filtered = bookings;
			}
		} else if (type === "shipment_status") {
			if (filter) {
				setFilterObject({
					...filterObject,
					...{
						page: "0",
						shipment_status: filter,
					},
				});
			} else {
				filtered = bookings;
			}
		} else if (type === "shipment_date") {
			if (filter.length) {
				const [startDate, endDate] = filter;

				if (startDate && endDate) {
					// filtered = bookings.filter((shipment: any) => {
					// 	console.log(moment(shipment.shipment_date));
					// 	return (
					// 		moment(shipment.createdAt).isSameOrAfter(startDate) &&
					// 		moment(shipment.createdAt).isSameOrBefore(endDate)
					// 	);
					// });
					setFilterObject({
						...filterObject,
						...{
							page: "0",
							start_date: moment(startDate).format('DD/MM/YYYY'),
							end_date: moment(endDate).format('DD/MM/YYYY'),
						},
					});
				} else {
					filtered = bookings;
				}
			}
		}

		setFilteredBookings(filtered);
		console.log("filtered-bookings", filtered);
	};

	const customStyles = {
		rows: {
			style: {
				minHeight: "72px",
				maxWidth: "100%",
			},
		},

		headCells: {
			style: {
				paddingLeft: "8px",
				paddingRight: "8px",
				backgroundColor: "#f0fdf4",
			},
		},

		cells: {
			style: {
				paddingLeft: "1px",
				paddingRight: "1px",
			},
		},
	};

	const columns = [
		{
			name: "Job Number",
			selector: (row) => {
				return (
					<div
						className="green-text cursor-pointer"
					// onClick={() => {
					// 	setSelectedBookings(row);
					// 	// window.location.href = `/shipments/shipment-details/${row?.["_id"]}`;
					// }}
					>
						<small style={{ textAlign: 'right', justifyContent: 'right', paddingLeft: '20px' }}>
							{row?.job_number === undefined ? 'N/A' : row?.job_number}
						</small>
					</div>
				);
			},
			sortable: true,
		},
		{
			name: "Shipment Type",
			selector: (row) => {
				return (
					<div className="black-text py-1 px-2 rounded-full mt-3 mb-3 capitalize">
						<p>{row.shipment_type}</p>
					</div>
				);
			},
			sortable: true,
		},

		{
			name: "Transportation Type",
			selector: (row) => {
				return (
					<div className="grey-text py-1 px-2 rounded-full mt-3 mb-3">
						<p>
							{
								row.shipment_transport_type === 'ocean_freight' ? 'Ocean Freight' : '' ||
									row.shipment_transport_type === 'haulage' ? 'Haulage' : '' ||
										row.shipment_transport_type === 'air_freight' ? 'Air Freight' : '' ||
											row.shipment_transport_type === 'import' ? 'import' : '' ||
												row.shipment_transport_type === 'export' ? 'Export' : ''
							}
						</p>
					</div>
				);
			},
			sortable: true,
		},

		{
			name: "Origin",
			selector: (row) => {
				return (
					<div>
						<p
							className="font-semibold capitalize"
							style={{ justifyContent: "center", whiteSpace: "break-spaces" }}
						>
							{row?.shipment_type === "export" &&
								row?.shipment_transport_type === "ocean_freight" ? (
								<p
									className="pl-8"
									style={{
										whiteSpace: "break-spaces",
										textAlign: "center",
										justifyContent: "center",
									}}
								>
									{row?.origin_port_code}
								</p>
							) : row?.shipment_type === "import" &&
								row?.shipment_transport_type === "ocean_freight" ? (
								<p
									style={{
										whiteSpace: "break-spaces",
										textAlign: "center",
										justifyContent: "center",
									}}
								>
									{row?.port_of_discharge}
								</p>
							) : row?.shipment_type === "export" &&
								row?.shipment_transport_type === "haulage" ? (
								<p
									className="pl-8"
									style={{
										whiteSpace: "break-spaces",
										textAlign: "center",
										justifyContent: "center",
									}}
								>
									{row?.terminal_port}
								</p>
							) : row?.shipment_type === "import" &&
								row?.shipment_transport_type === "haulage" ? (
								<p
									style={{
										whiteSpace: "break-spaces",
										textAlign: "center",
										justifyContent: "center",
									}}
								>
									{row?.pickup_location}
								</p>
							) : (
								<p
									style={{
										whiteSpace: "break-spaces",
										textAlign: "center",
										justifyContent: "center",
									}}
								>
									{row?.origin_port_code}
								</p>
							)}
						</p>
					</div>
				);
			},
			sortable: true,
		},
		{
			name: "Destination",
			selector: (row) => {
				return (
					<div>
						<p
							className="font-semibold capitalize"
							style={{ justifyContent: "center", whiteSpace: "break-spaces" }}
						>
							{row?.shipment_type === "export" &&
								row?.shipment_transport_type === "ocean_freight" ? (
								<p
									className="pl-8"
									style={{
										whiteSpace: "break-spaces",
										textAlign: "center",
										justifyContent: "center",
									}}
								>
									{row?.destination_port_code}
								</p>
							) : row?.shipment_type === "import" &&
								row?.shipment_transport_type === "ocean_freight" ? (
								<p
									style={{
										whiteSpace: "break-spaces",
										textAlign: "center",
										justifyContent: "center",
									}}
								>
									{row?.delivery_location}
								</p>
							) : row?.shipment_type === "export" &&
								row?.shipment_transport_type === "haulage" ? (
								<p
									style={{
										whiteSpace: "break-spaces",
										textAlign: "center",
										justifyContent: "center",
									}}
								>
									{row?.stuffing_location}
								</p>
							) : row?.shipment_type === "import" &&
								row?.shipment_transport_type === "haulage" ? (
								<p
									style={{
										whiteSpace: "break-spaces",
										textAlign: "center",
										justifyContent: "center",
									}}
								>
									{row?.delivery_location}
								</p>
							) : (
								<p
									style={{
										whiteSpace: "break-spaces",
										textAlign: "center",
										justifyContent: "center",
									}}
								>
									{row?.destination_port_code}
								</p>
							)}
						</p>
					</div>
				);
			},
			sortable: true,
		},

		{
			name: "Date Created",
			selector: (row) => {
				return (
					<div className="grey-text py-1 px-2 rounded-full mt-3 mb-3">
						<Moment format="DD-MM-YYYY">{row.createdAt}</Moment>
						{/* {row.createdAt}.format('DD/MM/YYYY') */}
					</div>
				);
			},
			sortable: true,
		},

		{
			name: "Customer",
			selector: (row) => {
				return (
					<div className="grey-text py-1 px-2 rounded-full mt-3 mb-3 capitalize">
						<p>{row?.customer_details === undefined || null || '' ? 'N/A' : row.customer_details[0]?.firstname} {row.customer_details[0]?.lastname}</p>
					</div>
				);
			},
			sortable: true,
		},

		{
			name: "Booking Status",
			selector: (row) => {
				return (
					<div>
						{
							row?.shipment_status === "awaiting quotes" ? (
								<Link to="#" className="bg-light-purple purple-text text-xs py-2 px-3 red-text mx-5 text-center rounded-full">
									Awaiting Quote
								</Link>
							) : row?.shipment_status === "pending approval" ? (
								<Link to="#" className="bg-light-red red-text text-xs py-2 px-3 red-text mx-5 text-center rounded-full">
									Pending Approval
								</Link>
							) : row?.shipment_status === "new booking" ? (
								<Link to="#" className="bg-yellow yellow-text text-xs py-2 px-3 red-text mx-5 text-center rounded-full">
									New Booking
								</Link>
							) : row?.shipment_status === "quoute_accepted" ? (
								<Link to="#" className="bg-light-green black-text-2 text-sm py-2 px-3 green-text mx-5 text-center rounded-full">
									Accepted Quote
								</Link>
							) : row?.shipment_status === "cancelled" ? (
								<Link to="#" className="bg-light-red red-text- text-sm py-2 px-3 green-text mx-5 text-center rounded-full">
									Cancelled
								</Link>
							) : (
								<></>
							)
						}
					</div>
				);
			},
			sortable: true,
		},

		{
			name: "",
			selector: (row) => {
				return (
					<div
						className="bg-green p-1 rounded cursor-pointer"
						style={{ justifyContent: "center" }}
						onClick={() => {
							setSelectedBookings(row);
							window.location.href = `/bookings/booking-details/${row?.["_id"]}`;
						}}
					>
						<p className="text-xs text-center white-text font-semibold p-2">
							<small>View Details</small>
						</p>
					</div>
				);
			},
		},
	];

	return (
		<div className="flex">
			{/* side bar */}
			<Aside openAside={openAside} SetOpenAside={setOpenAside} />
			{/* dashboard content */}
			<div>
				<TopBar title={"Bookings"} SetOpenAside={setOpenAside} />
				<div className="dashboard-content">
					<div className="lg:px-10 lg:pt-5 container mx-auto w-full">
						<CustomDateFilter handleFilter={handleFilter} />
						<div>
							<div className="lg:pb-3 lg:pt-3 grid grid-cols-2 gap-4">
								<div className="mt-2">
									<p className="font-semibold text-lg pl-3 ">All Bookings </p>
								</div>
								<div className="mt-2 ml-auto" style={{ textAlign: "right" }}>
									<small>Bookings </small>
									<small style={{ color: "grey" }}> / All Bookings</small>
								</div>
							</div>
						</div>
						{loading ? (
							<div className="text-center my-3">
								<Link to="#" className="text-success">
									{/* @ts-ignore */}
									<PrimaryButton title="Loading Quotes" loading={loading} />
								</Link>
							</div>
						) : (
							<>
								<div className="mt-2 w-full">
									{/* Desktop view */}
									<div className="desktop-only pt-3 solid-br">
										<DataTable
											// @ts-ignore
											columns={columns}
											data={filteredBookings}
											pagination
											persistTableHead
											responsive
											actions={
												<ActionsMemo
													handleFilter={handleFilter}
													handleSearch={handleSearch}
													filteredQuotes={filteredBookings}
												/>
											}
											paginationPerPage={10}
											customStyles={customStyles}
											progressPending={pending}
											paginationServer
											paginationTotalRows={total_bookings}
											onChangeRowsPerPage={handlePerRowsChange}
											onChangePage={handlePageChange}
										/>
									</div>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

// export default Quotes

const mapStateToProps = (state: any) => {
	const { bookings, total_bookings, error, loading } = state.bookings;
	return { bookings, total_bookings, error, loading };
};

const ActionsMemo = (props: any): JSX.Element => {
	const { filteredBookings, handleFilter, handleSearch } = props;
	return (
		<>
			<div className="mb-5 mr-3">
				<div className="">
					<select
						name=""
						id="shipment_transport_type"
						className=" px-4 py-2.5 text-xs rounded custom-input  w-full black-text bg-grey"
						onChange={(e) =>
							handleFilter(e.target.value, "shipment_transport_type")
						}
					>
						<option value="">Transportation Type</option>
						<option value="ocean_freight">Ocean Freight</option>
						<option value="air_freight">Air Freight</option>
						<option value="haulage">Haulage</option>
					</select>
				</div>
			</div>

			<div className="mb-5 mr-3">
				<div className="">
					<select
						name=""
						id="shipment_type"
						className=" px-4 py-2.5 text-xs rounded custom-input  w-full black-text bg-grey"
						onChange={(e) => handleFilter(e.target.value, "shipment_type")}
					>
						<option value="">Shipment Type</option>
						<option value="import">Import</option>
						<option value="export">Export</option>
					</select>
				</div>
			</div>
			{/* <div className="mb-5 mr-3">
				<div className="">
					<select
						name=""
						id="shipment_status"
						className=" px-4 py-2.5 text-xs rounded custom-input  w-full black-text bg-grey"
						onChange={(e) => handleFilter(e.target.value, "shipment_status")}
					>
						<option value="">Bookings Status</option>
						<option value="new booking">New Booking</option>
						<option value="pending approval">Pending Approval</option>
						<option value="awaiting quotes">Awaiting Quote</option>
					</select>
				</div>
			</div> */}
			<div className="mb-7 mr-1">
				<BookingCsvData />
			</div>

			<input
				placeholder="Search Bookings"
				className="form-input px-3 py-1.5 custom-input w-full black-text w-40 mb-5"
				onChange={(e) => handleSearch(e.target.value)}
			/>
		</>
	);
};

export default connect(mapStateToProps, { getBookings })(Bookings);
