export const GET_CONTACTS = "GET_CONTACTS";
export const GET_CONTACTS_SUCCESS = "GET_CONTACTS_SUCCESS";

export const GET_REQUEST_CONTACTS = "GET_REQUEST_CONTACTS";
export const GET_REQUEST_CONTACTS_SUCCESS = "GET_REQUEST_CONTACTS_SUCCESS";

export const UPDATE_MESSAGE_STATUS = "UPDATE_MESSAGE_STATUS";
export const UPDATE_MESSAGE_STATUS_SUCCESS = "UPDATE_MESSAGE_STATUS_SUCCESS";


export const API_ERROR = "API_ERROR";