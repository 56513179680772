import React from 'react';
import { Link, useParams } from "react-router-dom";
import Modal from "react-modal";
import PrimaryButton from "components/buttons/PrimaryButton";


const DeactivateModal = (props: any) => {
    const { isOpen, setIsOpen, id, loading, selectedContainer } = props;
    console.log(id);

    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            padding: " 1.5rem",
            transform: "translate(-50%, -50%)",
            width: "70%",
            height: '70%',
            borderRadius: "10px",
            border: "0.01px solid #888",
        },
        overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
        },
    };

    return (
        <>
            <Modal
                isOpen={isOpen}
                onRequestClose={() => {
                    setIsOpen(false);
                }}
                style={customStyles}
                contentLabel="Rate successful modal"
            >
                {
                    loading ?
                        (
                            <div className="text-center my-3 ml-5">
                                <Link to="#" className="text-success">
                                    {/* @ts-ignore */}
                                    <PrimaryButton title="Loading Quotes" loading={loading} />
                                </Link>
                            </div>
                        ) : (
                            <>
                                <div className="flex">
                                    <i className="ion-ios-close text-3xl ml-auto cursor-pointer" onClick={() => setIsOpen(false)}></i>
                                </div>
                                <div className=" flex flex-col">

                                    <div className="text-center mb-10">
                                        <p className='green-text'> Container Number: <span className='uppercase'>{selectedContainer?.container_number === null ? "No Number Assigned Yet" : selectedContainer?.container_number}</span></p>
                                        <div className="flex items-center mt-5" style={{ justifyContent: 'center' }}>
                                            <div>
                                                <>
                                                    <div className="mr-3">
                                                        <iframe
                                                            src="https://app.next4.io/shipments/83225b7b-7ac1-47ec-b89b-43797e0fd85d"
                                                            width="1200"
                                                            height="1000"
                                                        />
                                                    </div>
                                                </>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                }
            </Modal>
        </>


    )
}

export default DeactivateModal
