export const CHANGE_PASSWORD = "CHANGE_PASSWORD"
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";

export const EDIT_PROFILE = "EDIT_PROFILE"
export const EDIT_PROFILE_SUCCESS = "EDIT_PROFILE_SUCCESS";


export const GET_CURRENT_EXCHANGE_RATES = "GET_CURRENT_EXCHANGE_RATES"
export const GET_CURRENT_EXCHANGE_RATES_SUCCESS = "GET_CURRENT_EXCHANGE_RATES_SUCCESS";


export const UPDATE_CURRENT_EXCHANGE_RATES = "UPDATE_CURRENT_EXCHANGE_RATES"
export const UPDATE_CURRENT_EXCHANGE_RATES_SUCCESS = "UPDATE_CURRENT_EXCHANGE_RATES_SUCCESS";

export const API_ERROR = "API_ERROR";
