export const parse_container_count_and_size = (container_count_and_size) => {
    let container_count_and_size_parsed = {};
    let container_return_string = "";
    container_count_and_size.forEach((item) => {
        const container_det =  item.container_size + " " + item.container_type;
        if(container_count_and_size_parsed[container_det] == undefined) {   
            container_count_and_size_parsed[container_det] = 1;
        } else {
            container_count_and_size_parsed[container_det] += 1;
        }
    });

    Object.entries(container_count_and_size_parsed).forEach((item) => {
        container_return_string += item[1] + "x" + item[0] + " \n ";
    });
    return container_count_and_size_parsed;
}