import React from 'react';
import { Link } from "react-router-dom";
import PrimaryButton from "components/buttons/PrimaryButton";
import OceanExportShipmentForm from './OceanAndExport/OceanExportShipmentForm';
import OceanImportShipmentForm from './OceanAndImport/OceanImportShipmentForm';
import SecondaryButton from "components/buttons/SecondaryButton";
import InvoiceIcon from "assets/icons/invoice.svg";
import ExportHaulageForm from './ExportAndHaulage/ExportAndHaulage';
import ImportHaulageForm from './ImportAndHaulage/ImportAndHaulage';
import AirImportShipmentForm from './AirAndImport/ImportAirFreightForm';
import oops from "assets/images/oops.jpeg";
import AirFreightIcon from "assets/logos/airfreight.svg"

const shipmentAndTransportCombo = (props: any) => {
    const { loading, nextStep, previousStep } = props;
    console.log(props);

    return (
        <div>
            {
                loading ?
                    (
                        <div className="text-center my-3">
                            <Link to="#" className="text-success">
                                {/* @ts-ignore */}
                                <PrimaryButton title="Loading Combination" loading={loading} />
                            </Link>
                        </div>
                    ) : (
                        <>
                            {
                                props.shipmentType === 'export' && props.transportationType === 'Ocean Freight' ? (
                                    <>
                                        <OceanExportShipmentForm />
                                    </>
                                ) : props.shipmentType === 'import' && props.transportationType === 'Ocean Freight' ? (
                                    <>
                                        <OceanImportShipmentForm />
                                    </>
                                ) : props.shipmentType === 'export' && props.transportationType === 'Haulage' ? (
                                    <>
                                        <ExportHaulageForm />
                                    </>
                                ) : props.shipmentType === 'import' && props.transportationType === 'Haulage' ? (
                                    <>
                                        <ImportHaulageForm />
                                    </>

                                ) : props.shipmentType === 'import' && props.transportationType === 'Air Freight' ? (
                                    <>
                                        <div className="mt-3 flex flex-col item-center justify-center rounded mobile-dashboard" style={{ minHeight: 400 }}>
                                            <img src={AirFreightIcon} alt="" width='65%' height='65%' className="mx-auto mt-40" />

                                            <div
                                                className="mx-auto my-2"
                                                style={{ textAlign: 'center' }}
                                            >
                                                {/* <p className="grey-text my-3">No Form for <span className='capitalize add-shipment-text'>{props.shipmentType}</span> and <span className='capitalize add-shipment-text'>{props.transportationType}</span> Combination Yet</p> */}
                                                <p className="grey-text mt-3">Please contact our Sales team.</p>
                                                <p className="grey-text">We do not offer Air Freight service on the platform right now</p>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-3 py-14 items-center">
                                            <div></div>
                                            <div className="w-22">
                                                {/* @ts-ignore */}
                                                <SecondaryButton
                                                    title="Previous Step"
                                                    icon="ion-ios-arrow-round-back"
                                                    onClick={previousStep}
                                                />
                                            </div>
                                            <div></div>
                                        </div>
                                    </>
                                ) : props.shipmentType === 'export' && props.transportationType === 'Air Freight' ? (
                                    <>
                                        <div className="mt-3 flex flex-col item-center justify-center rounded mobile-dashboard" style={{ minHeight: 400 }}>
                                            <img src={AirFreightIcon} alt="" width='65%' height='65%' className="mx-auto mt-40" />

                                            <div
                                                className="mx-auto my-2"
                                                style={{ textAlign: 'center' }}
                                            >
                                                {/* <p className="grey-text my-3">No Form for <span className='capitalize add-shipment-text'>{props.shipmentType}</span> and <span className='capitalize add-shipment-text'>{props.transportationType}</span> Combination Yet</p> */}
                                                <p className="grey-text mt-3">Please contact our Sales team.</p>
                                                <p className="grey-text">We do not offer Air Freight service on the platform right now</p>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-3 py-14 items-center">
                                            <div></div>
                                            <div className="w-22">
                                                {/* @ts-ignore */}
                                                <SecondaryButton
                                                    title="Previous Step"
                                                    icon="ion-ios-arrow-round-back"
                                                    onClick={previousStep}
                                                />
                                            </div>
                                            <div></div>
                                        </div>
                                    </>
                                    // Error Pages
                                ) : props.shipmentType === 'export' && props.transportationType === null ? (
                                    <>
                                        <div className="flex flex-col item-center justify-center rounded mobile-dashboard">
                                            <img src={oops} alt="" width='50%' height='65%' className="mx-auto" />

                                            <div
                                                className="mx-auto my-2"
                                                style={{ textAlign: 'center' }}
                                            >
                                                <p className="grey-text mt-3">Seems Like You Selected Only <span className='green-text font-semibold'>Export</span></p>
                                                <p className="grey-text">Please click the button below to go back and select a <span className='green-text font-semibold'>Mode of Transportation</span></p>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-3 py-14 items-center">
                                            <div></div>
                                            <div className="w-22">
                                                {/* @ts-ignore */}
                                                <SecondaryButton
                                                    title="Previous Step"
                                                    icon="ion-ios-arrow-round-back"
                                                    onClick={previousStep}
                                                />
                                            </div>
                                            <div></div>
                                        </div>
                                    </>
                                ) : props.shipmentType === 'import' && props.transportationType === null ? (
                                    <>
                                        <div className="flex flex-col item-center justify-center rounded mobile-dashboard">
                                            <img src={oops} alt="" width='50%' height='65%' className="mx-auto" />

                                            <div
                                                className="mx-auto my-2"
                                                style={{ textAlign: 'center' }}
                                            >
                                                <p className="grey-text mt-3">Seems Like You Selected Only <span className='green-text font-semibold'>Import</span></p>
                                                <p className="grey-text">Please click the button below to go back and select a <span className='green-text font-semibold'>Mode of Transportation</span></p>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-3 py-14 items-center">
                                            <div></div>
                                            <div className="w-22">
                                                {/* @ts-ignore */}
                                                <SecondaryButton
                                                    title="Previous Step"
                                                    icon="ion-ios-arrow-round-back"
                                                    onClick={previousStep}
                                                />
                                            </div>
                                            <div></div>
                                        </div>
                                    </>
                                ) : props.shipmentType === null && props.transportationType === "Ocean Freight" ? (
                                    <>
                                        <div className="flex flex-col item-center justify-center rounded mobile-dashboard">
                                            <img src={oops} alt="" width='50%' height='65%' className="mx-auto" />

                                            <div
                                                className="mx-auto my-2"
                                                style={{ textAlign: 'center' }}
                                            >
                                                <p className="grey-text mt-3">Seems Like You Selected Only <span className='green-text font-semibold'>Ocean Freight</span> as a <span className='green-text font-semibold'>Mode of Transportation</span></p>
                                                <p className="grey-text">Please click the button below to go back and select a <span className='green-text font-semibold'>Shipment Type</span></p>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-3 py-14 items-center">
                                            <div></div>
                                            <div className="w-22">
                                                {/* @ts-ignore */}
                                                <SecondaryButton
                                                    title="Previous Step"
                                                    icon="ion-ios-arrow-round-back"
                                                    onClick={previousStep}
                                                />
                                            </div>
                                            <div></div>
                                        </div>
                                    </>

                                ) : props.shipmentType === null && props.transportationType === 'Air Freight' ? (
                                    <>
                                        <div className="flex flex-col item-center justify-center rounded mobile-dashboard">
                                            <img src={oops} alt="" width='50%' height='65%' className="mx-auto" />

                                            <div
                                                className="mx-auto my-2"
                                                style={{ textAlign: 'center' }}
                                            >
                                                <p className="grey-text mt-3">Seems Like You Selected Only <span className='green-text font-semibold'>Air Freight</span> as a <span className='green-text font-semibold'>Mode of Transportation</span></p>
                                                <p className="grey-text">Please click the button below to go back and select a <span className='green-text font-semibold'>Shipment Type</span></p>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-3 py-14 items-center">
                                            <div></div>
                                            <div className="w-22">
                                                {/* @ts-ignore */}
                                                <SecondaryButton
                                                    title="Previous Step"
                                                    icon="ion-ios-arrow-round-back"
                                                    onClick={previousStep}
                                                />
                                            </div>
                                            <div></div>
                                        </div>
                                    </>
                                ) : props.shipmentType === null && props.transportationType === 'Haulage' ? (
                                    <>
                                        <div className="flex flex-col item-center justify-center rounded mobile-dashboard">
                                            <img src={oops} alt="" width='50%' height='65%' className="mx-auto" />

                                            <div
                                                className="mx-auto my-2"
                                                style={{ textAlign: 'center' }}
                                            >
                                                <p className="grey-text mt-3">Seems Like You Selected Only <span className='green-text font-semibold'>Haulage</span> as a <span className='green-text font-semibold'>Mode of Transportation</span></p>
                                                <p className="grey-text">Please click the button below to go back and select a <span className='green-text font-semibold'>Shipment Type</span></p>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-3 py-14 items-center">
                                            <div></div>
                                            <div className="w-22">
                                                {/* @ts-ignore */}
                                                <SecondaryButton
                                                    title="Previous Step"
                                                    icon="ion-ios-arrow-round-back"
                                                    onClick={previousStep}
                                                />
                                            </div>
                                            <div></div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="flex flex-col item-center justify-center rounded mobile-dashboard">
                                            <img src={oops} alt="" width='50%' height='65%' className="mx-auto" />

                                            <div
                                                className="mx-auto my-2"
                                                style={{ textAlign: 'center' }}
                                            >
                                                <p className="grey-text mt-3">Seems like you selected neither a  <span className='green-text font-semibold'>Shipment Type</span> and a <span className='green-text font-semibold'>Mode of Transportation</span></p>
                                                <p className="grey-text">Please click the button below to go back and select a  <span className='green-text font-semibold'>Shipment Type</span> and a <span className='green-text font-semibold'>Mode of Transportation</span></p>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-3 py-14 items-center">
                                            <div></div>
                                            <div className="w-22">
                                                {/* @ts-ignore */}
                                                <SecondaryButton
                                                    title="Previous Step"
                                                    icon="ion-ios-arrow-round-back"
                                                    onClick={previousStep}
                                                />
                                            </div>
                                            <div></div>
                                        </div>
                                    </>
                                )
                            }
                        </>
                    )
            }
        </div>
    )
}

export default shipmentAndTransportCombo