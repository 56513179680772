// import { generateActions } from "helpers/generateActions";
export const GET_BOOKINGS = "GET_BOOKINGS";
export const GET_BOOKINGS_SUCCESS = "GET_BOOKINGS_SUCCESS";

export const GET_SINGLE_BOOKING = "GET_SINGLE_BOOKING";
export const GET_SINGLE_BOOKING_SUCCESS = "GET_SINGLE_BOOKING_SUCCESS";

export const EDIT_BOOKING_DETAILS = "EDIT_BOOKING_DETAILS";
export const EDIT_BOOKING_DETAILS_SUCCESS = "EDIT_BOOKING_DETAILS_SUCCESS";

export const SEARCH_BOOKING = "SEARCH_BOOKING";
export const SEARCH_BOOKING_SUCCESS = "SEARCH_BOOKING_SUCCESS";


export const API_ERROR = "API_ERROR";
