import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useParams} from "react-router-dom";
import CustomInput from "components/textInputs/CustomInput";
import CustomSelect from "components/selectInputs/CustomSelect";
import PrimaryButton from "components/buttons/PrimaryButton";
import CustomCheckBox from "components/checkBox/CustomCheckbox";
import CustomCurrencyInput from "components/textInputs/CustomCurrencyInput";
import CustomerAutocomplete from "components/customerAutocomplete/customerAutocomplete";
import { addExportShipment } from "store/actions";
import { parseAllPorts } from "helpers/index";
const _Json = require("sea-ports");

const ExportShipment = (props: any) => {
    const { loading, shipmentType, } = props;
    const { handleSubmit, control, formState: { errors }} = useForm();
    const [customs, setCustoms] = useState(false);
    const [haulage, setHaulage] = useState(false);
    const [terminal_handling, setThc] = useState(false);
    const [ocean_freight, setOceanFreight] = useState(false);
    const [insurance, setInsurance] = useState(false);
    const [warehousing, setWarehousing] = useState(false);
    const [defaultPortsOfOrigin, setDefaultPortsOfOrigin] = useState([]);
    const [defaultPortsOfDestination, setDefaultPortsOfDestination] = useState([]);

    useEffect(() => {
        const parsePorts = parseAllPorts(_Json.JSON);
            setDefaultPortsOfOrigin(parsePorts.origin);
            setDefaultPortsOfDestination(parsePorts.destination);
    }, []);
      

    const onSubmit = (data: any) => {
        console.log(data.customer_id)
        const newData = {
            customs,
            haulage,
            terminal_handling,
            ocean_freight,
            warehousing,
            insurance,
            pickup_location: data?.pickup_location?.label,
            port_origin: shipmentType === "export" ? "NGAPP" : "",
            origin_port_code: data?.origin_port?.value?.unlocs[0],
            destination_port_code: data?.destination_port?.value?.unlocs[0],
            customer_id: data.customer_id.value,
            cargo_pickup_date: data?.cargo_pickup_date,
            branch: data?.branch.value,
            container_size: data?.container_size.value,
            container_type: data?.container_type.value,
            container_count: data?.container_count,
            container_weight: data?.container_weight,
            goods_value: data?.goods_value,
            bl_number: data?.bl_number
		};
        props.addExportShipment(newData);
		// console.log(newData);
        // console.log(data.customer_id.value)
    }

    return (
        <div className='my-5 right-divider left-divider top-divider bottom-divider shadow-2xl'>
            <form onSubmit={handleSubmit(onSubmit)} className="my-5">
                <div className="px-8">
                    <div className="grid grid-cols-1 mb-3">
                        <div className="mr-1">
                            <p className="text-xs black-text mb-2 font-medium">Company Name & Customer Name<span className="red-text">*</span></p>
                            <CustomerAutocomplete
                                control={control}
                                name={"customer_id"}
                                id={"customer_id"}
                                label={"Customer Selection"}
                                placeholder={"Select Company & Customer Name"}
                                isRequired={true}
                                errors={errors}
                                isDisabled={false}
                                icon=""
                            />
                        </div>

                        {/* <div className="ml-1">
                            <CustomInput
                                control={control}
                                name={"email"}
                                id={"email"}
                                label={"Client Email Address"}
                                placeholder={"Enter Email Address"}
                                isRequired={true}
                                type={"text"}
                                errors={errors}
                                isDisabled={false}
                                defaultValue={""}
                                min={""}
                                max={""}
                                icon={""}
                            />
                        </div> */}

                        <div className="mt-3 grid grid-cols-2 mb-2">
                            <div className="mr-1">
                                <p className="text-xs black-text font-medium">Select Origin Port<span className="red-text">*</span></p>
                                <CustomSelect
                                    control={control}
                                    name={"origin_port"}
                                    id={"origin_port"}
                                    label={""}
                                    placeholder={"Origin Port"}
                                    isRequired={false}
                                    errors={errors}
                                    isDisabled={false}
                                    options={defaultPortsOfOrigin}
                                    defaultValue={''}
                                    icon=""
                                />
                            </div>

                            <div className="mr-1">
                                <p className="text-xs black-text font-medium">Select Destination Port<span className="red-text">*</span></p>
                                <CustomSelect
                                    control={control}
                                    name={"destination_port"}
                                    id={"destination_port"}
                                    label={""}
                                    placeholder={"Destination Port"}
                                    isRequired={false}
                                    errors={errors}
                                    isDisabled={false}
                                    defaultValue={''}
                                    options={defaultPortsOfDestination}
                                    icon=""
                                />
                            </div>
                        </div>

                    </div>

                    <div className="grid grid-cols-2">
                        <div className="mr-1">
                            <p className="text-xs black-text font-medium">Cargo Pickup Date<span className="red-text">*</span></p>
                            <CustomInput
                                control={control}
                                name={"cargo_pickup_date"}
                                id={"cargo_pickup_date"}
                                label={""}
                                placeholder={"Cargo Pickup Date"}
                                isRequired={true}
                                type={"date"}
                                errors={errors}
                                isDisabled={false}
                                defaultValue={""}
                                min={""}
                                max={""}
                                icon={""}
                            />
                        </div>

                        <div className="ml-1">
                            <p className="text-xs black-text font-medium">Total value of Goods (₦)<span className="red-text">*</span></p>
                            <CustomCurrencyInput
                                control={control}
                                name={"goods_value"}
                                id={"goods_value"}
                                label={""}
                                placeholder={""}
                                isRequired={true}
                                type={"number"}
                                errors={errors}
                                isDisabled={false}
                                defaultValue={''}
                                min={1}
                                max={""}
                                icon=""
                            />
                            {/* <CustomInput
                                control={control}
                                name={"container_countgoods_value"}
                                id={"fullname"}
                                label={"Estimated Total Value of Goods"}
                                placeholder={"Estimated Total Value of Goods"}
                                isRequired={true}
                                type={"text"}
                                errors={errors}
                                isDisabled={false}
                                defaultValue={""}
                                min={""}
                                max={""}
                                icon={""}
                            /> */}
                        </div>
                    </div>

                    <div className="mt-3 grid grid-cols-4">
                        <div className="">
                            <p className="text-xs black-text font-medium">Size of Container<span className="red-text">*</span></p>
                            <CustomSelect
                                control={control}
                                name={`container_size`}
                                id={`container_size`}
                                label={""}
                                placeholder={`Container Size`}
                                isRequired={true}
                                errors={errors}
                                isDisabled={false}
                                options={[
                                    { label: "20 FT", value: "20FT" },
                                    { label: "40 FT", value: "40FT" },
                                    { label: "40 HC FT", value: "40HC FT" },
                                ]}
                                defaultValue={''}
                                icon=""
                            />
                        </div>

                        <div className="ml-1">
                        <p className="text-xs black-text font-medium">Type of Container<span className="red-text">*</span></p>
                            <CustomSelect
                                control={control}
                                name={`container_type`}
                                id={`container_type`}
                                label={""}
                                placeholder={`Container Type`}
                                isRequired={true}
                                errors={errors}
                                isDisabled={false}
                                options={[
                                    { label: "Dry", value: "dry" },
                                    { label: "Reefer", value: "reefer" },
                                ]}
                                defaultValue={''}
                                icon=""
                            />
                        </div>

                        <div className="ml-1">
                        <p className="text-xs black-text font-medium">Number Of Containers<span className="red-text">*</span></p>
                            <CustomInput
                                control={control}
                                name={"container_count"}
                                id={"container_count"}
                                label={""}
                                placeholder={"Number Of Containers"}
                                isRequired={true}
                                type={"text"}
                                errors={errors}
                                isDisabled={false}
                                defaultValue={""}
                                min={""}
                                max={""}
                                icon={""}
                            />
                        </div>

                        

                        <div className="ml-1">
                            <p className="text-xs black-text font-medium">Weight (TON)<span className="red-text">*</span></p>
                                <CustomInput
                                    control={control}
                                    name={"container_weight"}
                                    id={"container_weight"}
                                    label={""}
                                    placeholder={"Weight"}
                                    isRequired={true}
                                    type={"text"}
                                    errors={errors}
                                    isDisabled={false}
                                    defaultValue={""}
                                    min={""}
                                    max={""}
                                    icon={""}
                                />
                            </div>
                        
                        </div>

                    <div className="mt-2">
                        <div className="grid grid-cols-3">
                            <div className="">
                                <p className="text-xs black-text font-medium">B/L Number</p>
                                    <CustomInput
                                        control={control}
                                        name={"bl_number"}
                                        id={"bl_number"}
                                        label={""}
                                        placeholder={"B/L Number"}
                                        isRequired={false}
                                        type={"text"}
                                        errors={errors}
                                        isDisabled={false}
                                        defaultValue={""}
                                        min={""}
                                        max={""}
                                        icon={""}
                                    />
                            </div>
                        

                            <div className="ml-1 ">
                                <p className="text-xs black-text font-medium">Office / Branch<span className="red-text">*</span></p>
                                <CustomSelect
                                    control={control}
                                    name={`branch`}
                                    id={`branch`}
                                    label={""}
                                    placeholder={`Office / Branch`}
                                    isRequired={true}
                                    errors={errors}
                                    isDisabled={false}
                                    options={[
                                        { label: "Lagos", value: "LOS" },
                                        { label: "Kano", value: "KAN" },
                                        { label: "Port Harcourt", value: "PHC" },
                                    ]}
                                    defaultValue={''}
                                    icon=""
                                />
                            </div>
                        </div>
                        {/* <div className="grid grid-cols-3">
                            <div className="flex items-center mb-5">
                                <div className="mr-3">
                                    <CustomCheckBox
                                        name="lagos"
                                        id="lagos"
                                        label="Lagos"
                                        isRequired={true}
                                        defaultChecked={false}
                                        isDisabled={false}
                                        onChange={(e : any) => setLagos(e.target.checked)}
                                    />
                                </div>
                                

                                <div className="flex items-center ">
                                    <div className="mr-3">
                                        <CustomCheckBox
                                            name="kano"
                                            id="kano"
                                            label="Kano"
                                            isRequired={false}
                                            isDisabled={false}
                                            onChange={(e : any) => setKano(e.target.checked)}
                                            defaultChecked={false}
                                        />
                                    </div>
                                    
                                </div>

                                <div className="mr-3">
                                    <CustomCheckBox
                                        name="harcourt"
                                        id="harcourt"
                                        label="Port-Harcourt"
                                        isRequired={false}
                                        isDisabled={false}
                                        onChange={(e : any) => setHarcourt(e.target.checked)}
                                        defaultChecked={false}
                                    />
                                </div>

                                
                            </div>
                    
                            
                        </div> */}
                    </div>

                    <div className="mt-5">
                        <p className="text-xs black-text mb-2 font-medium">Services Provided <small>(Select all that apply)<span className="red-text">*</span></small></p>
                        <div className="grid grid-cols-1">
                            <div className="flex items-center mb-5">
                                <div className="mr-3">
                                    <CustomCheckBox
                                        name="Customs"
                                        id="Customs"
                                        label="Customs"
                                        isRequired={false}
                                        isDisabled={false}
                                        onChange={(e : any) => setCustoms(e.target.checked)}
                                        defaultChecked={false}
                                    />
                                </div>

                                <div className="flex items-center ">
                                    {/* <div className="mr-3">
                                        <CustomCheckBox
                                            name="haulage"
                                            id="haulage"
                                            label="Haulage"
                                            isRequired={false}
                                            isDisabled={false}
                                            onChange={(e : any) => setHaulage(e.target.checked)}
                                            defaultChecked={false}
                                        />
                                    </div> */}
                                    
                                </div>

                                <div className="mr-3">
                                    <CustomCheckBox
                                        name="terminal_handling"
                                        id="terminal_handling"
                                        label="Terminal Handling"
                                        isRequired={false}
                                        isDisabled={false}
                                        onChange={(e : any) => setThc(e.target.checked)}
                                        defaultChecked={false}
                                    />
                                </div>

                                <div className="mr-3">
                                    <CustomCheckBox
                                        name="ocean_freight"
                                        id="ocean_freight"
                                        label="Ocean Freight"
                                        isRequired={false}
                                        isDisabled={false}
                                        onChange={(e : any) => setOceanFreight(e.target.checked)}
                                        defaultChecked={false}
                                    />
                                </div>

                                <div className="mr-3">
                                    <CustomCheckBox
                                        name="insurance"
                                        id="insurance"
                                        label="Marine Insurance"
                                        isRequired={false}
                                        isDisabled={false}
                                        onChange={(e : any) => setInsurance(e.target.checked)}
                                        defaultChecked={false}
                                    />
                                </div>

                                <div className="mr-3">
                                    <CustomCheckBox
                                        name="warehousing"
                                        id="warehousing"
                                        label="Warehousing"
                                        isRequired={false}
                                        isDisabled={false}
                                        onChange={(e : any) => setWarehousing(e.target.checked)}
                                        defaultChecked={false}
                                    />
                                </div>

                                
                            </div>
                    
                            
                        </div>
                    </div>

                    {/* <div className="mt-3 mb-5 grid grid-cols-3">
                        <div className="">
                            <div className="ml-1">
                                <CustomInput
                                    control={control}
                                    name={"email"}
                                    id={"email"}
                                    label={"Truck Positioned Date"}
                                    placeholder={"Truck Positioned Date"}
                                    isRequired={true}
                                    type={"date"}
                                    errors={errors}
                                    isDisabled={false}
                                    defaultValue={""}
                                    min={""}
                                    max={""}
                                    icon={""}
                                />
                            </div>
                        </div>

                        <div className="ml-1">
                            <CustomInput
                                control={control}
                                name={"emails"}
                                id={"emails"}
                                label={"Stuffing Date"}
                                placeholder={"Stuffing Date"}
                                isRequired={true}
                                type={"date"}
                                errors={errors}
                                isDisabled={false}
                                defaultValue={""}
                                min={""}
                                max={""}
                                icon={""}
                            />
                        </div>

                        <div className="ml-1">
                            <CustomInput
                                control={control}
                                name={"emailer"}
                                id={"emailer"}
                                label={"Truck Gate In Date"}
                                placeholder={"Truck Gate In Date"}
                                isRequired={true}
                                type={"date"}
                                errors={errors}
                                isDisabled={false}
                                defaultValue={""}
                                min={""}
                                max={""}
                                icon={""}
                            />
                        </div>

                        <div className="ml-1">
                            <CustomInput
                                control={control}
                                name={"emaile"}
                                id={"emaile"}
                                label={"Sailing Date"}
                                placeholder={"Sailing Date"}
                                isRequired={false}
                                type={"date"}
                                errors={errors}
                                isDisabled={false}
                                defaultValue={""}
                                min={""}
                                max={""}
                                icon={""}
                            />
                        </div>

                        <div className="ml-1">
                            <CustomInput
                                control={control}
                                name={"emailed"}
                                id={"emailed"}
                                label={ "Post Shipment Docs to Finance"}
                                placeholder={"Post Shipment Docs to Finance"}
                                isRequired={false}
                                type={"date"}
                                errors={errors}
                                isDisabled={false}
                                defaultValue={""}
                                min={""}
                                max={""}
                                icon={""}
                            />
                        </div>
                        
                    </div> */}

                </div>
                <div className="px-8 mb-5">
                    <hr className={`solid-br`} />
                    <div className="w-24 mt-5">
                        {" "}
                        {/* @ts-ignore */}
                        <PrimaryButton 
                            title="Submit Form" 
                            // loading={loading} 
                        />
                    </div>
                </div>
            </form>
        </div>
    )
}

// export default ExportShipment;

const mapStateToProps = (state: any) => {
    const { error, loading } = state.shipments;
    return { error, loading };
  };
  export default connect(mapStateToProps, { addExportShipment })(ExportShipment);
  