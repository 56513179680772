import React from "react";
import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import { Mapstyles } from "../dashboardPartials/MapStyles";


const options = {
  disableDefaultUI: false,
  scaleControl: true,
  mapTypeId: "roadmap",
  labels: true,
  styles: Mapstyles,
};


export default function DashboardMap(props) {
  const { setMap, children, defaultCenter } = props;
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyBZT1Ihn5kQ89VCqBLm980AmTciS4fO_N0"
  });

  const renderMap = () => {

    const loadHandler = map => {
      setMap(map);
    };

    return (
      <GoogleMap
        id="circle-example"
        mapContainerStyle={{
          height: "100%",
          width: "100%"
        }}
        zoom={8}
        center={defaultCenter}
        options={options}
        onLoad={loadHandler}
      >
        {children}
      </GoogleMap>
    );
  };

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return isLoaded ? renderMap() : <div>Loading...</div>;
}
